import React, { useEffect, useState } from 'react';

function getIndicesOf(searchStr, str, caseSensitive) {
  const searchStrLen = searchStr.length;
  let startIndex = 0;
  const indices = [];
  const splitTextes = [];
  if (searchStrLen === 0) {
    return { indices, splitTextes };
  }
  let newStr = str;
  let newSearchStr = searchStr;
  if (!caseSensitive) {
    newStr = str.toLowerCase();
    newSearchStr = searchStr.toLowerCase();
  }
  let index = newStr.indexOf(newSearchStr, startIndex);
  while (index > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
    splitTextes.push(str.substring(indices.length === 1?0:indices[indices.length - 2]+ searchStrLen, indices[indices.length - 1]))
    splitTextes.push(str.substring(indices[indices.length - 1], indices[indices.length - 1] + searchStrLen))
    index = newStr.indexOf(newSearchStr, startIndex);
  }
  splitTextes.push(str.substring(indices[indices.length - 1] + searchStrLen, str.length))
  return { indices, splitTextes };
}

function HighLighter({ text, searchText, caseSensitive = false, highlightClass = 'text-red-400' }) {
  const [splitTexts, setSplitTexts] = useState(getIndicesOf(searchText, text, caseSensitive).splitTextes);

  useEffect(() => {
    setSplitTexts(getIndicesOf(searchText, text, caseSensitive).splitTextes);
  }, [searchText, caseSensitive]);

  return (
    <div>
      {splitTexts.length === 0 && <p>{text}</p>}
      {splitTexts.length > 0 && <p>{splitTexts.map((t) => <span className={`${(caseSensitive ? t : t.toLowerCase()) === (caseSensitive ? searchText : searchText.toLowerCase()) && highlightClass}`}>{t}</span>)}</p>}
    </div>
  );
}

export default HighLighter;
