import React from 'react';

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 48 48" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M 29.5,7.5 C 23.5,7.5 17.5,7.5 11.5,7.5C 9.60171,6.26553 7.93504,6.59887 6.5,8.5C 5.08134,7.54885 3.41467,7.21552 1.5,7.5C 1.26057,5.5328 1.59391,3.69946 2.5,2C 9.5,1.33333 16.5,1.33333 23.5,2C 25.9962,3.33105 27.9962,5.16438 29.5,7.5 Z"/></g>
<g><path d="M 1.5,7.5 C 3.41467,7.21552 5.08134,7.54885 6.5,8.5C 9.10163,12.6974 10.7683,12.3641 11.5,7.5C 17.5,7.5 23.5,7.5 29.5,7.5C 31.8333,9.16667 33.8333,11.1667 35.5,13.5C 24.1667,13.5 12.8333,13.5 1.5,13.5C 1.5,11.5 1.5,9.5 1.5,7.5 Z"/></g>
<g><path d="M 1.5,13.5 C 12.8333,13.5 24.1667,13.5 35.5,13.5C 37.5,14.8333 39.1667,16.5 40.5,18.5C 27.5,18.5 14.5,18.5 1.5,18.5C 1.5,16.8333 1.5,15.1667 1.5,13.5 Z"/></g>
<g><path d="M 1.5,18.5 C 14.5,18.5 27.5,18.5 40.5,18.5C 42.8333,20.1667 44.8333,22.1667 46.5,24.5C 31.9903,24.1707 17.6569,24.5041 3.5,25.5C 1.83724,23.5615 1.17057,21.2282 1.5,18.5 Z"/></g>
<g><path d="M 46.5,24.5 C 46.7845,26.4147 46.4511,28.0813 45.5,29.5C 32.9887,29.1714 20.6554,29.5048 8.5,30.5C 6.5,29.1667 4.83333,27.5 3.5,25.5C 17.6569,24.5041 31.9903,24.1707 46.5,24.5 Z"/></g>
<g><path d="M 45.5,29.5 C 43.8333,31.8333 41.8333,33.8333 39.5,35.5C 30.8333,35.5 22.1667,35.5 13.5,35.5C 11.5,34.1667 9.83333,32.5 8.5,30.5C 20.6554,29.5048 32.9887,29.1714 45.5,29.5 Z"/></g>
<g><path d="M 13.5,35.5 C 22.1667,35.5 30.8333,35.5 39.5,35.5C 38.1667,37.5 36.5,39.1667 34.5,40.5C 29.1667,40.5 23.8333,40.5 18.5,40.5C 16.5,39.1667 14.8333,37.5 13.5,35.5 Z"/></g>
<g><path d="M 18.5,40.5 C 23.8333,40.5 29.1667,40.5 34.5,40.5C 29.1667,48.5 23.8333,48.5 18.5,40.5 Z"/></g></svg>;
};

export default Icon;