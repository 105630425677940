import React, { useEffect, useCallback, useInsertionEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {BsPin, BsFillPinFill} from 'react-icons/bs'

function Toast({positionX="end-0", positionY="top-2", duration=3, newToast, addToastHandler}) {
  const [toastList, setToastList] = useState([])
  useEffect(() => {
    if(!newToast) return
    setToastList([...toastList, newToast].filter(t => t !== null))
    addToastHandler()
  }, [newToast])
  
  return (
    <div className={`fixed ${positionX} ${positionY} w-72 z-50 flex flex-col gap-2`}>
      {toastList.map((toast, idx)=>(
        <ToastElement key={idx} setToastList={setToastList} removeHandler={() => setToastList(o => toastList.filter((tl, index) => tl.seconds > 0))} toast={toast} seconds={duration}/>
      ))}
    </div>
  )
}

function ToastElement({removeHandler, toast, setToastList}) {
  const { theme } = useSelector((state) => ({
    theme: state.item,
  }));
  const [pause, setPause] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState()

  useEffect(()=>{
    switch (toast.type) {
      case 'success':
        setBackgroundColor(`${theme.themeMode==='dark'?'bg-green-500':'bg-green-200'}`)
        break;
      case 'error':
        setBackgroundColor(`${theme.themeMode==='dark'?'bg-red-500':'bg-red-200'}`)
        break;
      case 'warning':
        setBackgroundColor(`${theme.themeMode==='dark'?'bg-amber-500':'bg-amber-200'}`)
        break;
      case 'info':
        setBackgroundColor(`${theme.themeMode==='dark'?'bg-blue-500':'bg-blue-200'}`)
        break;
    
      default:
        setBackgroundColor(`${theme.themeMode==='dark'?'bg-green-500':'bg-green-200'}`)
        break;
    }
  },[theme])

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if(pause) return
    if (toast.seconds > 0) {
      sleep(1000).then(t => setToastList(o => o.map(ti => {if(ti.id === toast.id){return {...ti, seconds: ti.seconds - 1}}else{return ti}})));
    }
    removeHandler()
  
    // return () => {
    //   console.log(timer)
    // }
  }, [toast.seconds])

  useEffect(() => {
    return () => {
      // پاکسازی هر تایمر باقی‌مانده در صورت حذف کامپوننت
      // setIsRunning(false);
    };
  }, []);
  
  // useEffect(() => {
  //   if(timer <= 0) {
  //     clearInterval(interval.current);
  //     interval.current=null;
  //     removeHandler(toast.id);
  //   };
  // }, [timer])
  
  return (
    <div className={`${backgroundColor} w-full rounded-s-md p-3 transition-all duration-700  dark:text-gray-200`}>
    <div className={`text-sm`}>
      <div className="flex justify-between items-center">
        <button className='cursor-pointer hover:text-gray-600 text-sm font-bold' onClick={()=>removeHandler()}>X</button>
        <p>{toast.seconds}</p>
        {!pause?<BsPin className='cursor-pointer hover:text-gray-600' onClick={()=>{setPause(true)}}/>:<BsFillPinFill className='cursor-pointer hover:text-gray-600' onClick={()=>{setPause(false);setToastList(o => o.map(ti => {if(ti.id === toast.id){return {...ti, seconds: ti.seconds - 1}}else{return ti}}))}}/>}
      </div>
      
      <hr className={`flex justify-between items-center my-1 ${backgroundColor}`}/>
      <p className='font-bold text-xs my-1'>{toast.title}</p>
      <p className='text-xs'>{toast.description}</p>

      {/* <p>{toast.id}</p> */}
    </div>
  </div>
  )
}

export default Toast