import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import useToken from '../hooks/useToken';
import moment from 'moment-jalaali'
import { FaCheck, FaChevronCircleDown, FaChevronCircleLeft, FaEdit, FaMinus, FaPlus} from 'react-icons/fa';
import * as XLSX from 'xlsx'
import { BeatLoader, ClipLoader, HashLoader } from 'react-spinners';
import {TagHandler, TagPanel} from '../components';
import parser from 'logic-query-parser';
import to from 'await-to-js';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { CgClose } from 'react-icons/cg';

const TagManagement = ({title}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const selectFileRef = useRef(null);
  const [newTag, setNewTag] = useState({id: null, title: '', parentId: null})

  useEffect(() => {
    getTags()
  }, [])
  
  const getTags = async() => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/tag/message/get-messages-tags`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    setTags(response.data.results)
    setIsLoading(false)
  }

  const handleUpdateTag = async(tag) => {
    if(tag.title.trim() === '') return
    setIsLoading(true)
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/tag/message/update-message-tag/${tag.id}`,
        {
          title: tag.title,
          parentId: tag.parentId
        },
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    getTags()
  }
  
  const handleInsertTag = async(tag)=>{
    if(tag.title.trim() === '') return
    setIsLoading(true)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/tag/message/add-message-tag`,
        {
          title: tag.title,
          parentId: tag.parentId
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }
    getTags()
  }

  const inputRef = useRef(null)

  moment.loadPersian()
  return (
    <div className={`relative flex h-full rounded px-1 min-w-[10rem]`}>

      <div className={`w-full h-full flex flex-col overflow-y-auto overflow-x-hidden scrollbar-thin rounded-b-md p-1 text-md border`}>
        {!isLoading && <div className="flex justify-start items-center gap-2">
          <input ref={inputRef} className='flex-grow px-2 py-1 rounded border' type="text" value={newTag.title} onChange={(e)=>{setNewTag({...newTag, title: e.target.value})}}/>
          <TagPanel prefix='والد:' tags={tags} selectedTags={newTag.parentId===null?[]:[newTag.parentId]} onSelect={(t)=>{setNewTag({...newTag, parentId: t})}} onRemoveTag={()=>setNewTag({...newTag, parentId: null})}/>
          <button onClick={()=>{setNewTag({id: null, title: '', parentId: null})}} className='border rounded  px-2 py-1 w-24 text-sm text-white font-bold bg-red-500 hover:bg-red-600'>بازنشانی</button>
          <button onClick={()=>{setNewTag({id: null, title: '', parentId: null});handleInsertTag(newTag)}} className='border rounded  px-2 py-1 w-24 text-sm text-white font-bold bg-green-500 hover:bg-green-600'>ثبت</button>
        </div>}
      {isLoading && <div className="w-full flex-grow flex justify-center items-center">
          <HashLoader
            color={'#36d7b7'}
            loading={isLoading}
            size={100}
          />
        </div>}
        {!isLoading && <TagElement tags={tags} mainTags={tags} onRemoveTag={()=>{}} onEditTag={(tag)=>handleUpdateTag(tag)} setParentTagId={(i)=>{inputRef.current.focus();setNewTag({...newTag, parentId: i})}}/>}
      </div>
    </div>
  );
};

const TagElement = ({tags, mainTags, onRemoveTag, onEditTag, className, setParentTagId}) => {
  const [showChilds, setShowChilds] = useState(tags.filter(t=>t.childs.length > 0).map(t=>t.id))
  const [editingTag, setEditingTag] = useState({id: null, title: '', parentId: null})

  const clickTagHandler = (id) => {
    setShowChilds(o=>{if(!showChilds.includes(id)){return [...new Set([...o, id])]}else{return o.filter(t=>t!==id)}})
  }
  
  return <div className={`flex flex-col gap-1 ${className} rounded-lg py-1 ps-1 text-sm w-full`}>
    {tags.map((tag, idx)=>
    <div>
    <div key={idx} className={`flex justify-between items-center gap-2 rounded-e-lg border rounded px-2 ${editingTag.id === tag.id?'bg-slate-300':'hover:bg-slate-100'}`}>
      <div draggable={true} className="flex flex-grow justify-center items-center gap-2 cursor-pointer">
        
        <button onDragCapture={()=>{}} className='text-gray-400 hover:text-gray-600 my-2 p-1' type="button"><MdOutlineDragIndicator/></button>
        {tag.childs && tag.childs.length > 0 && <button onClick={()=>clickTagHandler(tag.id)} className='text-gray-500 hover:text-gray-600 my-2' type="button"><FaChevronCircleLeft className={`${showChilds.includes(tag.id)?'-rotate-90':'rotate-0'} transition-all duration-700 ease-in-out`}/></button>}
        <p onClick={()=>clickTagHandler(tag.id)} className='flex-grow my-2 text-start' type="button">{tag.title}</p>
        {editingTag.id === tag.id && <div className="flex flex-grow justify-between items-center gap-2">
          <input className='flex-grow px-2 py-1 rounded border' type="text" value={editingTag.title} onChange={(e)=>{setEditingTag({...editingTag, title: e.target.value})}}/>
          <TagPanel prefix='والد:' notChooseableTags={[tag.id, ...tag.childs.map(c=>c.id)]} tags={mainTags} selectedTags={editingTag.parentId===null?[]:[editingTag.parentId]} onSelect={(t)=>{if(t===tag.id){return};setEditingTag({...editingTag, parentId: t})}} onRemoveTag={()=>setEditingTag({...editingTag, parentId: null})}/>
          <button onClick={()=>{setEditingTag({id: null, title: '', parentId: null})}} className='font-bold text-red-500'><CgClose/></button>
          <button onClick={()=>{setEditingTag({id: null, title: '', parentId: null});onEditTag(editingTag)}} className='font-bold text-green-500'><FaCheck/></button>
        </div>}
      </div>
      <div className="flex justify-center items-center gap-2">
        <button onClick={()=>{setEditingTag({id: tag.id, title: tag.title, parentId: tag.parentId})}} className='border rounded bg-gray-400 hover:bg-gray-600 text-white my-2 p-1' type="button"><FaEdit/></button>
        <button onClick={()=>{}} className='border rounded bg-red-400 hover:bg-red-600 text-white my-2 p-1' type="button"><RiDeleteBin6Fill /></button>
        <button onClick={()=>setParentTagId(tag.id)} className='border rounded bg-green-400 hover:bg-green-600 text-white my-2 p-1' type="button"><FaPlus /></button>
      </div>
    </div>
      {tag.childs.length > 0 && showChilds.includes(tag.id) && <div className="flex justify-start items-center gap-1 ms-4">
        <TagElement tags={tag.childs} mainTags={tags} onRemoveTag={onRemoveTag} onEditTag={onEditTag} setParentTagId={setParentTagId}/>
      </div>}
    </div>
    )}
  </div>
}

export default TagManagement;
