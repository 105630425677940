import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useToken from '../hooks/useToken';
import moment from 'moment-jalaali'
import DatePicker from 'react-multi-date-picker';
import * as XLSX from 'xlsx'
import { ClipLoader, HashLoader } from 'react-spinners';
import {DropDown, CheckBox, HDatePicker, HButton } from '../components';
import to from 'await-to-js';
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";
import { MdDelete, MdOutlineContentPaste, MdDeleteForever } from "react-icons/md";
import { FaPlus, FaEdit, FaCopy, FaPaste, FaSave, FaPlusSquare } from "react-icons/fa";
import { ImUndo2, ImDownload2 } from "react-icons/im";
import { IoIosCopy, IoIosSave } from "react-icons/io";
import { useElementScroll } from '../hooks/useScrollPercentage';

const SubjectManagement = ({title, addToastHandler}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [offset, setOffset] = useState(1)
  const [limit, setLimit] = useState(50)
  const [showAddSubject, setShowAddSubject] = useState(false)
  const [newSubject, setNewSubject] = useState({title: '', description: '', regionFrom: null, regionTo: null});
  const [hozehList, setHozehList] = useState([]);
  const [deleteSubjectId, setDeleteSubjectId] = useState(null);

  const abortRef = React.useRef(false);

  const selectFileRef = useRef(null);

  useEffect(() => {
    getSubjects()
    getHozehList()
  }, [])

  const getSubjects = async() => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/subject/subject/get-subjects`,
        {
          params: {
            offset: offset-1,
            limit
          },
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    setSubjects(response.data.results)
    setIsLoading(false)
  }

  const handleReadExcel = async (f) => {
    if(!f){
      // setMsg('فایلی انتخاب نشده است.')
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'فایلی انتخاب نشده است.',
        type: 'error', 
        seconds: 3
      })
      return
    }

    if (f) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const records = XLSX.utils.sheet_to_json(worksheet);

        setMessages(records.map(m=>{return {...m, ['relation.title']: changeResanehTitles.filter((r) => r.resaneh===m['relation.title']).length>0?changeResanehTitles.filter((r) => r.resaneh===m['relation.title'])[0].newResaneh:m['relation.title']}}))
        const lns = records.map((j,_)=>Object.entries(j).length)
        const indexOfMaxLength = lns.indexOf(Math.max(...lns))
        // setColumnCountIdx(indexOfMaxLength)
        const clmns = Object.keys(records[indexOfMaxLength]).map((k,idx)=>{return {id: idx, key: k}})
        setColumns(clmns)

        setMessageFields(messageFields.map(om=>
        {
          let ttt = []
          let excelColumn = ''
          switch (om.dbField) {
            case 'resaneh':
              ttt = ['relation.title']
              break;
            case 'titr':
              ttt = ['title']
              break;
            case 'link':
              ttt = ['url']
              break;
          }
          for (let i = 0; i < ttt.length; i++) {
            if(clmns.filter(c=>c.key === ttt[i]).length > 0){
              excelColumn = ttt[i]
              break
            }
          }
          return {...om, excelColumn: excelColumn}
        }
        ))
        setProgress(0)
      };
      reader.readAsBinaryString(f);
    }
  };

  const updateSubjectHandler = async(sub) => {
    if(sub.title.trim().length === 0) return
    // if(showAddSubjectPanel > -1 && subjects.filter(s => s.title === newSubjectTitle.trim()).length > 0) return
    setIsLoading(true)

    let url = process.env.REACT_APP_BASE_URL

    url += `/subject/subject/update-subject/${sub.id}`;
    const [err, response] = await to(
      axios.put(
        `${url}`,sub,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      return
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'سوژه با موفقیت ثبت شد.',
      type: 'success', 
      seconds: 3
    })
    // getSubjects()
    setIsLoading(false)
  }

  const saveExcel = ()=>{
    convertJsonToExcel(subjects);
  };

  const convertJsonToExcel = (data1)=>{
    const workbook = XLSX.utils.book_new();
    if(!workbook.Workbook) workbook.Workbook = {};
    if(!workbook.Workbook.Views) workbook.Workbook.Views = [];
    if(!workbook.Workbook.Views[0]) workbook.Workbook.Views[0] = {};
    workbook.Workbook.Views[0].RTL = true;
    
    const worksheet1 = XLSX.utils.json_to_sheet(data1);
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1')

    //Generate buffer
    XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'});
    //Binary string
    XLSX.write(workbook, {bookType: 'xlsx', type: 'binary'});

    const jdate = moment().locale('fa').format('jDD jMMMM jYYYY hhmmss')
    XLSX.writeFile(workbook, `${jdate}.xlsx`)
  };

  const getHozehList = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      return
    }
    setHozehList([... new Set(response.data.results.map(h => h.hozeh))].map(h => {return {id: h, title: h}}))
  }

  const addSubjectHandler = async() => {
    if(newSubject.title.trim().length === 0) return
    // if(showAddSubjectPanel > -1 && subjects.filter(s => s.title === newSubjectTitle.trim()).length > 0) return
    setIsLoading(true)
    const body = {
      title: newSubject.title.trim(),
      description: newSubject.description,
      regionFrom: newSubject.regionFrom,
      regionTo: newSubject.regionTo,
    }

    let url = process.env.REACT_APP_BASE_URL
    url += '/subject/subject/insert-subject';
    const [err, response] = await to(
      axios.post(
        `${url}`,body,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      return
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'سوژه با موفقیت ثبت شد.',
      type: 'success', 
      seconds: 3
    })
    getSubjects()
    setShowAddSubject(false)
    setNewSubject({title: '', description: '', regionFrom: null, regionTo: null})
    setIsLoading(false)
  }

  const deleteSubjectHandler = async(subjectId) => {
    setIsLoading(true)

    let url = process.env.REACT_APP_BASE_URL
    url += `/subject/subject/delete-subject/${subjectId}`;
    const [err, response] = await to(
      axios.delete(
        `${url}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setDeleteSubjectId(null)
      setIsLoading(false)
      return
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'سوژه با موفقیت حذف شد.',
      type: 'success', 
      seconds: 3
    })
    getSubjects()
    setDeleteSubjectId(null)
    setIsLoading(false)
  }
  const [scrollRef, { scrollPercentage, scrollTop }] = useElementScroll();
  moment.loadPersian()
  return (
    <div className="relative h-full box-border flex flex-col gap-1 justify-start items-stretch">
      <div className="w-full flex justify-start items-center gap-2">
        <label htmlFor="" className="text-xs">از موضوع:</label>
        <input type="text" value={offset} onChange={(e) => setOffset(e.target.value)} className="text-sm text-center flex justify-center items-center border rounded dark:border-gray-500 dark:bg-transparent focus:outline-teal-500" />
        <label htmlFor="" className="text-xs">تعداد موضوع:</label>
        <input type="text" value={limit} onChange={(e) => setLimit(e.target.value)} className="text-sm text-center flex justify-center items-center border rounded dark:border-gray-500 dark:bg-transparent focus:outline-teal-500" />
        <HButton onClick={() => getSubjects()} icon={<ImDownload2 />} title="فراخوانی" />
        <HButton onClick={() => saveExcel()} icon={<FaSave />} title="ذخیره اکسل" />
        <HButton onClick={() => setShowAddSubject(true)} className="whitespace-pre" icon={<FaPlusSquare />} title="موضوع جدید" />
      </div>
      <div ref={scrollRef} className="overflow-y-auto">
        <div style={{height: "1px"}} className="w-full rounded bg-secondary-dark-bg overflow-hidden sticky top-0 z-50">
          <div style={{width: `${scrollPercentage}%`}} className="absolute start-0 bg-amber-500 h-full"></div>
        </div>
        {showAddSubject && <div className="absolute z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="w-1/2 h-97 flex flex-col justify-center items-center gap-2 rounded-lg bg-white dark:bg-slate-700 p-2">
          <input type="text" className="w-full dark:bg-gray-800 rounded px-2 py-1 outline-none" value={newSubject.title} onChange={(e) => setNewSubject({...newSubject, title: e.target.value})} />
          <DropDown data={hozehList} fields={{value: 'title', title: 'title'}} className="w-full" value={newSubject.description} onChange={(s) =>{setNewSubject({...newSubject, description: s})}} />
          <div className="flex justify-between items-center">
          <HDatePicker className={`text-sm`} date={[newSubject.regionFrom, newSubject.regionTo]} setDate={(d) => {setNewSubject({...newSubject, regionFrom: d[0], regionTo: d[1]})}} />

          </div>
          <div className="flex justify-center items-center gap-2">
            <button onClick={()=> {setShowAddSubject(false)}} className="rounded border border-red-300 dark:border-amber-500 p-1 text-red-300 hover:text-red-500 dark:text-amber-300 dark:hover:text-amber-500">انصراف</button>
            <button onClick={()=> {addSubjectHandler()}} className="rounded border border-blue-300 dark:border-teal-500 p-1 text-blue-300 hover:text-blue-500 dark:text-teal-300 dark:hover:text-teal-500">ثبت</button>
          </div>
        </div>
        </div>}
        {deleteSubjectId !== null && <div className="absolute z-50 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="w-1/2 md:w-1/6 h-36 flex flex-col justify-center items-center gap-2 rounded-lg bg-white dark:bg-slate-700 p-2">
          <div className="w-full flex justify-center items-center gap-2">
            <button onClick={()=> {setDeleteSubjectId(null)}} className="w-1/2 rounded border border-red-300 dark:border-amber-500 p-1 text-red-300 hover:text-red-500 dark:text-amber-300 dark:hover:text-amber-500">انصراف</button>
            <button onClick={()=> {deleteSubjectHandler(deleteSubjectId)}} className="w-1/2 rounded border border-red-300 dark:border-red-500 p-1 text-red-300 hover:text-red-500 dark:text-red-300 dark:hover:text-red-500">حذف</button>
          </div>
        </div>
        </div>}
        {(isLoading) && <div className="w-full flex justify-center items-center">
              <Loader size={60}/>
        </div>}
        {!isLoading && subjects.map((subject, index) => 
          <Subject key={index} index={index} subject={subject} updateHandler={(sub) => {updateSubjectHandler(sub)}} deleteSubjectHandler={(subId) => {setDeleteSubjectId(subId)}} />
        )}
      </div>
    </div>
  );
};

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

const Subject = ({subject, index, updateHandler, deleteSubjectHandler}) => {
  const [sub, setSub] = useState(subject)

  const pasteHandler = () => {
    const savedSub = localStorage.getItem("savedSubject")
    if(!savedSub) return
    const {id, title, description, regionFrom, regionTo, ...savedSubject} = JSON.parse(savedSub)
    setSub({...sub, ...savedSubject})
  }

  return (
    <div className="w-full grid grid-cols-4 md:grid-cols-12 gap-2 rounded border dark:border-gray-500 p-2">
      <div className="col-span-4 md:col-span-3 flex justify-start items-center gap-2">
        <p className="text-xs">{index+1}</p>
        <div className="h-full grid grid-cols-2 grid-rows-3 gap-1">
          <input className="col-span-2 row-span-2 w-full text-sm text-center flex justify-center items-center border rounded dark:border-gray-500 dark:bg-transparent focus:outline-teal-500 outline-offset-4" value={sub.title} onChange={(e) => setSub({...sub, title: e.target.value})} />
          <input className="col-span-2 row-span-1 w-full text-xs text-center flex justify-center items-center border rounded dark:border-gray-500 dark:bg-transparent" value={sub.description} onChange={(e) => setSub({...sub, description: e.target.value})} />
          {/* <p className="col-span-1 row-span-1 w-full text-xs text-center flex justify-center items-center border rounded dark:border-gray-500 py-1">{moment(sub.regionFrom, 'YYYY-MM-DDTHH:mm:ss').format('jYYYY-jMM-jDD')}</p> */}
          <HDatePicker className={`text-xs`} date={[sub.regionFrom, sub.regionTo]} showArrowButtons={false} setDate={(d) => {setSub({...sub, regionFrom: d[0], regionTo: d[1]})}} />
          <div className="flex justify-between items-center gap-1">
            <div className="flex flex-col gap-2 text-xs">
              <p>{ numberCommaSeperator(parseInt(sub.iribKhabariCount) + parseInt(sub.newsMessageCount) + parseInt(sub.telegramMessageCount) + parseInt(sub.twitterMessageCount) + parseInt(sub.instagramMessageCount) + parseInt(sub.eitaaMessageCount) + parseInt(sub.rubikaMessageCount)) }</p>
              <p>{ numberCommaSeperator(Math.ceil(parseInt(sub.telegramTotalSeen)) + parseInt(sub.twitterTotalSeen) + parseInt(sub.instagramTotalSeen) + Math.ceil(parseInt(sub.eitaaTotalSeen)) + parseInt(sub.rubikaTotalSeen)) }</p>
            </div>
            <button onClick={() => setSub(subject)}><ImUndo2 /></button>
            <button onClick={() => {localStorage.setItem("savedSubject", JSON.stringify(sub))}}><IoIosCopy /></button>
            <button onClick={() => pasteHandler()}><MdOutlineContentPaste /></button>
          </div>
          {/* <p className="col-span-1 row-span-1 w-full text-xs text-center flex justify-center items-center border rounded dark:border-gray-500 py-1">{sub.regionTo}</p> */}
        </div>
      </div>
      <div className="col-span-2 grid grid-cols-2 gap-1">
        <p className="col-span-2 w-full text-xs text-center">صداوسیما</p>
        <input value={sub.iribShabakeCount} onChange={(e) => setSub({...sub, iribShabakeCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.iribShabakeList} onChange={(e) => setSub({...sub, iribShabakeList: e.target.value})} className={`w-full text-xs dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.iribKhabariCount} onChange={(e) => setSub({...sub, iribKhabariCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.iribKhabariTime} onChange={(e) => setSub({...sub, iribKhabariTime: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.iribFarhangiCount} onChange={(e) => setSub({...sub, iribFarhangiCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.iribFarhangiTime} onChange={(e) => setSub({...sub, iribFarhangiTime: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">خبرگزاری‌ها</p>
        <input value={sub.newsUserCount} onChange={(e) => setSub({...sub, newsUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.newsMessageCount} onChange={(e) => setSub({...sub, newsMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.newsTopUser} onChange={(e) => setSub({...sub, newsTopUser: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">تلگرام</p>
        <input value={sub.telegramUserCount} onChange={(e) => setSub({...sub, telegramUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.telegramMessageCount} onChange={(e) => setSub({...sub, telegramMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.telegramTotalSeen} onChange={(e) => setSub({...sub, telegramTotalSeen: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">توئیتر</p>
        <input value={sub.twitterUserCount} onChange={(e) => setSub({...sub, twitterUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.twitterMessageCount} onChange={(e) => setSub({...sub, twitterMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.twitterTotalSeen} onChange={(e) => setSub({...sub, twitterTotalSeen: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">اینستاگرام</p>
        <input value={sub.instagramUserCount} onChange={(e) => setSub({...sub, instagramUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.instagramMessageCount} onChange={(e) => setSub({...sub, instagramMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.instagramTotalSeen} onChange={(e) => setSub({...sub, instagramTotalSeen: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">ایتا</p>
        <input value={sub.eitaaUserCount} onChange={(e) => setSub({...sub, eitaaUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.eitaaMessageCount} onChange={(e) => setSub({...sub, eitaaMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.eitaaTotalSeen} onChange={(e) => setSub({...sub, eitaaTotalSeen: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-1 grid grid-cols-1 gap-1">
        <p className="w-full text-xs text-center">روبیکا</p>
        <input value={sub.rubikaUserCount} onChange={(e) => setSub({...sub, rubikaUserCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.rubikaMessageCount} onChange={(e) => setSub({...sub, rubikaMessageCount: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
        <input value={sub.rubikaTotalSeen} onChange={(e) => setSub({...sub, rubikaTotalSeen: e.target.value})} className={`w-full text-sm dark:bg-gray-600 border dark:border-gray-500 rounded px-2 py-1 text-center`} />
      </div>
      <div className="col-span-4 md:col-span-1 grid grid-cols-1 grid-rows-4 gap-1 border rounded dark:border-gray-500">
        <button onClick={() => updateHandler(sub)} className="relative row-span-3 inline-flex items-center justify-center px-6 py-3 overflow-hidden font-medium text-teal-600 transition duration-300 ease-out border-2 border-teal-500 shadow-md group">
          <span className="absolute inset-0 flex items-center justify-center w-full h-full text-2xl text-white duration-300 -translate-x-full bg-teal-500 group-hover:translate-x-0 ease">
            <IoIosSave />
          </span>
          <span className="absolute flex items-center justify-center w-full h-full text-teal-500 transition-all duration-300 transform group-hover:translate-x-full ease">ثبت</span>
          <span className="relative invisible">ثبت</span>
        </button>
        <button onClick={() => deleteSubjectHandler(sub.id)} className="relative row-span-1 inline-flex items-center justify-center px-6 py-3 overflow-hidden font-medium text-red-600 transition duration-300 ease-out border-2 border-red-500 shadow-md group">
          <span className="absolute inset-0 flex items-center justify-center w-full h-full text-2xl text-white duration-300 -translate-x-full bg-red-500 group-hover:translate-x-0 ease">
            <MdDeleteForever />
          </span>
          <span className="absolute flex items-center justify-center w-full h-full text-red-500 transition-all duration-300 transform group-hover:translate-x-full ease">حذف</span>
          <span className="relative invisible">حذف</span>
        </button>
      </div>
    </div>
  )
}

function numberCommaSeperator(number){
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export default SubjectManagement;
