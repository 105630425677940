import React, { useRef, useState } from 'react';
import {FaCheck} from 'react-icons/fa'

const SubmitComponent = ({children, onClick, className})=>{
  // const [selectedValue, setSelectedValue] = useState()

  return (
    <div className={`flex justify-between items-center gap-1 ${className}`}>
      {children}
      <button onClick={onClick} type="button" className="text-blue-700 hover:text-blue-400 dark:text-teal-500"><FaCheck/></button>
  </div>
  )
}

export default SubmitComponent;
