import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaTags } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { CgArrowsMergeAltH, CgArrowsShrinkH } from 'react-icons/cg';
import useToken from "./../hooks/useToken";
import { HashLoader } from 'react-spinners';
import axios from "axios";
import to from 'await-to-js';

function RightPanel({className, onSelectItem}) {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  const [accessToken] = useToken();
  const { t } = useTranslation();
  const [showFloatingMenu, setShowFloatingMenu] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const [accessList, setAccessList] = useState([])
  const [routes, setRoutes] = useState([
    {
        id: 10,
        name: "setting",
        title: "تنظیمات",
        icon: "config-icon",
        path: "/setting",
        access: "admin",
        showInPanel: true,
        order: "9",
        parentId: null
    },
    {
        id: 15,
        name: "labeling-lifeweb",
        title: "برچسب‌زنی لایف‌وب",
        icon: "lifeweb-icon",
        path: "/labeling-lifeweb",
        access: "costumer",
        showInPanel: true,
        order: "1",
        parentId: null
    },
    {
        id: 12,
        name: "labeling-dataak",
        title: "برچسب‌زنی دیتاک",
        icon: "dataak-icon",
        path: "/labeling-dataak",
        access: "costumer",
        showInPanel: true,
        order: "1",
        parentId: null
    },
    {
        id: 9,
        name: "user-management",
        title: "مدیریت کاربران",
        icon: null,
        path: "/user-management",
        access: "admin",
        showInPanel: false,
        order: "0",
        parentId: 10
    },
    {
        id: 1,
        name: "home",
        title: "صفحه اصلی",
        icon: "home-icon",
        path: "/",
        access: "costumer",
        showInPanel: true,
        order: "0",
        parentId: null
    },
    {
        id: 16,
        name: "question-management",
        title: "مدیریت سوالات",
        icon: "user-management-icon",
        path: "/question-management",
        access: "admin",
        showInPanel: true,
        order: "10",
        parentId: null
    },
    {
        id: 11,
        name: "change-password",
        title: "تغییر رمزعبور",
        icon: null,
        path: "/change-password",
        access: "costumer",
        showInPanel: false,
        order: "8",
        parentId: null
    },
    {
        id: 3,
        name: "upload-messages",
        title: "بارگذاری پیام",
        icon: "upload-icon",
        path: "/upload-messages",
        access: "costumer",
        showInPanel: true,
        order: "7",
        parentId: null
    },
    {
        id: 2,
        name: "report",
        title: "گزارش",
        icon: "report-icon",
        path: "/report",
        access: "costumer",
        showInPanel: true,
        order: "4",
        parentId: null
    },
    {
        id: 4,
        name: "hozeh-management",
        title: "مدیریت حوزه‌ها",
        icon: "hozeh-management-icon",
        path: "/hozeh-management",
        access: "admin",
        showInPanel: true,
        order: "5",
        parentId: null
    },
    {
        id: 5,
        name: "hozeh-mozuei-management",
        title: "مدیریت مولفه‌ها",
        icon: "hozeh-mozuei-management-icon",
        path: "/hozeh-mozuei-management",
        access: "admin",
        showInPanel: true,
        order: "6",
        parentId: null
    },
    {
        id: 14,
        name: "tagging",
        title: "ثبت حوزه",
        icon: "tag-icon",
        path: "/tagging",
        access: "costumer",
        showInPanel: true,
        order: "2",
        parentId: null
    },
    {
        id: 13,
        name: "subject-management",
        title: "مدیریت موضوعات",
        icon: "tag-icon",
        path: "/subject-management",
        access: "costumer",
        showInPanel: true,
        order: "11",
        parentId: null
    }
])
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    switch (user?.role.name) {
      case 'admin':
        setAccessList(['admin', 'costumer'])
        break;
      case 'costumer':
        setAccessList(['costumer'])
        break;
      default:
        setAccessList([])
        break;
    }
  }, [user])

  useEffect(()=>{
    // getRoutes()
  },[])

  const getRoutes = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-routes`,{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      return
    }
    setIsLoading(false)
    setRoutes(response.data.results)
  }
  
  return (
    <div className={`h-full flex flex-col gap-4 md:gap-4 py-2 px-1 md:p-2 ${showFloatingMenu?'md:w-40':'md:w-10'} transition-all duration-200 ease-linear ${className}`}>
      {/* <NavBtn className={`hidden md:block`} onClick={()=>setShowFloatingMenu(o=>!o)} showFloatingMenu={showFloatingMenu} title={''} iconName={!showFloatingMenu?<CgArrowsShrinkH/>:<CgArrowsMergeAltH/>}/> */}
      <button onClick={()=>setShowFloatingMenu(o=>!o)} type="button" className={`flex justify-start items-center gap-2 text-2xl md:text-2xl p-1 whitespace-pre ${showFloatingMenu?('text-white md:text-gray-500'):('text-white md:text-gray-400')} hover:text-blue-700 dark:hover:text-teal-200`}>
        {!showFloatingMenu?<CgArrowsShrinkH/>:<CgArrowsMergeAltH/>}
        {showFloatingMenu && <p className='text-xs font-bold'>{''}</p>}
      </button>
      {routes.sort((a,b) => a.order - b.order).map((menuItem, idx)=>
        <NavBtn key={idx} enable={menuItem.showInPanel && accessList.includes(menuItem.access)} onClick={()=>{onSelectItem();navigate(menuItem.path);}} selected={location.pathname === menuItem.path} showFloatingMenu={showFloatingMenu} title={menuItem.title} iconName={menuItem.icon} />
      )}
      <MenuItem routes={routes.filter(r => r.parentId === null)} accessList={accessList} showFloatingMenu={showFloatingMenu} />
      {isLoading && <Loader size={30}/>}
    </div>
  );
}

const NavBtn = ({showFloatingMenu, title, iconName, onClick, selected, className, enable=true})=>{
  return <button onClick={onClick} type="button" className={`${!enable && 'hidden'} flex justify-start items-center gap-2 p-1 ${showFloatingMenu?(selected?'text-blue-500 md:text-white md:bg-gray-500 rounded':'text-white md:text-gray-500'):(selected?'text-blue-500 md:text-white md:bg-gray-400 dark:md:bg-gray-600 rounded':'text-white md:text-gray-400')} hover:text-blue-700 dark:hover:text-teal-200 ${className}`}>
    <div className="w-7"><DynamicIconLoader iconName={iconName} /></div>
    {showFloatingMenu && <p className='text-xs font-bold hidden md:block'>{title}</p>}
  </button>
}

const MenuItem = ({showFloatingMenu, title, key, routes, iconName, onClick, selected, className, accessList})=>{
  // console.log('test')
  return 
  <div className="flex flex-col">
  {routes.sort((a, b) => a.order - b.order).map((menuItem, idx)=>
    (<button onClick={onClick} type="button" className={`${!enable && 'hidden'} flex justify-start items-center gap-2 text-2xl md:text-2xl p-1 whitespace-pre ${showFloatingMenu?(selected?'text-blue-500 md:text-white md:bg-gray-500 rounded':'text-white md:text-gray-500'):(selected?'text-blue-500 md:text-white md:bg-gray-400 rounded':'text-white md:text-gray-400')} hover:text-blue-700 dark:hover:text-teal-200 ${className}`}>
    <DynamicIconLoader iconName={menuItem.iconName} />
    {showFloatingMenu && <p className='text-xs font-bold'>{menuItem.title}</p>}
  </button>
    )
  )}
  </div>
}

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

const DynamicIconLoader = ({ iconName }) => {
  const [Icon, setIcon] = useState(null);
  useEffect(() => {
    import(`./icons/${iconName}`)
      .then(module => setIcon(() => module.default))
      .catch(err => {
        // console.error(`Error loading icon ${iconName}:`, err);
        setIcon(null);
      });
  }, [iconName]);

  return Icon ? <Icon /> : <FaTags />;
};

export default RightPanel;
