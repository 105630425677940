import axios from 'axios';
import React, { useEffect, useCallback, useState, useRef } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import useToken from '../hooks/useToken';
import moment from 'moment-jalaali'
import { FaArrowsAltH, FaArrowsAltV, FaCheck, FaFileExcel, FaInstagram, FaMinus, FaPlus, FaTags, FaTelegram, FaTelegramPlane, FaTwitter, FaTwitterSquare } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import * as XLSX from 'xlsx'
import { ClipLoader, HashLoader } from 'react-spinners';
import { BsInputCursorText  } from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';
import {Message, PagesPanel, QueryBuilder, TagHandler, TagPanel, ThreeStateCheckbox, HDateTimePicker, CheckBox} from '../components';
import parser from 'logic-query-parser';
import to from 'await-to-js';
import useOutsideClicked from '../hooks/useOutsideClick';
import { AiFillDelete } from 'react-icons/ai';
import { FaLink, FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import DropDown from '../components/drop-down';
import News from './labeling/news';
import copy from "copy-to-clipboard"

const LabelingDataak = ({title}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD 00:00:00'))
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD HH:mm:ss'))
  const [accessToken] = useToken();
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataakToken, setDataakToken] = useState("");
  const [dataakSubjects, setDataakSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [subjects, setSubjects] = useState([]);
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([]);
  const [taggedMessages, setTaggedMessages] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [messages, setMessages] = useState({meta:0, data: []});
  const [isLoading, setIsLoading] = useState(false);
  const [showUploadPanel, setShowUploadPanel] = useState(false);
  const [filters, setFilters] = useState({roydad: '', dataakPlatform: 'news', platform: 7, iranian_platforms: ['eitaa_channel_post', 'rubika_channel_post', 'bale_channel_post'],subject: 21840, resource: null, tag: null, newTag: 3547, text: '', notTagged: true, notSelected: false, page: 1})
  const [currentPlatform, setCurrentPlatform] = useState('news')
  const [showPopUp, setShowPopUp] = useState(null)
  const [showGuidePanel, setShowGuidePanel] = useState(false)
  const [messagesState, setMessagesState] = useState(0)
  const [getAllProgress, setGetAllProgress] = useState(null)

  const popUpRef = useRef(null)

  useOutsideClicked(popUpRef, () => {setShowPopUp(null)})

  useEffect(() => {
    getAppConfig()
    getPlatforms()
    getSubjects()
  }, [])
  

  useEffect(() => {
    getSubjectsFromDataak()
    getResources()
    getTags()
  }, [dataakToken])

  useEffect(() => {
    handleSearch(filters.page)
  }, [filters.page])

  useEffect(() => {
    getTaggedMessages()
  }, [messages])

  const getPlatforms = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/platform/platform/get-platforms`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setPlatforms(response.data.results)
  }

  const getSubjects = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/subject/subject/get-subjects`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setSubjects(response.data.results)
  }

  const getAppConfig = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-config`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }
    setDataakToken(response.data.results.filter(c => c.title === 'dataak')[0].config.token)
  }

  const getSubjectsFromDataak = async() => {
    if(dataakToken === '') return
    const [err, response] = await to(
      axios.get(
        `https://api.dataak.com/search/queries?no_paginate=1&orderBy%5B0%5D%5Bfield%5D=created_at&orderBy%5B0%5D%5Btype%5D=desc`,
        {
          headers: { 'authorization': `Bearer ${dataakToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setDataakSubjects(response.data.result.data)
  }

  const getResources = async() => {
    if(dataakToken === '') return
    const [err, response] = await to(
      axios.get(
        `https://api.dataak.com/search/tags?paginate=100&orderBy%5Btype%5D=desc&orderBy%5Bfield%5D=pin&page=1`,
        {
          headers: { 'authorization': `Bearer ${dataakToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setResources(response.data.result.data)
  }

  const getTags = async() => {
    if(dataakToken === '') return
    const [err, response] = await to(
      axios.get(
        `https://api.dataak.com/dynamic_index_analyzer/api/v1/tagCategory?with%5B0%5D=tags&sort%5B0%5D=created_at%2Cdesc&no_paginate=`,
        {
          headers: { 'authorization': `Bearer ${dataakToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setTags(response.data.result.data.map(d => d.tags).flat())
  }

  const saveExcel = ()=>{
    if(messages.data.filter(m => m.state===2).length === 0) return
    const filteredRows = messages.data.filter(m => m.state===2).map(m => {return {id: m.id, resaneh: m['relation.title'], title: m['title']}})
    convertJsonToExcel(filteredRows)
  }

  const convertJsonToExcel = (data1)=>{
    const workbook = XLSX.utils.book_new();
    if(!workbook.Workbook) workbook.Workbook = {};
    if(!workbook.Workbook.Views) workbook.Workbook.Views = [];
    if(!workbook.Workbook.Views[0]) workbook.Workbook.Views[0] = {};
    workbook.Workbook.Views[0].RTL = true
    
    const worksheet1 = XLSX.utils.json_to_sheet(data1);
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1')

    //Generate buffer
    XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'});
    //Binary string
    XLSX.write(workbook, {bookType: 'xlsx', type: 'binary'});

    const jdate = moment().locale('fa').format('jDD jMMMM jYYYY hhmmss')
    XLSX.writeFile(workbook, `${jdate}.xlsx`)
  }

  const handleSearch = async(page)=>{
    if(dataakToken === '') return
    setGetAllProgress(null)
    setMessages({meta:0, data: []})
    setIsLoading(true)

    const [err, response] = await fetchData(page)
    setSelectedRows([])
    setCurrentPlatform(filters.platform)
    setMessages({...response.data.result, data: response.data.result.data.map(m =>{return {...m, state: 0}})})
    setMessagesState(0)
    setIsLoading(false)
  }

  const fetchData = async(page)=>{
    let params = `https://api.dataak.com/search/api/v1/search?api_path=%2F${filters.dataakPlatform}%2Fposts&want_tag_content=1`
    if(filters.resource !== null){
      params += '&tag_id=' + filters.resource
    }
    if(filters.tag !== null){
      params += '&content_tag_ids%5B0%5D=' + filters.tag
    }
    if(filters.subject !== null){
      params += '&query=' + filters.subject
    }
    if(page > 1){
      params += '&page=' + page;
    }
    if(filters.dataakPlatform === 'iranian-platforms'){
      params += '&platforms%5B0%5D=' + filters.iranian_platforms;
    }
    params = `${params}&from=${(new Date(fromDate)).getTime()/1000}&to=${(new Date(toDate)).getTime()/1000}&count=100&sort=time&sort_type=desc&not_resource_ids%5B1%5D=razavi.ir&smart_highlight=1`; 
    
    return await to(
      axios.get(
        params,
        {
          headers: { Authorization: `Bearer ${dataakToken}` }
        }
      )
    )
  }

  const handlSearchAll = async() =>{
    if(dataakToken === '') return
    setMessages({meta:0, data: []})
    setIsLoading(true)

    const [err, response] = await fetchData(1)
    const pages = [...Array(Math.ceil(response.data.result.meta.total/100))].map((_,idx) => idx+1)
    let msgs = response.data.result.data.map(m =>{return {...m, state: 0}})
    setGetAllProgress(`${((1+1)/pages.length*100).toFixed(0)}%`)
    for (let i = 1; i < pages.length; i++) {
      const [err, response] = await fetchData(pages[i])
      msgs.push(...response.data.result.data.map(m =>{return {...m, state: 0}}))
      setGetAllProgress(`${((i+2)/pages.length*100).toFixed(0)}%`)
    }
    setSelectedRows([])
    setCurrentPlatform(filters.platform)
    setMessages({...response.data.result, data: msgs})
    setMessagesState(0)
    setIsLoading(false)
    setGetAllProgress("0%")
  }

  const handleTag = async()=>{
    if(dataakToken === '') return
    setIsLoading(true)
    const [err, response] = await to(
      axios.patch(
        `https://api.dataak.com/dynamic_index_analyzer/api/v2/taggedContent/multi`,{
          contents: messages.data.filter(m => m.state===2).map(m => {return {
            platform: "news",
            taggable_type: "post",
            taggable_id: m.id,
            platform_user_id: m['relation.id'],
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            tags: [
                filters.newTag
            ]
          }})
        },
        {
          headers: { Authorization: `Bearer ${dataakToken}` }
        }
      )
    )
    if(err){
      setIsLoading(false)
      return
    }
    if(getAllProgress===null)
      handleSearch(filters.page)
    else
      handlSearchAll()
    setSelectedRows([])
  }

  const getTaggedMessages = async()=>{
    const body = {
      from: fromDate,
      to: toDate
    }
    
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/get-messages`, body,
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      return
    }

    setTaggedMessages(response.data.results)
  }

  const dateRef = useRef(null)

  const selectAllRowsHandler = () => {
    let allRows;
    switch (true) {
      case (currentPlatform < 7):
        allRows = messages.data.filter((message, index) => message.state!==1).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.description.indexOf(filters.text) > -1).map(m => m.id);
        break;
      case (currentPlatform === 7):
        allRows = messages.data.filter((message, index) => message.state!==1).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.title.indexOf(filters.text) > -1).map(m => m.id);
        break;
      default:
        break;
    }
    const newRows = [...selectedRows, ...allRows];
    setMessages({...messages, data: messages.data.map(m => {return allRows.includes(m.id)?{...m, state:2}:m})})
    setFilters({...filters, text: ""})
  };

  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    if(showGuidePanel){
      sleep(3000).then(d => setShowGuidePanel(false));
    }
  
    return () => {
    }
  }, [showGuidePanel])
  
  const deleteSubjectHandler = async(id) => {
    const [err, response] = await to(
      axios.delete(
        `${process.env.REACT_APP_BASE_URL}/message/delete-one-message/${id}`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    getTaggedMessages()
  }

  const handleKeyPress = (event) => {
    if(event.ctrlKey && event.altKey && !showGuidePanel){
      setShowGuidePanel(true)
    }
    if(event.ctrlKey && event.altKey && (event.key === "ظ" || event.key === "z")){
      deleteMessagesFromQueueHandler()
    }
    if(event.ctrlKey && event.altKey && (event.key === "ش" || event.key === "a")){
      selectAllRowsHandler()
    }
    if(event.ctrlKey && event.altKey && (event.key === "ّ" || event.key === "f")){
      navigator.clipboard.readText()
      .then(text =>
        {// serachInputRef.current.value = text
          setFilters({...filters, text: text.trim()})
        }
      )
      .catch(err => 
        console.error('Failed to read clipboard contents: ', err)
      )
      serachInputRef.current.focus()
    }
  };

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const deleteMessagesFromQueueHandler = () => {
    // setMessages({...messages, data: messages.data.filter(m => 
    //   !messages.data
    //   .filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true)
    //   .filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true)
    //   .filter((message, index) => message.title.indexOf(filters.text) > -1)
    //   .map((message, index) => message.id).includes(m.id))});
    const tempMsgsIds = messages.data
    .filter((message, index) => message.state!=2)
    .filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true)
    .filter((message, index) => message.title.indexOf(filters.text) > -1)
    .map((message, index) => message.id)
    setMessages({...messages, data: messages.data.map(m => {return tempMsgsIds.includes(m.id)?{...m, state: 1}:m})})
    setFilters({...filters, text: "" })
  }

  const serachInputRef = useRef(null)

  const computeDates = () => {
    const dates = [...Array(7)].map((_, idx) => {
      const date = new Date();
      return date.setDate(date.getDate() - idx - 1)
    });

    return dates.map((date, idx) => {
      const d = new Date(date);
      return {date: d, title: `${idx+1} روز قبل`}
    })
  }

  moment.loadPersian()
  return (
    <div className="sticky h-full box-border flex flex-col gap-3 justify-start items-stretch dark:bg-secondary-dark-bg">
      {showGuidePanel && <div className="fixed bottom-5 end-5 rounded bg-gray-900 px-2 py-1 text-xs">
        <p>{`Ctrl + f ====> فیلتر پیام‌های دارای متن انتخاب شده`}</p>
        <p>{`Ctrl + d ====> حذف پیام نمایش داده شده از لیست`}</p>
      </div>}
      <div className="grid grid-cols-12 gap-2 bg-slate-500 px-2 py-1 dark:bg-gray-700 rounded">
        <div className="col-span-12 flex justify-start items-center gap-1">
          {computeDates().map((date, idx) => 
            <button dir="rtl" onClick={() => {setFromDate(date.date.setHours(0, 0, 0, 0));setToDate(date.date.setHours(23, 59, 59, 0))}} className={`rounded text-xs bg-teal-800 px-2`}>{date.title}</button>
          )}
        </div>
        <HDateTimePicker className={`col-span-12 md:col-span-3 text-xs`} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate}/>
        <DropDown beginFrom="start" placeHolder="پلتفرم" onClear={() => {}} showClearButton={false} className={`md:whitespace-pre col-span-12 md:col-span-1`} onChange={(s) => {setFilters({...filters, platform: s, dataakPlatform: platforms.filter(p => p.id === s)[0].dataakName, iranian_platforms: (s === 4?'eitaa_channel_post':(s === 5?'bale_channel_post':(s === 6?'rubika_channel_post':null)))})}} value={filters.platform} data={platforms.map(p => {return {value: p.id, title: p.title}})} />
        <DropDown beginFrom="start" placeHolder="موضوع" onClear={() => setFilters({...filters, subject: null})} className="col-span-4 md:col-span-1 md:whitespace-pre" onChange={(s) => setFilters({...filters, subject: s})} value={filters.subject} data={dataakSubjects.map((subject, index) => {return {value: subject.id, title: subject.title}})} />
        <DropDown beginFrom="end" placeHolder="برچسب" onClear={() => setFilters({...filters, tag: null})} className="col-span-4 md:col-span-1 md:whitespace-pre" onChange={(s) => setFilters({...filters, tag: s})} value={filters.tag} data={tags.map((tag, index) => {return {value: tag.id, title: tag.value}})} />
        <DropDown beginFrom="end" placeHolder="منبع" className="col-span-4 md:col-span-1" onClear={() => setFilters({...filters, resource: null})} onChange={(s) => setFilters({...filters, resource: s})} value={filters.resource} data={resources.map((resource, index) => {return {value: resource.id, title: resource.name}})} />
        <div className="col-span-12 md:col-span-2 flex justify-start items-center gap-3">
          <p className="text-xs">کل: {messages.meta.total}</p>
          {getAllProgress===null && <p className="text-xs">این صفحه: {messages.data.length}</p>}
        </div>
        <div className="col-span-12 md:col-span-3 w-full flex justify-between items-center gap-2">
          <button className={`w-2/5 py-1 rounded border ${getAllProgress!==null?"border-blue-200 text-blue-200":"border-amber-200 border-2 text-amber-200"} hover:text-white hover:bg-blue-400 px-2 text-xs whitespace-pre`} onClick={() => {if(filters.page !== 1){setFilters({...filters, page: 1})}else{handleSearch(filters.page)}}}>دریافت</button>
          <button className={`relative w-2/5 py-1 rounded border ${getAllProgress===null?"border-blue-200 text-blue-200":"border-amber-200 border-2 text-amber-200"} overflow-hidden hover:text-white hover:bg-blue-400 px-2 text-xs`} onClick={() => {handlSearchAll()}}>
            <div style={{width: getAllProgress}} className="absolute -z-0 inset-y-0 start-0 bg-blue-700 whitespace-pre"></div>
            <p className="relative z-10 whitespace-pre">دریافت همه</p>
          </button>
          <button className="w-1/5 py-1 rounded border text-blue-200 border-blue-200 hover:text-white hover:bg-blue-400 px-2 text-xs" onClick={() => saveExcel()}>اکسل</button>
        </div>
        <div className="col-span-12 flex flex-wrap justify-center items-center gap-1">
          {messagesState!==4 && <p className="text-xs">{messages.data.filter((message, index) => message.state===messagesState).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.title.indexOf(filters.text) > -1).length}</p>}
          {messagesState===4 && <p className="text-xs">{messages.data.filter((message, index) => message['content_tags'].length > 0).filter((message, index) => message.title.indexOf(filters.text) > -1).length}</p>}
          {messagesState===0 && <button onClick={()=>{selectAllRowsHandler()}} className={`text-2xl font-bold text-teal-300 hover:text-teal-500 dark:text-gray-300 hover:dark:text-gray-400`}><FaRegCheckSquare /></button>}
          {messagesState===2 && <button onClick={()=>setMessages({...messages, data: messages.data.map(m => {return m.state===2?{...m, state: 0}:m})})} className={`text-2xl font-bold py-1 text-teal-300 hover:text-teal-500 dark:text-gray-300 hover:dark:text-gray-400`}><FaRegSquare/></button>}
          <p className="text-xs">صفحه: {filters.page}</p>
          {/* <button onClick={()=>{setSelectedRows(selectedRows.length === messages.data.length?[]:messages.data.filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.title.indexOf(filters.text) > -1).map(m => m.id))}} className="text-2xl text-teal-800">{selectedRows.length === messages.data.length?<FaRegCheckSquare/>:<FaRegSquare/>}</button> */}
          <input ref={serachInputRef} className={`flex-grow text-sm border rounded px-2 py-1 outline-none dark:bg-gray-600 dark:border-gray-500`} placeholder="فیلتر..." value={filters.text} onChange={(e) => setFilters({...filters, text: e.target.value})} />
          <div className="flex flex-wrap justify-center items-center gap-2">
            <div className="flex justify-center items-center gap-2">
              <button className={`text-xs border px-2 py-1 rounded ${messagesState===2 && "bg-blue-500"}`} onClick={() => setMessagesState(2)}>انتخاب شده‌ها {messages.data.filter((message, index) => message.state===2).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).length}</button>
              <button className={`text-xs border px-2 py-1 rounded ${messagesState===0 && "bg-blue-500"}`} onClick={() => setMessagesState(0)}>مانده‌ها {messages.data.filter((message, index) => message.state===0).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).length}</button>
              <button className={`text-xs border px-2 py-1 rounded ${messagesState===1 && "bg-blue-500"}`} onClick={() => setMessagesState(1)}>حذف شده‌ها {messages.data.filter((message, index) => message.state===1).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).length}</button>
              <button className={`text-xs border px-2 py-1 rounded ${messagesState===4 && "bg-blue-500"}`} onClick={() => setMessagesState(4)}>دارای برچسب {messages.data.filter((message, index) => message['content_tags'].length > 0).length}</button>
            </div>
            <div className="flex justify-center items-center gap-2">
            {/* <CheckBox className="text-xs text-white" boxStyle='text-teal-300 dark:text-teal-500' value={filters.notSelected} onChange={(e) => {setFilters({...filters, notSelected: e})}} title="انتخاب نشده" /> */}
              <CheckBox className="text-xs text-white" boxStyle='text-teal-300 dark:text-teal-500' value={filters.notTagged} onChange={(e) => {setFilters({...filters, notTagged: e})}} title="بدون برچسب" />
              {currentPlatform===7 && <button onClick={()=>{deleteMessagesFromQueueHandler()}} className={`text-2xl font-bold py-1 text-red-300 hover:text-red-500 dark:text-red-300 hover:dark:text-red-400`}><AiFillDelete /></button>}
              {<DropDown beginFrom="start" placeHolder="برچسب" onClear={() => setFilters({...filters, newTag: null})} className="col-span-6 min-w-[6rem] flex-grow text-xs md:col-span-1 " onChange={(s) => {setFilters({...filters, newTag: s})}} value={filters.newTag} data={tags.map((tag, index) => {return {value: tag.id, title: tag.value}})} />}
              {<button className="col-span-6 md:col-span-1 rounded border text-teal-300 px-2 text-xs py-1 border-teal-300 hover:text-white hover:bg-teal-600" onClick={() => {handleTag()}}>اختصاص</button>}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 flex-grow overflow-y-auto overflow-x-hidden bg-slate-50 dark:bg-secondary-dark-bg">
      {(isLoading) && <div className="flex justify-center items-center h-full">
          <Loader size={60}/>
      </div>}
        {!isLoading && messages.meta.total === 0 && <p className="w-full text-center text-lg text-red-800 font-bold py-2">متاسفانه پیامی یافت نشد...</p>}

        {/* News */}
        {!isLoading && currentPlatform === 7 && messagesState!==4 && messages.data.filter((message, index) => message.state===messagesState).filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.title.indexOf(filters.text) > -1).map((message, index) => 
          // <News key={index} id={message.id} setSelectedText={(t) => copy(t)} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} onDelete={(id) => setMessages({...messages, data: messages.data.map(m => {return m.id!==id?m:{...m, state:1}})})} counter={index+1} setShowPopUp={(id) => {setShowPopUp(id)}} username={message['relation.title']} url={message.url} tags={message['content_tags']} title={message.title} description={message.description} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
          <News key={index} id={message.id} setSelectedText={(t) => copy(t)} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} onDelete={(id) => setMessages({...messages, data: messages.data.map(m => {return m.id!==id?m:{...m, state:1}})})} counter={index+1} setShowPopUp={(id) => {setShowPopUp(id)}} username={message['relation.title']} url={message.url} tags={message['content_tags']} title={message.title} description={message.description} time={message.time} selectedRows={messages.data.filter(m => m.state===2).map(m => m.id)} setSelectedRows={() => setMessages({...messages, data: messages.data.map(m => {return m.id!==message.id?m:{...m, state: m.state===2?0:2}})})} />
        )}
        {!isLoading && currentPlatform === 7 && messagesState===4 && messages.data.filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => message['content_tags'].length > 0).filter((message, index) => message.title.indexOf(filters.text) > -1).map((message, index) => 
          // <News key={index} id={message.id} setSelectedText={(t) => copy(t)} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} onDelete={(id) => setMessages({...messages, data: messages.data.map(m => {return m.id!==id?m:{...m, state:1}})})} counter={index+1} setShowPopUp={(id) => {setShowPopUp(id)}} username={message['relation.title']} url={message.url} tags={message['content_tags']} title={message.title} description={message.description} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
          <News key={index} id={message.id} setSelectedText={(t) => copy(t)} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} onDelete={(id) => setMessages({...messages, data: messages.data.map(m => {return m.id!==id?m:{...m, state:1}})})} counter={index+1} setShowPopUp={(id) => {setShowPopUp(id)}} username={message['relation.title']} url={message.url} tags={message['content_tags']} title={message.title} description={message.description} time={message.time} selectedRows={messages.data.filter(m => m.state===2).map(m => m.id)} setSelectedRows={() => setMessages({...messages, data: messages.data.map(m => {return m.id!==message.id?m:{...m, state: m.state===2?0:2}})})} />
        )}
        {messages.data.filter((message, index) => message.id === showPopUp).length > 0 && showPopUp && <div className="absolute h-full bg-black bg-opacity-75 top-1 w-full px-24 py-1 flex justify-center items-center text-sm rounded">
          <div ref={popUpRef} className="w-full max-h-[720px] overflow-y-auto p-5 border bg-white dark:bg-secondary-dark-bg rounded" dangerouslySetInnerHTML={{__html: messages.data.filter((message, index) => message.id === showPopUp)[0].content.replaceAll(/color:#/g, "").replaceAll(/background-color:/g, "").replaceAll(/<img/g, /<img style="width:500px;height:281px"/g).replaceAll('آستان', '<span style="color:red">آستان</span>').replaceAll('قدس', '<span style="color:red">قدس</span>').replaceAll('رضوی', '<span style="color:red">رضوی</span>').replaceAll('ملک', '<span style="color:red">ملک</span>')}}/>
        </div>}

          {/*  */}
        {!isLoading && currentPlatform === 2 && messages.data.filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.description.indexOf(filters.text) > -1).map((message, index) => 
          <News key={index} id={message.id} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} counter={index+1} username={message['username']} url={message.url} tags={message['content_tags']} description={message.description} title={''} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
        )}

        {!isLoading && currentPlatform === 3 && messages.data.filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.description.indexOf(filters.text) > -1).map((message, index) => 
          <News key={index} id={message.id} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} counter={index+1} platform='instagram' username={message['username']} like_count={message.like_count} view_count={message.view_count} url={message.url} tags={message['content_tags']} description={message.description} title={''} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
        )}

        {!isLoading && currentPlatform === 1 && messages.data.filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.description.indexOf(filters.text) > -1).map((message, index) => 
          <News key={index} id={message.id} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} counter={index+1} platform='twitter' username={message['username']} like_count={message.like_count} view_count={message.view_count} retweet_count={message.retweet_count} url={message.url} tags={message['content_tags']} description={message.description} title={''} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
        )}
        
        {!isLoading && [4,5,6].includes(currentPlatform) && messages.data.filter((message, index) => filters.notSelected?(!selectedRows.includes(message.id)):true).filter((message, index) => filters.notTagged?(message['content_tags'].length === 0):true).filter((message, index) => message.description.indexOf(filters.text) > -1).map((message, index) => 
          <News key={index} id={message.id} taggedMessages={taggedMessages} deleteSubjectHandler={(id) => deleteSubjectHandler(id)} counter={index+1} platform={message.platform} username={message['username']} like_count={message.like_count} view_count={message.view_count} retweet_count={message.retweet_count} url={message.url} tags={message['content_tags']} description={message.description} title={''} time={message.time} selectedRows={selectedRows} setSelectedRows={() => setSelectedRows(o=>{if(o.includes(message.id)){return o.filter(f=>f!==message.id)}else{return [...o, message.id]}})} />
        )}

      </div>
      {/* <DropDown beginFrom="start" onClear={() => setFilters({...filters, subject: null})} className="col-span-4 md:col-span-1 md:whitespace-pre" onChange={(s) => setFilters({...filters, subject: s})} value={filters.subject} data={dataakSubjects.map((subject, index) => {return {value: subject.id, title: subject.title}})} /> */}

      {getAllProgress===null && <PagesPanel currentPage={filters.page -1} totalRecords={messages.meta.total || 0} recordInPage={100} onChanegPage={(s)=>{setFilters({...filters, page: s+1})}} />}
    </div>
  );
};

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

export default LabelingDataak;
