import React from 'react';

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M481.1 81.45l-202.6-77.3C271.2 1.383 263.6 0 256 0S240.8 1.383 233.5 4.145L30.89 81.45C12.29 88.55 0 106.4 0 126.3v259.4c0 19.91 12.29 37.75 30.89 44.85l202.6 77.3C240.8 510.6 248.4 512 256 512s15.23-1.383 22.47-4.145l202.6-77.3C499.7 423.5 512 405.6 512 385.7V126.3C512 106.4 499.7 88.55 481.1 81.45zM256 64.07l166.2 63.41L256 189.8L89.77 127.5L256 64.07zM288 435.9V246.2l160-60v188.5L288 435.9z"/></g>
  </svg>
};


export default Icon;
