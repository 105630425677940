import React, { useEffect, useRef, useState } from 'react';
import { DropDown, Header, RadioButton } from '../components';
import { GrLocation } from 'react-icons/gr';
import axios from 'axios';
import useToken from '../hooks/useToken';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClicked from '../hooks/useOutsideClick';
import to from 'await-to-js';
import FormData from 'form-data';

const UserManegement = ({title, addToastHandler}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const toolbarOptions = ['Search'];

  const [accessToken, setToken] = useToken()

  const editing = { allowDeleting: true, allowEditing: true };
  const [users, setUsers] = useState([])
  const [username, setUserName] = useState('')
  const [roles, setRoles] = useState([{id: 1, name: 'admin'}, {id: 2, name: 'costumer'}])
  const [role, setRole] = useState()
  const [avatarFile, setAvatarFile] = useState()
  const [errorMessage, setErrorMessage] = useState("")
  const [editingUser, setEditingUser] = useState(null)
  const [newUser, setNewUser] = useState({firstname: '', lastname: '', username: '', gender: '', phone: ''})

  const dispatch = useDispatch();

  const getUsers = async()=>{
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/user`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    
    if(err){
      console.log(err)
      switch (err.response.status) {
        case 404:
          setErrorMessage(t('not_found_user'));
          break;        
        case 400:
          setErrorMessage(t('invalid_password'));
          break;
        case 500:
          setErrorMessage(t('can_not_connect_to_server'));
          break;
        default:
          setErrorMessage(t('can_not_connect_to_server'));
          break;
      }
      return
    }
    setUsers(response.data.results.map((r) => {return {...r, fullname: r.firstname + ' ' + r.lastname}}))
  }

  useEffect(() => {
    getUsers();
  }, [])

  const deactiveUserHandler = async(activeState, id)=>{
    const [err, response] = await to(
      axios.patch(
        `${process.env.REACT_APP_BASE_URL}/admin/user/${id}/${activeState}`,
        {id},
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: response.data.error,
        type: 'error', 
        seconds: 3
      })
      return
    }

    setUsers(users.map(u=>{if(u.id===id){return {...u, ActiveAt: activeState==='activate'?response.data.result:null}}else{return u}}))
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'عملیات موفق',
      description: `${aUser.name} با موفقیت ${aUser.active?'غیرفعال':'فعال'} شد.`,
      type: 'success', 
      seconds: 3
    })
  }

  const cancelHandler = () => {
    setNewUser({firstname: '', lastname: '', username: '', gender: '', roleId: 2})
  }

  const submitHandler = async() => {
    if(!newUser.username || !newUser.firstname || !newUser.lastname){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'پارامترها به درستی وارد نشده‌اند.',
        type: 'error', 
        seconds: 3
      })
    }
    const {firstname, lastname, username} = newUser
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/user/register`,
        {firstname, lastname, username, email: username + "@demo.com", password: '123456'},{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    console.log(err)
    // if(err){
    //   addToastHandler({
    //     id: Math.floor(Math.random()*3000),
    //     title: 'خطا',
    //     description: err,
    //     type: 'error', 
    //     seconds: 3
    //   })
    //   return
    // }

    setNewUser(null)
    getUsers()
  }

  const changeAvatarHandler = async(userId) => {
    if(!avatarFile){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'پارامترها به درستی وارد نشده‌اند.',
        type: 'error', 
        seconds: 3
      })
    }
    const formData = new FormData()
    formData.append('file', avatarFile)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/user/change-avatar/${userId}`,
        formData,{
          headers: { 'authorization': `Bearer ${accessToken}`, "Content-Type": "multipart/form-data" }
        }
      )
    )

    console.log(err)
    // if(err){
    //   addToastHandler({
    //     id: Math.floor(Math.random()*3000),
    //     title: 'خطا',
    //     description: err,
    //     type: 'error', 
    //     seconds: 3
    //   })
    //   return
    // }

    // setNewUser(null)
    // getUsers()
  }
  
  const updateUserHandler = async(id) => {
    if(!editingUser.username || !editingUser.firstname || !editingUser.lastname){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'پارامترها به درستی وارد نشده‌اند.',
        type: 'error', 
        seconds: 3
      })
    }

    const {firstname, lastname, username, roleId} = editingUser
    
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/admin/user/${id}`,
        {firstname, lastname, username, roleId, email: username},
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err,
        type: 'error', 
        seconds: 3
      })
      return
    }

    setEditingUser(null)
    setUsers(o=> o.map((u) => {if(u.id === editingUser.id){return editingUser}else{return u}}))
  }

  const resetUserPasswordHandler = async(id) => {
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/global/reset-password/${id}`,
        {},
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err,
        type: 'error', 
        seconds: 3
      })
      return
    }
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'عملیات موفق',
      description: 'رمز عبور با موفقیت بازنشانی شد.',
      type: 'success', 
      seconds: 3
    })
  }

  return (
    <div className='mt-20'>
      <div className="m-2 md:m-5 mt-20 p-2 md:p-10 bg-white dark:bg-[#42464D] rounded-3xl">
        <Header category="مدیریت کاربران"/>
        <div className={`sticky z-50 top-12 w-full mb-4 text-sm rounded border p-2 bg-gray-300 dark:bg-gray-500`}>
          <p className='text-sm my-2 font-bold'>اضافه کردن کاربر جدید</p>
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-1">
            <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="firstname" className='dark:text-white whitespace-pre'>نام:</label>
              <input id="firstname" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='نام' type="text" value={newUser?.firstname || ''} onChange={(e)=> setNewUser({...newUser, firstname: e.target.value})}/>
            </div>            
            <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="lastname" className='dark:text-white whitespace-pre'>نام خانوادگی:</label>
              <input id="lastname" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='نام خانوادگی' type="text" value={newUser?.lastname || ''} onChange={(e)=> setNewUser({...newUser, lastname: e.target.value})}/>
            </div>
            <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="username" className='dark:text-white whitespace-pre'>نام کاربری:</label>
              <input id="username" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='نام کاربری' type="email" value={newUser?.username || ''} onChange={(e)=> setNewUser({...newUser, username: e.target.value})}/>
            </div>
            <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="phone" className='dark:text-white whitespace-pre'>شماره تماس:</label>
              <input id="phone" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='شماره تماس' type="text" value={newUser?.phone || ''} onChange={(e)=> setNewUser({...newUser, phone: e.target.value})}/>
            </div>
            <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="phone" className='dark:text-white whitespace-pre'>تصویر:</label>
              <input id="phone" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='تصویر' type="file" onChange={(event)=> setAvatarFile(event.target.files[0])}/>
            </div>
            <div className="col-span-1 flex items-center justify-end gap-2 w-full">
              <button onClick={() => cancelHandler()} className='rounded p-1 w-1/2 hover:bg-gray-700 text-white bg-gray-600'>بازنشانی</button>
              <button onClick={() => submitHandler()} className='rounded p-1 w-1/2 bg-blue-400 hover:bg-blue-300 text-white'>ثبت</button>
              <button onClick={() => changeAvatarHandler(editingUser.id)} className='rounded p-1 w-1/2 bg-blue-400 hover:bg-blue-300 text-white'>ثبت</button>
            </div>
          </div>
        </div>
        <div className="sticky z-0 top-32 w-full grid grid-cols-2 md:grid-cols-5 text-xs dark:text-white mt-8 h-12 md:h-6 rounded bg-gray-300 dark:bg-gray-500">
            <div className="col-span-1 flex justify-center items-center gap-2">
              <p>نام</p>
            </div>
            <div className="col-span-1 flex justify-center items-center gap-2">
              <p>نام خانوادگی</p>
            </div>
            <div className="col-span-1 flex justify-center items-center gap-2">
              <p>نام کاربری</p>
            </div>
            <div className="col-span-1 flex justify-center items-center gap-2">
              <p>نقش</p>
            </div>
            <div className="flex justify-around items-center">
              <p>عملیات</p>
            </div>
        </div>
        {users.map((u, idx)=>(
          <div key={idx} className={`w-full grid grid-cols-2 md:grid-cols-5 text-sm dark:text-white border-1 rounded p-2 my-1 ${u.ActiveAt===null && 'bg-gray-200'}`}>
            {editingUser?.id !== u.id && <div className="col-span-1 flex justify-start items-center gap-2 no-underline">
              <p>{idx+1}- </p>
              <img src={u?.avatar || '/images/avatars/avatar.jpg'} className='rounded-full w-12 h-12' />
              <p className={`${u.activeAt===null && 'line-through'}`}>{u.firstname}</p>
            </div>}
            {editingUser?.id === u.id && <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="name" className='dark:text-white whitespace-pre'>نام:</label>
              <input id="name" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='نام' type="text" value={editingUser?.firstname || ''} onChange={(e)=> setEditingUser({...editingUser, firstname:e.target.value})}/>
            </div>}
            {editingUser?.id !== u.id && <div className="col-span-1 flex justify-start items-center gap-2 no-underline">
              <p className={`${u.activeAt===null && 'line-through'}`}>{u.lastname}</p>
            </div>}
            {editingUser?.id === u.id && <div className="col-span-1 flex justify-between items-center w-full">
              <label htmlFor="lastname" className='dark:text-white whitespace-pre'>نام خانوادگی:</label>
              <input id="lastname" className='p-1 px-2 rounded mx-1 bg-transparent border w-full' placeholder='نام خانوادگی' type="text" value={editingUser?.lastname || ''} onChange={(e)=> setEditingUser({...editingUser, lastname:e.target.value})}/>
            </div>}
            {<div className="col-span-1 flex justify-center items-center gap-2">
              <p className={`${!u.activeAt===null && 'line-through'}`}>{u.username}</p>
            </div>}
            {editingUser?.id !== u.id && <div className="col-span-1 flex justify-center items-center gap-2">
              <p className={`${u.activeAt===null && 'line-through'}`}>{u.role}</p>
            </div>}
            {editingUser?.id === u.id && <div className="col-span-1 flex items-center justify-start gap-2">
              <label htmlFor="role" className='dark:text-white'>نقش کاربر:</label>
              <RadioButton onChange={(s)=>{console.log(s);setEditingUser({...editingUser, roleId: s, role: roles.filter(r => r.id === s)[0].name})}} value={editingUser?.roleId || 0} data={roles} fields={{title: 'name', value: 'id'}} fill={theme.themeMode==="light"?'#000000':'#000000'}/>
            </div>}
            {editingUser?.id !== u.id && <div className="flex justify-around items-center no-underline gap-2">
              <button onClick={()=>setEditingUser(u)} className='rounded whitespace-pre bg-blue-400 p-1 w-24 hover:bg-gray-500 text-xs'>ویرایش</button>
              <button onClick={()=>resetUserPasswordHandler(u.id)} className='rounded whitespace-pre bg-cyan-400 p-1 w-24 hover:bg-gray-500 text-xs'>بازنشانی رمزعبور</button>
              <button onClick={()=>deactiveUserHandler(u.ActiveAt?'deActivate':'activate', u.id)} className={`rounded whitespace-pre ${u.ActiveAt?'bg-green-400':'bg-gray-500'} p-1 w-24 hover:bg-gray-500 text-xs`}>{u.ActiveAt===null?'فعال سازی':'غیرفعال سازی'}</button>
            </div>}
            {editingUser?.id === u.id && <div className="flex justify-around items-center no-underline gap-2">
              <button onClick={()=>setEditingUser(null)} className='rounded whitespace-pre bg-blue-400 p-1 w-24 hover:bg-gray-500 text-xs'>انصراف</button>
              <button onClick={()=>updateUserHandler(u.id)} className='rounded whitespace-pre bg-cyan-400 p-1 w-24 hover:bg-gray-500 text-xs'>تایید</button>
            </div>}
          </div>
        ))}
      </div>
    </div>
  );
};
export default UserManegement;
