import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollDirection } from 'react-use-scroll-direction'
import { MultiSelectComponent, DropDown } from '../../components';
import useToken from '../../hooks/useToken';
import { Line } from 'react-chartjs-2';
import { FaPlus, FaMinus, FaTags } from "react-icons/fa";
import to from 'await-to-js';
import {CheckBox} from '../../components';
import { HashLoader } from 'react-spinners';

const componentsContext = require.context('../../components/icons', true, /\icon.jsx?$/);
const iconNames = componentsContext.keys().map((key) => key.replace('./', '').replace(/\.[^/.]+$/, ''));


const RoutesBox = ({ addToastHandler}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [routes, setRoutes] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getRoutes()
  },[])

  const getRoutes = async () => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-routes`,{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setIsLoading(false)
      return
    }
    setRoutes(response.data.results)
    setIsLoading(false)
  }

  const updateOneRoute = async (route) => {
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/config/update-route/${route.id}`,{...route},{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      throw new HttpException('error in database.', HttpStatus.INTERNAL_SERVER_ERROR)
    }
    
    return true
  }

  const updateRoutes = async () => {
    setIsLoading(true)
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      const [err, _] = await to(updateOneRoute(route))

      if(err){
        addToastHandler({
          id: Math.floor(Math.random()*3000),
          title: 'خطا',
          description: err.message,
          type: 'error', 
          seconds: 3
        })
        setIsLoading(false)
        return
      }
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'تغییرات با موفقیت ذخیره شد.',
      type: 'success', 
      seconds: 3
    })
    setIsLoading(false)
    getRoutes()
  }

  return (
    <div className={`max-w-full box-border bg-white dark:bg-secondary-dark-bg md:transition-2 text-sm`}>
      <div className={`grid grid-cols-1 md:grid-cols-8 justify-between items-center gap-2 py-1 w-full bg-gray-900 rounded border border-gray-600 text-white`}>
        <p className="text-center md:text-start text-sm px-2">نام</p>
        <p onClick={() => {const id = Math.floor(Math.random()*3000); addToastHandler({id: id,
      title: 'موفق',
      description: `fffffff ` + id.toString(),
      type: 'success', seconds: 5})}} className="text-center text-sm px-2">عنوان</p>
        <p className="text-center text-sm">سطح دسترسی</p>
        <p className="text-center text-sm px-2">آیکون</p>
        <p className="text-center text-sm px-2">میسر دسترسی</p>
        <div className="flex justify-center items-center gap-4">
          <p className="text-center text-sm px-2">نمایش در پنل</p>
          <p className="text-center text-sm px-2">ترتیب</p>
        </div>
        <p className="text-center text-sm px-2"></p>
      </div>
      {(isLoading) && <div className="w-full flex justify-center items-center">
          <Loader size={60} />
      </div>}
      {!isLoading && <RouteElement routes={routes} mainRoutes={routes} onRemoveTag={()=>{}} setRoutes={setRoutes} onEditRoute={(tag)=>handleUpdateTag(tag)} setParentRouteId={(i)=>{}}/>}
      <div className="w-full flex justify-end items-center">
        <button onClick={() => updateRoutes()} className="w-full md:w-36 border bg-blue-500 text-white rounded py-1 hover:bg-blue-400 dark:border-none">ذخیره تغییرات</button>
      </div>
    </div>
  );
};

const RouteElement = ({routes, mainRoutes, onRemoveRoute, onEditRoute, setRoutes, className, parentId=null, setParentRouteId}) => {
  const [showChilds, setShowChilds] = useState(routes.filter(r => routes.filter(t => t.parentId === r.id).length > 0).map(t=>t.id))
  const [editingRoute, setEditingRoute] = useState(routes)

  const clickRouteHandler = (id) => {
    setShowChilds(o=>{if(!showChilds.includes(id)){return [...new Set([...o, id])]}else{return o.filter(t=>t!==id)}})
  }
  
  return <div className={`flex flex-col gap-1 ${className} rounded-lg py-1 text-sm w-full`}>
    {routes.filter(route => route.parentId === parentId).map((route, idx)=>
    <div key={idx} className={`grid grid-cols-1 md:grid-cols-8 justify-between items-center gap-1 px-[1px] w-full ${route.access === 'admin'?'bg-gray-300 dark:bg-secondary-dark-bg rounded border border-gray-500':'bg-gray-200 dark:bg-gray-800 rounded border border-gray-500'}`}>
      <div className="flex justify-between items-center gap-3 ps-2">
        {(routes.filter(r => r.parentId === route.id).length > 0) && (!showChilds.includes(route.id)) && <FaPlus onClick={() => clickRouteHandler(route.id)} />}
        {(showChilds.includes(route.id)) && <FaMinus onClick={() => clickRouteHandler(route.id)} />}
        <p className="w-full text-end text-sm px-2">{route.name}</p>
      </div>
      <input className="text-center text-sm px-2 dark:bg-gray-600" value={route.title} onChange={(e) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, title: e.target.value}}else{return r}}))}} />
      <p onClick={() => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, access: r.access === 'admin'?'costumer':'admin'}}else{return r}}))}} className="text-center text-sm cursor-pointer border border-teal-700 text-teal-700 dark:border-teal-400 dark:text-teal-400 dark:hover:bg-teal-500 px-2 rounded hover:bg-teal-700 hover:text-white dark:hover:text-white">{route.access}</p>
      {/* <input className="text-center text-sm px-2 dark:bg-gray-600" value={route.icon?route.icon:''} onChange={(e) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, icon: e.target.value}}else{return r}}))}} /> */}
        <DropDown hideFilter={true} beginFrom="end" className="w-full dark:bg-gray-600 dark:border-gray-500" onChange={(s) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, icon: s}}else{return r}}))}} value={route.icon} data={iconNames.map((i, index) => {return {value: i, title: 
          <p className="flex justify-start items-center gap-1">
            <div className="w-5"><DynamicIconLoader iconName={i} /></div>
            {i}
          </p>}})} />
      <p dir="ltr" className="text-end text-sm px-2">{route.path}</p>
      <div className="flex justify-around items-center">
        <CheckBox className="text-xs text-white" boxStyle='text-teal-300 dark:text-teal-500' value={route.showInPanel} onChange={(e) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, showInPanel: e}}else{return r}}))}} title="" />
        <input dir="ltr" type="number" className="w-10 rounded text-center text-sm px-2 dark:bg-gray-600" value={route.order} onChange={(e) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, order: e.target.value}}else{return r}}))}} />
      </div>
      <div className="col-span-1 py-1">
        <DropDown beginFrom="end" className="w-full dark:bg-gray-600 dark:border-gray-500" onChange={(s) => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, parentId: s}}else{return r}}))}} value={route.parentId} data={mainRoutes.filter((r) => r.id !== route.id).map((r, index) => {return {value: r.id, title: r.title}})}  onClear={() => {setRoutes(() => mainRoutes.map(r => {if(r.id === route.id){return {...r, parentId: null}}else{return r}}))}}/>
      </div>
      {routes.filter(r => r.parentId === route.id).length > 0 && showChilds.includes(route.id) && <div className="cols-span-1 md:col-span-8 flex justify-start items-center gap-1">
        <RouteElement routes={routes.filter(r => r.parentId === route.id)} parentId={route.id} mainRoutes={routes} onRemoveRoute={onRemoveRoute} setRoutes={setRoutes} onEditRoute={onEditRoute} setParentRouteId={setParentRouteId}/>
      </div>}
    </div>
    )}
  </div>
}

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}
const DynamicIconLoader = ({ iconName }) => {
  const [Icon, setIcon] = useState(null);
  useEffect(() => {
    import(`../../components/icons/${iconName}`)
      .then(module => setIcon(() => module.default))
      .catch(err => {
        // console.error(`Error loading icon ${iconName}:`, err);
        setIcon(null);
      });
  }, [iconName]);

  return Icon ? <Icon /> : <FaTags />;
};

export default RoutesBox
