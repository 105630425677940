import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment-jalaali'
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TiChevronRightOutline, TiChevronLeftOutline, TiTimes } from "react-icons/ti";
import DatePicker, { Calendar } from 'react-multi-date-picker';
import persian from "react-date-object/calendars/persian";
import persianFa from "react-date-object/locales/persian_fa";
import "react-multi-date-picker/styles/layouts/mobile.css"

const HDateTimePicker = ({fromDate, toDate, setFromDate, setToDate, className}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(moment(fromDate).format('YYYY-MM-DD HH:mm:ss'));
  const [dateTo, setDateTo] = useState(moment(toDate).format('YYYY-MM-DD HH:mm:ss'));
  const pickerRef = useRef(null);

  const addDay = (days) => {
    setFromDate(moment(fromDate).add(days, 'day').format('YYYY-MM-DD HH:mm:ss'));
    setDateFrom(moment(fromDate).add(days, 'day').format('YYYY-MM-DD HH:mm:ss'));
    setToDate(moment(toDate).add(days, 'day').format('YYYY-MM-DD HH:mm:ss'));
    setDateTo(moment(toDate).add(days, 'day').format('YYYY-MM-DD HH:mm:ss'));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      // document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={`relative flex ${className}`}>
      <div className="flex flex-wrap justify-between items-center gap-1 w-full">
        <button 
          onClick={() => setIsOpen(true)} 
          type="button" 
          className="flex-grow border bg-blue-600 hover:bg-blue-500 dark:bg-teal-600 dark:hover:bg-teal-500 dark:border-0 rounded px-2 py-1 text-xs text-white"
        >
          بازه زمانی: {`${moment(fromDate).format('HH:mm:ss jDD-jMM-jYYYY')} تا ${moment(toDate).format('HH:mm:ss jDD-jMM-jYYYY')}`}
        </button>
        <TiChevronRightOutline onClick={() => addDay(-1)} className="cursor-pointer text-gray-600 hover:text-gray-400" />
        <TiChevronLeftOutline onClick={() => addDay(1)} className="cursor-pointer text-gray-600 hover:text-gray-400" />
      </div>

      {isOpen && (
        <>
          {/* Desktop View */}
          <div ref={pickerRef} className="hidden md:block absolute top-8 start-0 bg-white dark:bg-secondary-dark-bg border rounded p-4 z-50">
            {/* <div ref={pickerRef}> */}
              <Calendar
                value={dateTo === null ? 
                  [moment(dateFrom).format('jYYYY-jMM-jDD HH:mm:ss')] : 
                  [moment(dateFrom).format('jYYYY-jMM-jDD 00:00:00'), moment(dateTo).format('jYYYY-jMM-jDD 23:59:59')]
                }
                calendar={persian}
                locale={persianFa}
                showOtherDays={true}
                range
                multiple
                format="YYYY-MM-DD HH:mm:ss"
                onChange={(d) => {
                  setDateFrom(moment([d[0].year, d[0].month, d[0].day].join('-'), 'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD 00:00:00'));
                  if (d.length === 2) {
                    setDateTo(moment([d[1].year, d[1].month, d[1].day].join('-'), 'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD 23:59:59'));
                  } else {
                    setDateTo(null);
                  }
                }}
                className="w-full dark:bg-gray-800 dark:border-gray-700"
                inputClass="w-full border dark:border-gray-500 rounded-md bg-transparent px-2 py-1 text-center text-sm font-bold dark:text-gray-300"
              />

              {/* <div className="grid grid-cols-3 gap-2 mt-4">
                <button 
                  onClick={() => {
                    setDateFrom(moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'));
                    setDateTo(moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-1 text-xs whitespace-pre hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  روز گذشته
                </button>
                <button 
                  onClick={() => {
                    setDateFrom(moment().add('3:30', 'h').jWeek(-1).startOf('jWeek').format('YYYY-MM-DD 00:00:00'));
                    setDateTo(moment().add('3:30', 'h').jWeek(-1).endOf('jWeek').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-1 text-xs whitespace-pre hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  هفته گذشته
                </button>
                <button 
                  onClick={() => {
                    setDateFrom(moment().add('3:30', 'h').startOf('jMonth').format('YYYY-MM-DD 00:00:00'));
                    setDateTo(moment().add('3:30', 'h').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-1 text-xs whitespace-pre hover:bg-gray-100 dark:hover:bg-gray-700"
                >
                  ماه جاری
                </button>
              </div> */}

              <div className="flex justify-between items-center mt-4">
                <button 
                  className="border rounded px-4 py-1 text-sm hover:bg-gray-100 dark:hover:bg-gray-700"
                  onClick={() => setIsOpen(false)}
                >
                  انصراف
                </button>
                <button 
                  className="bg-blue-500 text-white rounded px-4 py-1 text-sm hover:bg-blue-600"
                  onClick={() => {
                    setFromDate(dateFrom);
                    setToDate(dateTo);
                    setIsOpen(false);
                  }}
                >
                  تایید
                </button>
              </div>
            {/* </div> */}
          </div>

          {/* Mobile View */}
          <div className="md:hidden fixed inset-0 bg-white dark:bg-secondary-dark-bg z-50">
            <div className="h-full flex flex-col p-4">
              <div className="flex justify-between items-center mb-4 mt-8">
                <h3 className="text-lg font-medium dark:text-gray-200">انتخاب تاریخ</h3>
                <button 
                  onClick={() => setIsOpen(false)} 
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <TiTimes size={24} />
                </button>
              </div>
              <div className="flex justify-center items-center">
                <Calendar
                  value={dateTo === null ? 
                    [moment(dateFrom).format('jYYYY-jMM-jDD HH:mm:ss')] : 
                    [moment(dateFrom).format('jYYYY-jMM-jDD 00:00:00'), moment(dateTo).format('jYYYY-jMM-jDD 23:59:59')]
                  }

                  
                  calendar={persian}
                  locale={persianFa}
                  showOtherDays={true}
                  range
                  multiple
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(d) => {
                    setDateFrom(moment([d[0].year, d[0].month, d[0].day].join('-'), 'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD 00:00:00'));
                    if (d.length === 2) {
                      setDateTo(moment([d[1].year, d[1].month, d[1].day].join('-'), 'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD 23:59:59'));
                    } else {
                      setDateTo(null);
                    }
                  }}
                  className="w-full text-lg dark:bg-gray-800 dark:border-gray-700"
                  inputClass="w-full text-lg border dark:border-gray-500 rounded-md bg-transparent text-center font-bold dark:text-gray-300"
                  calendarClassName="text-lg"
                />
              </div>
              <div className="grid grid-cols-3 gap-2 mt-4">
                <button 
                  onClick={() => {
                    setDateFrom(moment().add(-1, 'day').format('YYYY-MM-DD 00:00:00'));
                    setDateTo(moment().add(-1, 'day').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-3 text-sm whitespace-pre bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600"
                >
                  روز گذشته
                </button>
                <button 
                  onClick={() => {
                    setDateFrom(moment().add('3:30', 'h').jWeek(-1).startOf('jWeek').format('YYYY-MM-DD 00:00:00'));
                    setDateTo(moment().add('3:30', 'h').jWeek(-1).endOf('jWeek').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-3 text-sm whitespace-pre bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600"
                >
                  هفته گذشته
                </button>
                <button 
                  onClick={() => {
                    setDateFrom(moment().add('3:30', 'h').startOf('jMonth').format('YYYY-MM-DD 00:00:00'));
                    setToDate(moment().add('3:30', 'h').format('YYYY-MM-DD 23:59:59'));
                  }}
                  className="rounded border py-3 text-sm whitespace-pre bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600"
                >
                  ماه جاری
                </button>
              </div>

              <div className="mt-4 space-y-2">
                <div className="flex justify-between items-center text-sm">
                  <span className="dark:text-gray-200">از تاریخ:</span>
                  <span className="text-gray-500 dark:text-gray-400">{moment(dateFrom).format('jDD jMMMM jYYYY HH:mm:ss')}</span>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <span className="dark:text-gray-200">تا تاریخ:</span>
                  <span className="text-gray-500 dark:text-gray-400">{moment(dateTo).format('jDD jMMMM jYYYY HH:mm:ss')}</span>
                </div>
              </div>

              <div className="mt-auto flex justify-between items-center gap-4 pt-4">
                <button 
                  className="flex-1 py-3 text-red-500 border border-red-500 rounded-lg"
                  onClick={() => setIsOpen(false)}
                >
                  انصراف
                </button>
                <button 
                  className="flex-1 py-3 bg-blue-500 text-white rounded-lg"
                  onClick={() => {
                    setFromDate(dateFrom);
                    setToDate(dateTo);
                    setIsOpen(false);
                  }}
                >
                  تایید
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HDateTimePicker;