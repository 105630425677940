import React from 'react';

const ConfigIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M 180.5,27.5 C 187.667,27.5275 194.334,29.3608 200.5,33C 206.167,38.6667 211.833,44.3333 217.5,50C 242.833,68 268.167,68 293.5,50C 299.167,44.3333 304.833,38.6667 310.5,33C 318.666,28.1942 327.332,26.8609 336.5,29C 362.469,38.4783 386.136,51.8117 407.5,69C 415.65,75.1325 419.65,83.2992 419.5,93.5C 418.194,102.591 416.361,111.591 414,120.5C 413.82,158.989 432.654,181.822 470.5,189C 479.34,191.823 485.84,197.323 490,205.5C 492.501,214.674 494.168,224.008 495,233.5C 496.297,254.29 495.297,274.956 492,295.5C 489.935,306.565 483.769,314.398 473.5,319C 430.083,325.714 409.916,350.881 413,394.5C 415.771,401.971 417.604,409.638 418.5,417.5C 418.555,428.222 414.222,436.722 405.5,443C 387.617,457.451 367.95,469.117 346.5,478C 328.406,484.525 314.239,479.691 304,463.5C 280.548,442.006 254.714,438.506 226.5,453C 217.167,459.667 209.167,467.667 202.5,477C 193.971,483.132 184.638,484.798 174.5,482C 146.251,472.135 121.084,457.302 99,437.5C 93.2266,429.738 91.2266,421.071 93,411.5C 107.548,378.193 100.714,350.36 72.5,328C 61.6722,321.709 50.0055,318.042 37.5,317C 29.3946,313.894 23.5613,308.394 20,300.5C 17.8314,292.989 16.4981,285.322 16,277.5C 14.4068,254.309 15.7401,231.309 20,208.5C 22.6426,198.522 28.8092,191.689 38.5,188C 77.0143,183.985 98.3476,162.819 102.5,124.5C 100.791,113.004 98.4579,101.671 95.5,90.5C 95.4812,83.9025 97.3145,77.9025 101,72.5C 119.418,55.697 140.251,42.5303 163.5,33C 169.127,30.6232 174.793,28.7899 180.5,27.5 Z M 255.5,175.5 C 292.225,178.1 317.391,196.434 331,230.5C 340.559,267.491 330.392,297.658 300.5,321C 271.92,338.232 242.92,338.899 213.5,323C 181.37,299.956 170.203,269.122 180,230.5C 193.711,196.398 218.878,178.065 255.5,175.5 Z"/></g>
  </svg>;
};


export default ConfigIcon;
