const ar = {
  username: 'نام کاربری',
  password: 'رمز عبور',
  confirmation_password: 'تکرار رمز عبور',
  signin: "ورود",
  did_you_forget_your_password: "رمز عبور خود را فراموش کرده‌اید؟",
  don_t_have_an_account: "حساب کاربری ندارید ",
  please_register: 'لطفاً ثبت نام کنید.',
  condition_and_terms: 'من با قوانین و مقرارت سایت موافق هستم.',
  register: 'ثبت نام',
  did_you_have_an_account: 'حساب کاربری دارید؟ ',
  signin_from_this_link: 'از اینجا وارد شوید.',
  settings: 'تنظیمات',
  theme_option: 'تنظیمات پوسته',
  light: 'قالب روشن',
  dark: 'قالب تیره',
  theme_colors: 'رنگ‌ پوسته',
  user_profile: 'منوی کاربری',
  usernam_or_password_is_incorrect: 'نام کاربری و رمز عبور اشتباه است!',
  can_not_connect_to_server: 'برقراری ارتباط با سرور امکان‌پذیر نیست.',
  usernam_duplacate: 'این نام کاربری قبلاً ثبت شده است.!',
  token_not_valid: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  can_not_register_user_now: 'در حال حاضر امکان ثبت کاربر فراهم نیست.',
  enterd_code_is_not_valid: 'کد وارد شده معتبر نیست.',
  not_allowed_to_update_that_user: 'شما اجازه ویرایش مشخصات کاربر را ندارید.',
  unable_to_verify_token: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  no_authorization_header_send: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  menu: 'منو',
  cart: 'سبد خرید',
  chat: 'تیکت',
  notification: 'اطلاع‌رسانی',
  hi: 'سلام، ',
  site_title: 'شاپی',
  dashboard: 'داشبورد',
  ecommerce: 'فروشگاه',
  pages: 'صفحات',
  orders: 'سفارشات',
  employees: 'پرسنل',
  users: 'کاربران',
  apps: 'ابزارها',
  calendar: 'تقویم',
  kanban: 'مدیریت پروژه',
  editor: 'ویرایشگر',
  color_picker: 'انتخاب رنگ',
  charts: 'نمودارها',
  line: 'نمودار خطی',
  area: 'نمودار سطحی',
  bar: 'نمودار میله‌ای',
  pie: 'نمودار دایراه‌ای',
  financial: 'نمودار تجاری',
  color_mapping: 'نمودار هیت مپ',
  pyramid: 'نمودار هرمی',
  stacked: 'نمودار درصدی',
  lang_option: 'زبان',
  ltr: 'چپ چین',
  rtl: 'راست چین',
  my_profile: 'پروفایل من',
  account_setting: 'تنظیمات حساب کاربری',
  my_inbox: 'صندوق پیام',
  messages_and_emails: 'پیام‌ها و ایمیل‌ها',
  my_tasks: 'تسک‌های من',
  todo_and_daily_tasks: 'تسک‌های روزانه',
  notifications: 'تیکت‌ها',
  see_all_notifications: 'مشاهده همه تیک‌ها',
  new: 'جدید',
  messages: 'پیام‌ها',
  see_all_messages: 'مشاهده همه پیام‌ها',
  shopping_cart: 'سبد خرید',
  count: 'تعداد',
  sub_total: 'مجموع این فاکتور',
  total: 'مجموع',
  place_order: 'ثبت خرید',
  currency_sign: 'تومان',
}

export default ar;