import React, { useRef, useState } from 'react';
import {useTranslation} from 'react-i18next'
import useOutsideClicked from '../hooks/useOutsideClick';
import { FaCheck } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';

const CheckBox = ({onChange=()=>{}, value=true, title='', className, boxStyle=""})=>{
  const [checked, setChecked] = useState(value)

  return (
    <button onClick={() => onChange(!value)} className={`flex justify-start items-center gap-1 ${className} `}>
      <p className="flx-grow">{title}</p>
      <p className={`flex justify-center items-center aspect-square min-w-[20px] min-h-[20px] border rounded dark:border-gray-500 text-blue-500 dark:text-teal-500 ${boxStyle}`}>{value?<FaCheck/>:<p/>}</p>
    </button>
  )
}

export default CheckBox;
