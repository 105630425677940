import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment-jalaali'
import { FaMinus, FaPlus } from 'react-icons/fa';
import { TiChevronRightOutline, TiChevronLeftOutline } from "react-icons/ti";
import parser from 'logic-query-parser';
import DatePicker, { Calendar } from 'react-multi-date-picker';
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import useOutsideClicked from '../../hooks/useOutsideClick';
import InputIcon from "react-multi-date-picker/components/input_icon"

const HDatePicker = ({date, setDate, className, showArrowButtons=true}) =>{
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [dateFrom, setDateFrom] = useState(moment(date[0]).format('YYYY-MM-DD'))
  const [dateTo, setDateTo] = useState(moment(date[1]).format('YYYY-MM-DD'))

  const pickerRef = useRef(null)

  // useEffect(() => {
  //   setFromDate(dateFrom + ' ' + timeFrom)
  // }, [dateFrom, timeFrom])
  
  // useEffect(() => {
  //   setToDate(dateTo + ' ' + timeTo)
  // }, [dateTo, timeTo])

  const addDay = (days) => {
    setDate([moment(date[0]).add(days, 'day').format('YYYY-MM-DD'), moment(date[1]).add(days, 'day').format('YYYY-MM-DD')])
    setDateFrom(moment(date[0]).add(days, 'day').format('YYYY-MM-DD'))
    setDateTo(moment(date[1]).add(days, 'day').format('YYYY-MM-DD'))
  }

  useOutsideClicked(pickerRef, ()=>setShowDatePicker(false))
  return(
    <div className={`relative flex ${className}`}>
      <div className="flex flex-wrap justify-between items-center gap-1">
        <button onClick={() => setShowDatePicker(true)} className="border bg-blue-600 hover:bg-blue-500 dark:bg-teal-600 dark:hover:bg-teal-500 dark:border-0 rounded px-2 py-1 text-sm">بازه زمانی: {`${moment(date[0]).format('jDD-jMM-jYYYY')} تا ${moment(date[1]).format('jDD-jMM-jYYYY')}`}</button>
        {/* <input onClick={()=>setShowDatePicker(true)} className='text-center py-1 rounded outline-none border dark:bg-secondary-dark-bg dark:border-gray-500' type="text" onChange={(e)=>{setFromDate(moment(e.target.value, 'HH:mm:ss jYYYY-jMM-jDD').format('YYYY-MM-DD HH:mm:ss'))}} value={moment(fromDate).format('jYYYY-jMM-jDD')}/> */}
        {/* <p className="text-xs">تا</p> */}
        {/* <input onClick={()=>setShowDatePicker(true)} className='text-center py-1 rounded outline-none border dark:bg-secondary-dark-bg dark:border-gray-500' type="text" onChange={(e)=>setToDate(moment(e.target.value, 'HH:mm:ss jYYYY-jMM-jDD').format('YYYY-MM-DD HH:mm:ss'))} value={moment(toDate).format('jYYYY-jMM-jDD')}/> */}
        {showArrowButtons && <TiChevronRightOutline onClick={() => addDay(-1)} className="cursor-pointer text-gray-600 hover:text-gray-400" />}
        {showArrowButtons && <TiChevronLeftOutline onClick={() => addDay(1)} className="cursor-pointer text-gray-600 hover:text-gray-400" />}
      </div>
      {showDatePicker && <div ref={pickerRef} className="absolute top-6 start-1/4 bg-white dark:bg-secondary-dark-bg dark:border-gray-500 flex flex-col gap-2 border rounded p-2 z-50">
        <Calendar
            value={dateTo===null?[moment(dateFrom).format('jYYYY-jMM-jDD')]:[moment(dateFrom).format('jYYYY-jMM-jDD'),moment(dateTo).format('jYYYY-jMM-jDD HH:mm:ss')]}
            calendar={persian}
            locale={persian_fa}
            showOtherDays={true}
            zIndex={500000}
            range
            multiple
            format="YYYY-MM-DD"
            onChange={(d)=>{
              setDateFrom(moment(d[0].year + '-' + d[0].month + '-' + d[0].day ,'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD'))
              if(d.length === 2){
                setDateTo(moment(d[1].year + '-' + d[1].month + '-' + d[1].day,'jYYYY-jMM-jDD HH:mm:ss').format('YYYY-MM-DD'))
              }
              else{
                setDateTo(null)
              }
            }}
            render={<InputIcon/>}
            className=" dark:bg-gray-800 dark:border-gray-700 dark:text-gray-300"
            inputClass='w-[calc(100%-0.5rem)] border dark:bg-secondary-dark-bg dark:border-gray-500 rounded-md z-50 mx-1 px-2 py-1 text-center text-sm font-bold dark:text-gray-300'
            containerClassName='dark:text-gray-300'
            arrowClassName=' dark:text-gray-300'
          />
          <div className="w-full flex justify-between items-center gap-1">
            <button onClick={()=>{setDateFrom(moment().days(-1).format('YYYY-MM-DD'));setDateTo(moment().days(-1).format('YYYY-MM-DD'));setTimeFrom('00:00:00');setTimeTo('23:59:59')}} className={`rounded border py-1 px-2 w-1/3 text-xs whitespace-pre`} type="button">روز گذشته</button>
            <button onClick={()=>{setDateFrom(moment().jWeek(-1).startOf('jWeek').format('YYYY-MM-DD'));setDateTo(moment().format('YYYY-MM-DD'));setTimeFrom('00:00:00');setTimeTo(moment().format('HH:mm:ss'))}} className={`rounded border py-1 px-2 w-1/3 text-xs whitespace-pre`} type="button">هفته گذشته</button>
            <button onClick={()=>{setDateFrom(moment().startOf('jMonth').format('YYYY-MM-DD'));setDateTo(moment().format('YYYY-MM-DD'));setTimeFrom('00:00:00');setTimeTo(moment().format('HH:mm:ss'))}} className={`rounded border py-1 px-2 w-1/3 text-xs whitespace-pre`} type="button">ماه جاری</button>
          </div>
          <div className="w-full flex justify-between items-center">
            {/* <Clock classname={''} time={timeFrom} onChange={(t)=>{setTimeFrom(t)}}/> */}
            {/* <Clock classname={''} time={timeTo} onChange={(t)=>{setTimeTo(t)}}/> */}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex flex-col justify-center items-center">
              <p className='text-gray-500 text-md'>{moment(dateFrom).format('jDD jMMMM jYYYY')}</p>
              {/* <p className='text-gray-500 text-xs'>{timeFrom}</p> */}
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className='text-gray-500 text-md'>{moment(dateTo).format('jDD jMMMM jYYYY')}</p>
              {/* <p className='text-gray-500 text-xs'>{timeTo}</p> */}
            </div>
          </div>
          <div className="flex justify-between items-center">
            <button className='border rounded w-20 text-sm bg-red-500 hover:bg-red-600 dark:border-gray-500' type="button" onClick={()=>{setShowDatePicker(false)}}>انصراف</button>
            <button className='border rounded w-20 text-sm bg-green-500 hover:bg-green-600 dark:border-gray-500' type="button" onClick={()=>{setDate([dateFrom, dateTo]);setShowDatePicker(false)}}>تایید</button>
          </div>
      </div>}
    </div>
  )
}

const Clock = ({time, classname, onChange}) =>{
  const [hour, setHour] = useState(0)
  const [minute, setMinute] = useState(0)
  useEffect(() => {
    setHour(+time.split(':')[0])
    setMinute(+time.split(':')[1])
  }, [])
  
  const clockHandleWithButtons = (direction='up', type='hour')=>{
    let newHour = hour
    let newMinute = minute
    if(direction==='up'){
      if(type==='hour'){
        newHour += 1
      }
      else{
        newMinute +=1
      }
    }
    else{
      if(type==='hour'){
        newHour -= 1
      }
      else{
        newMinute -=1
      }
    }

    switch (true) {
      case newMinute===-1:
        newMinute = 59
        newHour -= 1
        if(newHour === -1)
        newHour = 23
        break;
      case newMinute===60:
        newMinute = 0
        newHour += 1
        if(newHour === 24)
          newHour = 0
        break;
      case newHour===-1:
        newHour = 23
        break;
      case newHour===24:
        newHour = 0
        break;
      default:
        break;
    }

    setHour(newHour)
    setMinute(newMinute)

    onChange(newHour.toString().padStart(2,'0') + ':' + newMinute.toString().padStart(2,'0'))
  }

  const clockHandleWithInput = (e, type)=>{
    let newValue;
    try {
      newValue = parseInt(e.target.value)
    } catch (error) {
      return
    }

    if(type==='hour'){
      if(newValue >=0 && newValue <= 23){
        setHour(newValue)
        onChange(newValue.toString().padStart(2,'0') + ':' + minute.toString().padStart(2,'0'))
      }
    }
    else if(type==='minute'){
      if(newValue >=0 && newValue <= 59){
        setMinute(newValue)
        onChange(hour.toString().padStart(2,'0') + ':' + newValue.toString().padStart(2,'0'))
      }
    }
  }
  return(
    <div className={`grid grid-cols-2 gap-1 text-sm ${classname}`}>
      <div className="col-span-1 flex justify-between items-center border rounded">
        <button className='text-gray-400 hover:text-gray-600' onClick={()=>clockHandleWithButtons('up', 'minute')} type="button"><FaPlus/></button>
        <input className='focus:outline-none text-center w-6 bg-transparent dark:bg-gray-500' type="text" name="" id="" value={minute.toString()} onChange={(e)=>clockHandleWithInput(e, 'minute')}/>
        <button className='text-gray-400 hover:text-gray-600' onClick={()=>clockHandleWithButtons('down', 'minute')} type="button"><FaMinus/></button>
      </div>
      <div className="col-span-1 flex justify-between items-center border rounded">
        <button className='text-gray-400 hover:text-gray-600' onClick={()=>clockHandleWithButtons('up', 'hour')} type="button"><FaPlus/></button>
        <input className='focus:outline-none text-center w-6 bg-transparent dark:bg-gray-500' type="text" name="" id="" value={hour.toString()} onChange={(e)=>clockHandleWithInput(e, 'hour')}/>
        <button className='text-gray-400 hover:text-gray-600' onClick={()=>clockHandleWithButtons('down', 'hour')} type="button"><FaMinus/></button>
      </div>
    </div>
  )
}

export default HDatePicker