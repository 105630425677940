import React from 'react';
import { FaLink, FaRegCheckSquare, FaRegSquare, FaEye } from 'react-icons/fa';
import { FaRetweet } from "react-icons/fa";
import moment from 'moment-jalaali';
import { AiFillLike, AiFillDelete } from "react-icons/ai";
import { CgClose, CgDetailsMore } from "react-icons/cg";
import CheckBox from '../../components/checkbox';


function News({id, platform='news', taggedMessages=[], setSelectedText, counter, url, tags, title, description, deleteSubjectHandler, setShowPopUp, onDelete = ()=>{}, like_count=0, view_count=0, retweet_count=0, time, username, selectedRows, setSelectedRows=() => {}}) {

  const handleSelection = () => {
    const selection = window.getSelection();
    const text = selection.toString();
    setSelectedText(text);
  };

  return (
    <div key={id} className={`grid grid-cols-12 gap-y-4 md:gap-y-0 gap-1 items-center shadow-lg dark:shadow-gray-700 p-3 py-5 justify-start border-b-2 dark:border-gray-700 rounded text-xs ${selectedRows.includes(id) && 'border-2 border-teal-800 dark:border-amber-600'}`}>
    <div className="col-span-12 md:col-span-12 gap-2 md:gap-1 flex flex-col md:flex-row justify-start items-center rounded ">
      <CheckBox className="text-xs text-white" boxStyle='text-teal-700 border-2 border-teal-600 dark:text-teal-500' value={selectedRows.includes(id)} onChange={(e) => {setSelectedRows()}} title="" />
      {title !== '' && <div className="flex justify-start items-center rounded md:px-2 w-full gap-2 md:gap-1">
        <p onMouseUp={handleSelection} className="flex-grow px-2 py-1 font-bold text-base rounded">{title}</p>
      </div>}
        <p className="flex-grow px-2 py-1 font-bold text-base rounded">{counter}</p>
    </div>
    <div className="col-span-12 md:col-span-12 gap-2 md:gap-1 text-xs flex flex-col md:flex-row justify-start items-center rounded">
      <p onMouseUp={handleSelection} className="flex-grow px-2 py-1 text-sm rounded">{description}</p>
    </div>
    <hr className="col-span-12 md:col-span-12 border-1 gap-2 my-2 md:gap-1 dark:border-gray-500"/>
    <div className="col-span-12 w-full flex flex-wrap justify-start items-center gap-2">
      <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{username}</p>
      <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{`  ${moment(time).format('jYYYY-jMM-jDD HH:mm:ss')}  `}</p>
      <button onClick={()=>{window.open(url,'_blank', 'rel=noopener noreferrer')}} className={`rounded font-bold text-blue-800 hover:text-blue-500 dark:text-teal-500 dark:hover:text-teal-800`}><FaLink /></button>
      <button onClick={()=>setShowPopUp(id)} className={`rounded font-bold text-xl text-blue-800 hover:text-blue-500 dark:text-teal-500 dark:hover:text-teal-800`}><CgDetailsMore  /></button>
      <div className="flex justify-start items-center gap-1"></div>
      {tags.map((tag, idx)=>
        <button key={idx} className="flex justify-between items-center gap-2">
          <p className="text-sm text-start px-2 rounded border dark:text-gray-300 hover:border-blue-800 hover:bg-transparent hover:text-blue-800 bg-blue-800 text-white whitespace-pre font-bold">{`${tag.value}`}</p>
          <CgClose />
        </button>
      )}
      {taggedMessages.filter(tm => tm.originMessageId === id).map((tm, idx)=>
        <div key={idx} className="flex justify-between items-center gap-2 rounded border dark:text-gray-300 hover:border-blue-800 hover:bg-transparent hover:text-blue-800 dark:bg-green-700 dark:hover:border-green-700 dark:hover:bg-transparent dark:hover:text-green-600 bg-blue-800 text-white whitespace-pre font-bold">
          <p className="text-sm text-start px-2">{`${tm.subject}`}</p>
          <button onClick={() => deleteSubjectHandler(tm.id)}><CgClose className="hover:text-red-400" /></button>
        </div>
      )}
      <button onClick={()=>onDelete(id)} className={`rounded font-bold text-lg justify-self-end text-red-300 hover:text-red-500`}><AiFillDelete /></button>
      {platform !=='news' && <div className="flex justify-start items-center">
        <AiFillLike />
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{like_count}</p>
        </div>}
      {platform !=='news' && <div className="flex justify-start items-center">
        <FaEye />
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{view_count}</p>
      </div>}
      {platform ==='twitter' && <div className="flex justify-start items-center">
        <FaRetweet />
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{retweet_count}</p>
      </div>}
      {platform ==='eitaa_channel_post' && <div className="flex justify-start items-center">
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">ایتا</p>
      </div>}
      {platform ==='rubika_channel_post' && <div className="flex justify-start items-center">
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">روبیکا</p>
      </div>}
      {platform ==='bale_channel_post' && <div className="flex justify-start items-center">
        <p className="text-sm text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">بله</p>
      </div>}
    </div>
  </div>
  )
}

export default News