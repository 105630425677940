import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next'
import useKeyPress from '../../hooks/useKeyPress';
import useOutsideClicked from '../../hooks/useOutsideClick';
import ShowMoreText from "react-show-more-text";
import {GrView,  GrLike} from "react-icons/gr"
import {FaCommentAlt, FaEye, FaHeart, FaQuoteRight, FaReply, FaRetweet, FaTwitter, FaTelegramPlane} from "react-icons/fa"
import MultiSelectComponent from '../multi-select-component';

const PagesPanel = ({totalRecords, recordInPage, currentPage=0, onChanegPage=()=>{}, className}) => {
  const [pageCount, setPageCount] = useState(Math.ceil(totalRecords / recordInPage))
  useEffect(() => {
    setPageCount(Math.ceil(totalRecords / recordInPage))
  }, [])

  useEffect(() => {
    setPageCount(Math.ceil(totalRecords / recordInPage))
  }, [recordInPage, totalRecords])
  
  return (
    <div className={`flex justify-center items-center gap-9 ${pageCount === 0 && 'hidden'}`}>
      {pageCount > 0 && <div className="flex justify-center items-center gap-1">
        <PageBtn onClick={()=>{onChanegPage(0);}} select={false} title={'ابتدا'}/>
        <PageBtn onClick={()=>{onChanegPage(Math.max(currentPage-10, 0));}} select={false} title={'<'}/>
        <PageBtn onClick={()=>{onChanegPage(Math.max(currentPage-20, 0));}} select={false} title={'<<'}/>
      </div>}
      <div className="flex justify-center items-center gap-2">
        {[...Array(Math.min(10, pageCount))].map((_,idx)=>
          {
            if(pageCount < 10)
              return <PageBtn key={idx} onClick={()=>{onChanegPage(idx);}} select={currentPage===idx} title={idx+1}/>

            switch (true) {
            case (currentPage < 7):
              if(idx < 8 ){
                return <PageBtn key={idx} onClick={()=>{onChanegPage((idx===9?pageCount-1:idx));}} select={currentPage===(idx===9?pageCount - 1:idx)} title={idx===9?pageCount:idx+1}/>
              }
              else if(idx===8)
                return <p>...</p>
              else if(idx > 8){
                return <PageBtn key={idx} onClick={()=>{onChanegPage((pageCount+idx-10));}} select={currentPage===pageCount+idx-10} title={pageCount+idx-9}/>
              } 
              break;
            case (currentPage > (pageCount - 7)):
              if(idx !==1 ){
                return <PageBtn key={idx} onClick={()=>{onChanegPage((idx===0?0:pageCount-10+idx));}} select={currentPage===(idx===0?0:pageCount-10+idx)} title={idx===0?1:pageCount-9+idx}/>
              }
              else if(idx===1)
                return <p>...</p>            
              break;
            default:
              if(idx < 7){
                return <PageBtn key={idx} onClick={()=>{onChanegPage((currentPage+idx-3));}} select={currentPage===currentPage+idx-3} title={currentPage+idx-2}/>
              }
              else if(idx===7)
                return <p>...</p>
              else if(idx > 7){
                return <PageBtn key={idx} onClick={()=>{onChanegPage((pageCount+idx-10));}} select={currentPage===pageCount+idx-10} title={pageCount+idx-9}/>
              }          
              break;
            // default:
            //   return <button key={idx} onClick={()=>{onChanegPage(idx);}} className={`w-5 border rounded bg-gray-400 hover:bg-gray-500 dark:bg-gray-400 dark:hover:bg-gray-500 dark:border-gray-300 ${currentPage===idx && 'border-2 border-blue-500 dark:border-blue-600'}`}>{idx+1}</button>
            //   break;
          }}
        )}
      </div>
      {pageCount > 0 && <div className="flex justify-center items-center gap-1">
        <PageBtn onClick={()=>{onChanegPage(Math.min(currentPage+20, pageCount - 1));}} select={false} title={'>>'}/>
        <PageBtn onClick={()=>{onChanegPage(Math.min(currentPage+10, pageCount - 1));}} select={false} title={'>'}/>
        <PageBtn onClick={()=>{onChanegPage((pageCount - 1));}} select={false} title={'انتها'}/>
      </div>}
    </div>
  );
};

const PageBtn = ({onClick, title, select})=>{
  return <button onClick={onClick} className={`min-w-[1.5rem] px-1 border rounded bg-white dark:border-0 hover:bg-blue-300 dark:bg-gray-400 dark:hover:bg-gray-500 text-sm ${select && 'border-2 border-blue-500 bg-blue-300 dark:border dark:bg-blue-600'}`}>{title}</button>
}

export default PagesPanel;
