const en = {
  username: 'Username',
  password: 'Password',
  confirmation_password: 'Confirm password',
  signin: "Log in",
  sign_out: 'Log out',
  did_you_forget_your_password: "Did you forget your password?",
  don_t_have_an_account: "Don`t have an account ",
  please_register: 'Please Register',
  condition_and_terms: 'I agree to the Terms of Service and Privacy Policy',
  signup: 'Register',
  did_you_have_an_account: 'Did you have an account? ',
  signin_from_this_link: 'Login from this link',
  settings: 'Settings',
  theme_option: 'Theme Option',
  light: 'Light',
  dark: 'Dark',
  theme_colors: 'Theme Colors',
  user_profile: 'User Profile',
  usernam_or_password_is_incorrect: 'Usernam or Password is incorrect!',
  can_not_connect_to_server: 'Can`t connect to server',
  usernam_duplacate: 'Username Exist!',
  token_not_valid: 'Token not valid',
  can_not_register_user_now: 'User can not register now!',
  enterd_code_is_not_valid: 'Entered Code is not valid',
  not_allowed_to_update_that_user: 'Not allowed to update that user`s data',
  unable_to_verify_token: 'Unable to verify token',
  no_authorization_header_send: 'No authorization header send',
  menu: 'Menu',
  cart: 'Cart',
  chat: 'Chat',
  notification: 'Notification',
  hi: 'Hi, ',
  site_title: 'Shoppy',
  dashboard: 'Dashboard',
  ecommerce: 'ecommerce',
  pages: 'Pages',
  orders: 'orders',
  employees: 'employees',
  users: 'users',
  apps: 'Apps',
  calendar: 'calendar',
  kanban: 'kanban',
  editor: 'editor',
  color_picker: 'color-picker',
  charts: 'Charts',
  line: 'line',
  area: 'area',
  bar: 'bar',
  pie: 'pie',
  financial: 'financial',
  color_mapping: 'color-mapping',
  pyramid: 'pyramid',
  stacked: 'stacked',
  lang_option: 'Language',
  ltr: 'Left to right',
  rtl: 'Right to left',
  my_profile: 'My Profile',
  my_posts: 'My Posts',
  posts_created_by_you: 'Posts created by you',
  profile_of: 'Profile of',
  account_setting: 'Acount Setting',
  my_inbox: 'My Inbox',
  messages_and_emails: 'Messages & Emails',
  my_tasks: 'My Tasks',
  todo_and_daily_tasks: 'To-do and Daily Tasks',
  notifications: 'Notifications',
  see_all_notifications: 'See all notifications',
  new: 'New',
  messages: 'Messages',
  see_all_messages: 'See all messages',
  shopping_cart: 'Shopping Cart',
  count: 'Count',
  sub_total: 'Sub Total',
  total: 'Total',
  place_order: 'Place Order',
  currency_sign: '$',
  click_to_change_language: 'Click to change language',
  chat_main: 'Back to messages',
  write_message: 'Write message...',
  p_avatar: 'Avatar',
  p_name: 'Name',
  p_alias: 'Alias',
  p_mobile: 'Mobile',
  p_email: 'Email',
  p_country: 'Country',
  p_city: 'City',
  p_address: 'Address',
  p_bio: 'Bio',
  verified:'Verified',
  submit:'Submit',
  verification_code:'Verification code',
  verify_email: 'Verify email',
  verify_mobile: 'Verify mobile',
  save_changes: 'Save changes',
  reset: 'Reset values',
  verification_code_sent_to_your_mobile: 'Verification code sent to your mobile. Plz. enter the code.',
  verification_code_sent_to_your_email: 'Verification code sent to your email address. Plz. enter the code.',
  cancel: 'Cancel',
  changes_saved_successfully: 'Changes saved successfully!',
  rights_reserved: '© 2022 All rights reserved by Shoppy.com',
  usernam_or_password_is_incorrect: 'Username or Password is incorrect',
  is_loading: 'Loading ...',
  posts_per_page: 'Posts per Page:',
  edit: 'Edit',
  delete: 'Delete',
  all_categories: 'All posts',
  update: 'Update',
  category_title: 'Category`s title',
  add_category: 'Add Category',
  delete_item: 'Are you sure to remove item?',
  show_user_posts: 'Show user`s posts',
  title:'Title',
  description:'Description',
  tags:'Tags',
  permitted_users:'Permitted Users',
  explore:'Show in explore',
  supported_files:'Supported files',
  select_file: 'Select files',
  new_post: 'New Post',
  categories_management: 'Ctegories Management',
  posts_management: 'Posts Management',
  the_best: 'The Best',
  most_popular_posts: 'Most Popular Posts',
  most_comment_posts: 'Most Commented Posts',
  saved_posts: 'Saved Posts',
  name:'Name',
  alias:'Alias',
  email:'E-mail Address',
  mobile:'Mobile Number',
  bio:'Bio',
  comment:'Comment',
  comments_count:'Count of Comments',
  like: 'Like',
  likes_count: 'Count of Likes',
  date: 'Date',
  only_personal_page: 'Public',
  on_wall_page: 'Wall',
}

export default en;