import React from 'react';

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M -0.5,291.5 C -0.5,267.5 -0.5,243.5 -0.5,219.5C 1.31481,215.521 4.31481,212.688 8.5,211C 17.4938,210.5 26.4938,210.334 35.5,210.5C 38.7632,198.302 43.4299,186.635 49.5,175.5C 43.4826,169.317 37.6493,162.983 32,156.5C 29.8518,151.016 30.1852,145.683 33,140.5C 48.8619,124.304 65.0285,108.471 81.5,93C 86.9836,90.8518 92.3169,91.1852 97.5,94C 103.015,99.6816 108.681,105.182 114.5,110.5C 125.635,104.43 137.302,99.7632 149.5,96.5C 149.334,87.4938 149.5,78.4938 150,69.5C 151.36,66.2648 153.527,63.7648 156.5,62C 174.073,60.5274 191.739,60.0274 209.5,60.5C 209.667,91.1685 209.5,121.835 209,152.5C 157.643,148.016 120.643,169.016 98,215.5C 80.174,269.354 94.3407,312.52 140.5,345C 161.945,357.623 184.778,362.123 209,358.5C 209.5,389.165 209.667,419.832 209.5,450.5C 191.739,450.973 174.073,450.473 156.5,449C 153.527,447.235 151.36,444.735 150,441.5C 149.5,432.506 149.334,423.506 149.5,414.5C 137.302,411.237 125.635,406.57 114.5,400.5C 108.317,406.517 101.983,412.351 95.5,418C 90.0164,420.148 84.6831,419.815 79.5,417C 63.3043,401.138 47.4709,384.971 32,368.5C 29.8518,363.016 30.1852,357.683 33,352.5C 38.6816,346.985 44.1816,341.319 49.5,335.5C 43.4299,324.365 38.7632,312.698 35.5,300.5C 26.4938,300.666 17.4938,300.5 8.5,300C 4.31481,298.312 1.31481,295.479 -0.5,291.5 Z"/></g>
    <g><path d="M 511.5,180.5 C 511.5,267.5 511.5,354.5 511.5,441.5C 509.685,445.479 506.685,448.312 502.5,450C 414.834,450.5 327.167,450.667 239.5,450.5C 239.5,320.5 239.5,190.5 239.5,60.5C 290.167,60.5 340.833,60.5 391.5,60.5C 391.333,95.5016 391.5,130.502 392,165.5C 392.833,174.333 397.667,179.167 406.5,180C 441.498,180.5 476.498,180.667 511.5,180.5 Z M 284.5,150.5 C 306.878,150.054 329.211,150.554 351.5,152C 361.457,158.163 363.124,166.163 356.5,176C 353.624,178.437 350.29,179.77 346.5,180C 324.124,180.946 301.791,180.612 279.5,179C 269.543,172.837 267.876,164.837 274.5,155C 277.564,152.63 280.897,151.13 284.5,150.5 Z M 284.5,210.5 C 335.168,210.333 385.834,210.5 436.5,211C 447.29,213.067 451.79,219.567 450,230.5C 447.187,235.996 442.687,239.162 436.5,240C 385.833,240.667 335.167,240.667 284.5,240C 270.472,236.437 266.972,228.271 274,215.5C 277.147,212.841 280.647,211.174 284.5,210.5 Z M 284.5,270.5 C 335.168,270.333 385.834,270.5 436.5,271C 447.29,273.067 451.79,279.567 450,290.5C 447.187,295.996 442.687,299.162 436.5,300C 385.833,300.667 335.167,300.667 284.5,300C 270.472,296.437 266.972,288.271 274,275.5C 277.147,272.841 280.647,271.174 284.5,270.5 Z M 284.5,330.5 C 335.168,330.333 385.834,330.5 436.5,331C 447.29,333.067 451.79,339.567 450,350.5C 447.187,355.996 442.687,359.162 436.5,360C 385.833,360.667 335.167,360.667 284.5,360C 270.472,356.437 266.972,348.271 274,335.5C 277.147,332.841 280.647,331.174 284.5,330.5 Z"/></g>
    <g><path d="M 421.5,68.5 C 448.965,95.4651 476.299,122.632 503.5,150C 476.169,150.5 448.835,150.667 421.5,150.5C 421.5,123.167 421.5,95.8333 421.5,68.5 Z"/></g>
    <g><path d="M 182.5,180.5 C 191.54,180.252 200.54,180.585 209.5,181.5C 209.832,192.187 209.499,202.854 208.5,213.5C 181.516,207.408 162.682,217.075 152,242.5C 146.621,271.254 157.788,290.087 185.5,299C 193.293,299.975 200.959,299.475 208.5,297.5C 209.499,308.146 209.832,318.813 209.5,329.5C 170.813,334.742 142.646,320.075 125,285.5C 112.162,248.338 120.996,217.505 151.5,193C 161.19,186.944 171.524,182.777 182.5,180.5 Z"/></g>
    <g><path d="M 188.5,240.5 C 204.654,238.487 211.487,245.487 209,261.5C 204.652,269.915 197.819,272.748 188.5,270C 180.085,265.652 177.252,258.819 180,249.5C 181.688,245.315 184.521,242.315 188.5,240.5 Z"/></g>
  </svg>;
};

export default Icon;