const themeAction = {
  SHOW_ITEM: 'SHOW_ITEM',
  CLOSE_ITEM: 'CLOSE_ITEM',
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_COLOR: 'CHANGE_COLOR',
  SCREEN_SIZE: 'SCREEN_SIZE',
  CHAT_SIDE: 'CHAT_SIDE',
  LANG: 'LANG',
  DIR: 'CHANGE_DIRECTION',
};

export default themeAction;