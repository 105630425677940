import React from 'react';

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M 104.5,17.5 C 129.157,18.9927 144.657,31.6594 151,55.5C 153.258,88.3414 138.092,106.675 105.5,110.5C 76.9071,108.074 61.5738,92.574 59.5,64C 60.1376,42.0639 70.8043,27.3973 91.5,20C 95.9649,19.1259 100.298,18.2926 104.5,17.5 Z"/></g>
<g><path d="M 404.5,17.5 C 429.157,18.9927 444.657,31.6594 451,55.5C 453.258,88.3414 438.092,106.675 405.5,110.5C 376.907,108.074 361.574,92.574 359.5,64C 360.138,42.0639 370.804,27.3973 391.5,20C 395.965,19.1259 400.298,18.2926 404.5,17.5 Z"/></g>
<g><path d="M 250.5,20.5 C 289.408,22.2423 307.908,42.5756 306,81.5C 298.08,110.736 278.913,124.569 248.5,123C 216.617,115.436 202.117,95.2697 205,62.5C 210.782,38.2145 225.948,24.2145 250.5,20.5 Z"/></g>
<g><path d="M 95.5,119.5 C 126.254,117.139 152.087,127.139 173,149.5C 173.667,150.5 173.667,151.5 173,152.5C 158.874,166.078 149.374,182.411 144.5,201.5C 102.832,201.667 61.1653,201.5 19.5,201C 17.0786,200.002 15.7453,198.168 15.5,195.5C 22.6954,160.47 43.0287,136.637 76.5,124C 82.8902,122.16 89.2235,120.66 95.5,119.5 Z"/></g>
<g><path d="M 395.5,119.5 C 442.317,117.644 474.484,138.311 492,181.5C 493.91,186.716 494.91,192.049 495,197.5C 494.5,199.333 493.333,200.5 491.5,201C 449.835,201.5 408.168,201.667 366.5,201.5C 361.626,182.411 352.126,166.078 338,152.5C 337.333,151.5 337.333,150.5 338,149.5C 353.563,132.47 372.729,122.47 395.5,119.5 Z"/></g>
<g><path d="M 244.5,132.5 C 286.231,130.284 318.731,146.284 342,180.5C 348.378,191.802 352.878,203.802 355.5,216.5C 355.139,219.692 353.472,221.859 350.5,223C 286.418,223.993 222.418,223.659 158.5,222C 157.034,220.448 156.034,218.614 155.5,216.5C 166.473,168.523 196.139,140.523 244.5,132.5 Z"/></g>
<g><path d="M 15.5,252.5 C 57.8333,252.5 100.167,252.5 142.5,252.5C 145.025,300.417 167.691,334.917 210.5,356C 254.844,372.998 295.177,366.331 331.5,336C 355.382,313.393 367.715,285.56 368.5,252.5C 410.833,252.5 453.167,252.5 495.5,252.5C 495.667,263.505 495.5,274.505 495,285.5C 481.919,290.638 468.919,295.971 456,301.5C 451.043,322.548 442.876,342.215 431.5,360.5C 437.894,373.602 443.394,386.935 448,400.5C 433.167,415.333 418.333,430.167 403.5,445C 401.447,445.325 399.447,444.991 397.5,444C 386.125,438.93 374.791,433.764 363.5,428.5C 345.204,439.879 325.537,448.046 304.5,453C 298.878,466.03 293.544,479.196 288.5,492.5C 266.5,493.833 244.5,493.833 222.5,492.5C 217.456,479.196 212.122,466.03 206.5,453C 185.452,448.043 165.785,439.876 147.5,428.5C 136.209,433.764 124.875,438.93 113.5,444C 111.553,444.991 109.553,445.325 107.5,445C 92.6667,430.167 77.8333,415.333 63,400.5C 67.6055,386.935 73.1055,373.602 79.5,360.5C 68.1238,342.215 59.9572,322.548 55,301.5C 42.0805,295.971 29.0805,290.638 16,285.5C 15.5002,274.505 15.3335,263.505 15.5,252.5 Z"/></g>
<g><path d="M 181.5,252.5 C 230.833,252.5 280.167,252.5 329.5,252.5C 327.167,291.33 307.167,315.497 269.5,325C 238.845,329.674 214.012,319.841 195,295.5C 186.379,282.471 181.879,268.138 181.5,252.5 Z"/></g>
</svg>;
};

export default Icon;