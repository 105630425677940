import { useState, useEffect, useRef } from 'react';

interface ScrollInfo {
  scrollPercentage: number;
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}

export const useElementScroll = (): [React.RefObject<HTMLDivElement>, ScrollInfo] => {
  const elementRef = useRef(null);
  const [scrollInfo, setScrollInfo] = useState({
    scrollPercentage: 0,
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  });

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const calculateScrollInfo = () => {
      const { scrollTop, scrollHeight, clientHeight } = element;
      const scrollableHeight = scrollHeight - clientHeight;
      const scrollPercentage = scrollableHeight > 0 
        ? Math.round((scrollTop / scrollableHeight) * 100)
        : 0;

      setScrollInfo({
        scrollPercentage,
        scrollTop,
        scrollHeight,
        clientHeight
      });
    };

    element.addEventListener('scroll', calculateScrollInfo);
    // اولین محاسبه
    calculateScrollInfo();

    return () => {
      element.removeEventListener('scroll', calculateScrollInfo);
    };
  }, []);

  return [elementRef, scrollInfo];
};