import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollDirection } from 'react-use-scroll-direction'
import { MultiSelectComponent, DropDown } from '../../components';
import useToken from '../../hooks/useToken';
import { Line } from 'react-chartjs-2';
import { FaPlus, FaMinus } from "react-icons/fa";
import to from 'await-to-js';
import { HashLoader } from 'react-spinners';

const ConfigBox = ({ addToastHandler }) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [configs, setConfigs] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  useEffect(()=>{
    getConfigs()
  },[])

  const getConfigs = async () => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-config`,{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: err.message,
        type: 'error', 
        seconds: 3
      })
      setIsLoading(false)
      return
    }
    // addToastHandler({id: Math.floor(Math.random()*3000),
    //   title: 'موفق',
    //   description: 'fffffff',
    //   type: 'success'})
    setConfigs(response.data.results)
    setIsLoading(false)
  }

  const updateOneConfig = async (config) => {
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/config/update-config/${config.id}`,{...config},{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      throw new HttpException('error in database.', HttpStatus.INTERNAL_SERVER_ERROR)
    }
    
    return true
  }

  const updateConfigs = async () => {
    for (let i = 0; i < configs.length; i++) {
      const config = configs[i];
      const [err, _] = await to(updateOneConfig(config))

      if(err){
        addToastHandler({
          id: Math.floor(Math.random()*3000),
          title: 'خطا',
          description: err.message,
          type: 'error', 
          seconds: 3
        })
        setIsLoading(false)
        return
      }
    }

    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'تنظیمات با موفقیت ذخیره شد.',
      type: 'success', 
      seconds: 3
    })
    getConfigs()
  }

  return (
    <div className={`max-w-full box-border bg-white dark:bg-secondary-dark-bg md:transition-2 text-sm`}>
      <div className={`grid grid-cols-1 md:grid-cols-8 justify-between items-center gap-2 py-1 w-full bg-gray-900 rounded border border-gray-600 text-white`}>
        <p className="text-center md:text-start text-sm px-2">کانفیگ سامانه‌ها</p>
      </div>
      {(isLoading) && <div className="w-full flex justify-center items-center">
          <Loader size={60} />
      </div>}
      {!isLoading && configs.map((c, index) =>
        <div key={index} className="w-full flex justify-start items-center gap-2">
          <p>{c.title}</p>
          <div className="flex flex-grow justify-start items-center gap-2">
            {Object.keys(c.config).map((key, idx)=>
              <div className="flex-grow">
                <p>{key}</p>
                <input dir="ltr" className="w-full border rounded text-xs px-2 py-1 dark:bg-gray-600 dark:border-gray-500" value={c.config[key]} onChange={(e) => setConfigs(configs.map(co => {if(c.id === co.id){return {...co, config: {...co.config, [key]:e.target.value}}}else{return co}}))} />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="w-full flex justify-end items-center my-1">
        <button onClick={() => updateConfigs()} className="w-full md:w-36 border bg-blue-500 text-white rounded py-1 hover:bg-blue-400 dark:border-none">ذخیره تغییرات</button>
      </div>
    </div>
  );
};

const ConfigElement = ({}) => {

  const clickRouteHandler = (id) => {
    setShowChilds(o=>{if(!showChilds.includes(id)){return [...new Set([...o, id])]}else{return o.filter(t=>t!==id)}})
  }
  
  return <div className={`flex flex-col gap-1 ${className} rounded-lg py-1 text-sm w-full`}>
    
  </div>
}

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

export default ConfigBox
