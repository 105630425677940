import React from 'react';
import { IoMdSettings } from "react-icons/io";

const ConfigIcon = () => {
  return <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="150 150 650 550"
  fill="currentColor"
  style={{
    shapeRendering: "geometricPrecision",
    textRendering: "geometricPrecision",
    imageRendering: "optimizeQuality",
    fillRule: "evenodd",
    clipRule: "evenodd",
  }}
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <g>
    <path
      d="M 642.5,157.5 C 659.603,158.725 665.437,167.392 660,183.5C 654.567,189.866 647.734,192.032 639.5,190C 627.962,184.275 625.462,175.775 632,164.5C 634.998,161.262 638.498,158.929 642.5,157.5 Z"
    />
  </g>
  <g>
    <path
      d="M 591.5,159.5 C 600.731,158.365 607.231,162.032 611,170.5C 610.858,185.632 603.358,191.465 588.5,188C 577.758,180.812 576.758,172.478 585.5,163C 587.727,162.059 589.727,160.892 591.5,159.5 Z"
    />
  </g>
  <g>
    <path
      d="M 543.5,162.5 C 558.773,162.918 563.939,170.251 559,184.5C 550.038,192.739 541.372,192.405 533,183.5C 528.733,172.604 532.233,165.604 543.5,162.5 Z"
    />
  </g>
  <g>
    <path
      d="M 441.5,163.5 C 450.409,163.118 455.742,167.285 457.5,176C 452.848,187.156 445.515,189.323 435.5,182.5C 431.766,178.374 431.6,174.04 435,169.5C 436.914,167.144 439.081,165.144 441.5,163.5 Z"
    />
  </g>
  <g>
    <path
      d="M 490.5,164.5 C 503.375,162.55 509.208,167.883 508,180.5C 504.994,188.064 499.494,190.897 491.5,189C 483.788,186.078 480.955,180.578 483,172.5C 484.69,168.979 487.19,166.312 490.5,164.5 Z"
    />
  </g>
  <g>
    <path
      d="M 340.5,168.5 C 351.411,168.161 354.744,172.995 350.5,183C 342.925,186.92 337.591,184.92 334.5,177C 335.187,173.16 337.187,170.327 340.5,168.5 Z"
    />
  </g>
  <g>
    <path
      d="M 392.5,167.5 C 402.312,167.811 406.479,172.811 405,182.5C 402.669,186.837 399.003,189.171 394,189.5C 385.181,186.189 382.681,180.189 386.5,171.5C 388.565,170.143 390.565,168.81 392.5,167.5 Z"
    />
  </g>
  <g>
    <path
      d="M 292.5,168.5 C 300.27,168.425 303.437,172.092 302,179.5C 297.423,186.034 292.756,186.034 288,179.5C 287.319,174.871 288.819,171.205 292.5,168.5 Z"
    />
  </g>
  <g>
    <path
      d="M 241.5,172.5 C 249.385,173.115 251.718,176.948 248.5,184C 240.775,187.066 237.608,184.566 239,176.5C 239.662,175.016 240.496,173.683 241.5,172.5 Z"
    />
  </g>
  <g>
    <path
      d="M 143.5,173.5 C 146.779,173.305 148.779,174.805 149.5,178C 146.012,184.102 143.012,183.935 140.5,177.5C 140.787,175.557 141.787,174.223 143.5,173.5 Z"
    />
  </g>
  <g>
    <path
      d="M 191.5,173.5 C 195.497,172.668 198.164,174.168 199.5,178C 197.058,182.154 193.891,182.988 190,180.5C 189.167,177.829 189.667,175.496 191.5,173.5 Z"
    />
  </g>
  <g>
    <path
      d="M 594.5,208.5 C 607.896,209.041 613.063,215.708 610,228.5C 602.646,238.468 594.146,239.635 584.5,232C 579.736,220.696 583.069,212.863 594.5,208.5 Z"
    />
  </g>
  <g>
    <path
      d="M 640.5,208.5 C 657.217,207.705 664.051,215.372 661,231.5C 654.955,240.182 647.122,242.349 637.5,238C 629.632,230.788 628.466,222.621 634,213.5C 635.965,211.45 638.132,209.783 640.5,208.5 Z"
    />
  </g>
  <g>
    <path
      d="M 693.5,208.5 C 710.627,210.761 715.794,219.761 709,235.5C 698.98,243.795 689.313,243.462 680,234.5C 676.519,227.667 676.853,221 681,214.5C 684.556,210.97 688.722,208.97 693.5,208.5 Z"
    />
  </g>
  <g>
    <path
      d="M 443.5,213.5 C 454.633,215.766 457.8,222.099 453,232.5C 447.336,237.643 441.503,237.81 435.5,233C 432.255,223.998 434.922,217.498 443.5,213.5 Z"
    />
  </g>
  <g>
    <path
      d="M 492.5,213.5 C 507.575,214.166 511.575,221 504.5,234C 496.172,239.335 489.338,237.835 484,229.5C 482.907,222.016 485.741,216.683 492.5,213.5 Z"
    />
  </g>
  <g>
    <path
      d="M 544.5,213.5 C 558.329,214.525 563.163,221.525 559,234.5C 552.803,240.104 545.969,240.937 538.5,237C 531.991,230.55 531.325,223.717 536.5,216.5C 539.194,215.246 541.861,214.246 544.5,213.5 Z"
    />
  </g>
  <g>
    <path
      d="M 391.5,217.5 C 401.654,215.496 406.154,219.496 405,229.5C 399.401,237.105 393.234,237.439 386.5,230.5C 383.628,224.451 385.295,220.118 391.5,217.5 Z"
    />
  </g>
  <g>
    <path
      d="M 341.5,218.5 C 352.117,218.616 355.117,223.449 350.5,233C 345.354,235.504 340.854,234.671 337,230.5C 334.828,225.17 336.328,221.17 341.5,218.5 Z"
    />
  </g>
  <g>
    <path
      d="M 291.5,219.5 C 297.059,218.608 300.726,220.775 302.5,226C 299.811,233.442 295.311,234.942 289,230.5C 286.6,225.99 287.433,222.323 291.5,219.5 Z"
    />
  </g>
  <g>
    <path
      d="M 241.5,222.5 C 246.894,221.398 249.894,223.565 250.5,229C 248.401,235.233 244.901,236.066 240,231.5C 237.636,227.835 238.136,224.835 241.5,222.5 Z"
    />
  </g>
  <g>
    <path
      d="M 191.5,223.5 C 196.952,222.132 199.452,224.132 199,229.5C 196.847,231.444 194.347,231.944 191.5,231C 188.88,228.441 188.88,225.941 191.5,223.5 Z"
    />
  </g>
  <g>
    <path
      d="M 142.5,223.5 C 151.062,224.683 151.562,227.349 144,231.5C 140.211,229.331 139.711,226.664 142.5,223.5 Z"
    />
  </g>
  <g>
    <path
      d="M 740.5,257.5 C 757.701,256.192 764.867,263.859 762,280.5C 757.133,289.849 749.633,293.016 739.5,290C 728.812,284.123 725.978,275.623 731,264.5C 733.79,261.535 736.957,259.202 740.5,257.5 Z"
    />
  </g>
  <g>
    <path
      d="M 293.5,257.5 C 308.038,257.869 315.205,265.202 315,279.5C 313.305,287.53 308.472,292.697 300.5,295C 279.558,297.57 271.725,288.737 277,268.5C 280.597,261.953 286.097,258.287 293.5,257.5 Z"
    />
  </g>
  <g>
    <path
      d="M 491.5,258.5 C 506.275,257.28 514.942,263.946 517.5,278.5C 514.523,292.642 505.856,298.809 491.5,297C 483.611,294.742 478.778,289.575 477,281.5C 474.34,268.777 479.173,261.111 491.5,258.5 Z"
    />
  </g>
  <g>
    <path
      d="M 341.5,258.5 C 358.512,258.531 366.345,266.864 365,283.5C 360.776,294.867 352.609,299.367 340.5,297C 326.403,291.065 322.237,280.899 328,266.5C 331.266,261.622 335.766,258.955 341.5,258.5 Z"
    />
  </g>
  <g>
    <path
      d="M 542.5,258.5 C 559.419,257.925 567.919,265.925 568,282.5C 563.098,294.206 554.265,299.039 541.5,297C 533.592,294.758 528.759,289.591 527,281.5C 523.755,267.959 528.922,260.292 542.5,258.5 Z"
    />
  </g>
  <g>
    <path
      d="M 590.5,258.5 C 601.86,257.424 609.694,262.091 614,272.5C 615.464,284.407 610.63,292.574 599.5,297C 586.573,298.798 578.073,293.632 574,281.5C 573.651,269.385 579.151,261.718 590.5,258.5 Z"
    />
  </g>
  <g>
    <path
      d="M 691.5,259.5 C 701.772,258.624 708.105,263.124 710.5,273C 707.574,285.953 699.907,290.287 687.5,286C 679.932,279.939 678.599,272.773 683.5,264.5C 685.928,262.295 688.595,260.628 691.5,259.5 Z"
    />
  </g>
  <g>
    <path
      d="M 242.5,260.5 C 252.254,260.856 258.254,265.689 260.5,275C 257.288,288.272 249.288,292.605 236.5,288C 230.514,280.585 230.014,272.751 235,264.5C 237.255,262.501 239.755,261.168 242.5,260.5 Z"
    />
  </g>
  <g>
    <path
      d="M 440.5,261.5 C 457.24,261.059 463.074,268.726 458,284.5C 451.709,290.904 444.542,292.07 436.5,288C 429.016,281.094 428.016,273.427 433.5,265C 435.925,263.787 438.259,262.621 440.5,261.5 Z"
    />
  </g>
  <g>
    <path
      d="M 642.5,260.5 C 656.991,261.148 661.491,268.148 656,281.5C 649.126,288.1 642.293,288.1 635.5,281.5C 631.559,276.105 631.726,270.771 636,265.5C 637.965,263.45 640.132,261.783 642.5,260.5 Z"
    />
  </g>
  <g>
    <path
      d="M 193.5,266.5 C 204.254,269.447 206.254,275.28 199.5,284C 191.303,287.23 186.97,284.396 186.5,275.5C 187.027,270.985 189.36,267.985 193.5,266.5 Z"
    />
  </g>
  <g>
    <path
      d="M 392.5,266.5 C 399.405,266.275 403.738,269.442 405.5,276C 404.313,281.353 400.979,284.519 395.5,285.5C 387.68,283.206 384.847,278.206 387,270.5C 388.812,269.023 390.645,267.69 392.5,266.5 Z"
    />
  </g>
  <g>
    <path
      d="M 143.5,271.5 C 150.513,272.542 151.18,275.209 145.5,279.5C 143.5,278.167 141.833,276.5 140.5,274.5C 141.34,273.265 142.34,272.265 143.5,271.5 Z"
    />
  </g>
  <g>
    <path
      d="M 289.5,303.5 C 309.361,302.851 317.528,312.184 314,331.5C 304.634,343.819 293.467,345.652 280.5,337C 273.665,329.821 272.832,321.988 278,313.5C 281.435,309.555 285.268,306.222 289.5,303.5 Z"
    />
  </g>
  <g>
    <path
      d="M 337.5,305.5 C 355.98,304.479 364.48,313.146 363,331.5C 359.62,340.452 353.12,345.452 343.5,346.5C 323.195,341.225 318.362,329.559 329,311.5C 331.736,309.248 334.569,307.248 337.5,305.5 Z"
    />
  </g>
  <g>
    <path
      d="M 543.5,304.5 C 561.172,305.985 568.338,315.318 565,332.5C 560.866,340.576 554.199,344.91 545,345.5C 533.131,343.465 526.631,336.465 525.5,324.5C 526.465,313.203 532.465,306.537 543.5,304.5 Z"
    />
  </g>
  <g>
    <path
      d="M 440.5,305.5 C 458.67,304.169 466.837,312.502 465,330.5C 460.057,343.057 450.891,347.891 437.5,345C 423.461,338.216 420.294,328.05 428,314.5C 431.261,310.131 435.427,307.131 440.5,305.5 Z"
    />
  </g>
  <g>
    <path
      d="M 492.5,306.5 C 510.531,306.695 518.365,315.695 516,333.5C 508.947,345.962 498.78,349.462 485.5,344C 473.991,333.73 472.991,322.73 482.5,311C 485.815,309.17 489.149,307.67 492.5,306.5 Z"
    />
  </g>
  <g>
    <path
      d="M 742.5,306.5 C 760.262,307.042 766.429,315.709 761,332.5C 750.664,342.066 740.664,341.733 731,331.5C 727.667,325.833 727.667,320.167 731,314.5C 734.464,311.195 738.297,308.528 742.5,306.5 Z"
    />
  </g>
  <g>
    <path
      d="M 691.5,307.5 C 706.352,307.265 712.186,314.265 709,328.5C 702.241,337.135 694.074,338.635 684.5,333C 679.839,326.866 679.339,320.366 683,313.5C 685.322,310.605 688.155,308.605 691.5,307.5 Z"
    />
  </g>
  <g>
    <path
      d="M 592.5,311.5 C 602.337,310.511 607.17,314.844 607,324.5C 604.887,328.558 601.554,330.891 597,331.5C 593.677,330.59 590.51,329.257 587.5,327.5C 585.738,321.039 587.405,315.706 592.5,311.5 Z"
    />
  </g>
  <g>
    <path
      d="M 241.5,311.5 C 258.323,311.13 264.156,318.797 259,334.5C 251.005,340.947 242.838,341.114 234.5,335C 227.694,324.261 230.027,316.427 241.5,311.5 Z"
    />
  </g>
  <g>
    <path
      d="M 392.5,311.5 C 403.886,311.048 408.72,316.381 407,327.5C 401.973,334.866 395.473,336.366 387.5,332C 383.359,327.375 382.526,322.209 385,316.5C 387.17,314.177 389.67,312.51 392.5,311.5 Z"
    />
  </g>
  <g>
    <path
      d="M 640.5,311.5 C 652.807,311.627 657.974,317.627 656,329.5C 650.569,336.035 644.069,337.201 636.5,333C 631.603,326.924 631.27,320.59 635.5,314C 637.315,313.243 638.981,312.41 640.5,311.5 Z"
    />
  </g>
  <g>
    <path
      d="M 190.5,315.5 C 200.204,315.025 203.704,319.358 201,328.5C 194.511,334.306 189.177,333.306 185,325.5C 184.477,320.88 186.31,317.547 190.5,315.5 Z"
    />
  </g>
  <g>
    <path
      d="M 143.5,320.5 C 144.822,320.33 145.989,320.663 147,321.5C 147.497,323.81 147.663,326.143 147.5,328.5C 144.537,328.047 142.204,326.547 140.5,324C 141.365,322.641 142.365,321.474 143.5,320.5 Z"
    />
  </g>
  <g>
    <path
      d="M 337.5,353.5 C 355.829,352.043 364.662,360.377 364,378.5C 360.145,388.028 352.978,393.028 342.5,393.5C 330.8,392.299 324.8,385.799 324.5,374C 324.732,364.331 329.065,357.497 337.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 388.5,353.5 C 406.256,351.926 414.756,359.926 414,377.5C 409.551,389.275 401.051,394.442 388.5,393C 376.639,389.125 372.139,380.958 375,368.5C 377.263,361.407 381.763,356.407 388.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 491.5,353.5 C 504.667,351.655 513.167,356.989 517,369.5C 517.036,385.796 508.87,393.629 492.5,393C 476.45,386.734 472.283,375.9 480,360.5C 483.514,357.5 487.347,355.167 491.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 292.5,353.5 C 305.931,353.359 314.264,359.693 317.5,372.5C 314.712,388.081 305.379,394.581 289.5,392C 275.841,385.358 272.341,375.191 279,361.5C 282.601,357.123 287.101,354.457 292.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 440.5,353.5 C 461.281,353.762 468.781,363.762 463,383.5C 454.306,393.274 444.14,395.108 432.5,389C 422.597,379.66 421.93,369.66 430.5,359C 433.767,356.87 437.1,355.037 440.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 741.5,353.5 C 756.151,354.314 762.651,361.981 761,376.5C 755.235,387.979 746.402,391.145 734.5,386C 727.488,377.871 726.654,369.038 732,359.5C 734.843,356.837 738.009,354.837 741.5,353.5 Z"
    />
  </g>
  <g>
    <path
      d="M 690.5,355.5 C 703.298,354.801 709.798,360.801 710,373.5C 705.983,382.759 699.149,385.925 689.5,383C 680.519,377.241 678.685,369.741 684,360.5C 685.965,358.45 688.132,356.783 690.5,355.5 Z"
    />
  </g>
  <g>
    <path
      d="M 242.5,357.5 C 256.983,359.141 261.816,366.808 257,380.5C 248.889,389.312 240.556,389.646 232,381.5C 227.628,369.92 231.128,361.92 242.5,357.5 Z"
    />
  </g>
  <g>
    <path
      d="M 639.5,359.5 C 654.349,359.572 658.849,366.239 653,379.5C 644.79,385.685 637.79,384.685 632,376.5C 631.256,369.327 633.756,363.66 639.5,359.5 Z"
    />
  </g>
  <g>
    <path
      d="M 591.5,362.5 C 602.042,361.539 606.542,366.206 605,376.5C 599.557,383.412 593.557,384.079 587,378.5C 584.491,371.849 585.991,366.516 591.5,362.5 Z"
    />
  </g>
  <g>
    <path
      d="M 193.5,363.5 C 204.039,362.915 207.539,367.581 204,377.5C 199.397,381.542 194.564,382.042 189.5,379C 185.552,372.628 186.885,367.461 193.5,363.5 Z"
    />
  </g>
  <g>
    <path
      d="M 542.5,364.5 C 551.013,363.626 554.847,367.293 554,375.5C 551.282,381.111 547.116,382.611 541.5,380C 535.891,374.277 536.225,369.11 542.5,364.5 Z"
    />
  </g>
  <g>
    <path
      d="M 142.5,369.5 C 144.144,369.286 145.644,369.62 147,370.5C 147.495,372.473 147.662,374.473 147.5,376.5C 144.01,376.849 141.677,375.349 140.5,372C 141.381,371.292 142.047,370.458 142.5,369.5 Z"
    />
  </g>
  <g>
    <path
      d="M 391.5,401.5 C 410.541,403.35 417.375,413.35 412,431.5C 401.205,443.717 389.871,444.383 378,433.5C 371.527,418.066 376.027,407.399 391.5,401.5 Z"
    />
  </g>
  <g>
    <path
      d="M 442.5,401.5 C 456.045,401.884 463.712,408.717 465.5,422C 464.266,434.227 457.433,440.727 445,441.5C 433.295,440.129 426.462,433.629 424.5,422C 425.801,410.875 431.801,404.041 442.5,401.5 Z"
    />
  </g>
  <g>
    <path
      d="M 290.5,403.5 C 305.549,402.177 314.549,408.843 317.5,423.5C 315.71,435.623 308.71,442.29 296.5,443.5C 277.978,439.121 272.145,428.121 279,410.5C 282.514,407.5 286.347,405.167 290.5,403.5 Z"
    />
  </g>
  <g>
    <path
      d="M 340.5,403.5 C 357.361,402.865 365.861,410.865 366,427.5C 361.377,438.901 352.877,443.734 340.5,442C 325.169,436.332 321.003,426.165 328,411.5C 331.367,407.41 335.533,404.744 340.5,403.5 Z"
    />
  </g>
  <g>
    <path
      d="M 742.5,403.5 C 759.153,403.315 765.653,411.315 762,427.5C 753.716,438.821 744.049,440.154 733,431.5C 726.64,422.855 727.14,414.688 734.5,407C 737.158,405.522 739.825,404.355 742.5,403.5 Z"
    />
  </g>
  <g>
    <path
      d="M 692.5,404.5 C 707.584,405.721 712.751,413.388 708,427.5C 700.355,435.97 692.022,436.637 683,429.5C 676.801,417.597 679.967,409.264 692.5,404.5 Z"
    />
  </g>
  <g>
    <path
      d="M 639.5,407.5 C 652.992,406.484 658.492,412.484 656,425.5C 648.923,432.944 641.59,433.277 634,426.5C 631.201,418.779 633.035,412.445 639.5,407.5 Z"
    />
  </g>
  <g>
    <path
      d="M 243.5,407.5 C 258.221,409.953 262.721,417.953 257,431.5C 248.078,438.184 240.078,437.184 233,428.5C 230.922,422.545 231.588,416.879 235,411.5C 237.564,409.376 240.397,408.042 243.5,407.5 Z"
    />
  </g>
  <g>
    <path
      d="M 597.5,408.5 C 607.388,410.221 610.888,415.888 608,425.5C 603.265,431.115 597.931,431.781 592,427.5C 584.728,418.316 586.561,411.983 597.5,408.5 Z"
    />
  </g>
  <g>
    <path
      d="M 544.5,411.5 C 555.469,410.987 558.469,415.487 553.5,425C 547.12,427.927 542.12,426.427 538.5,420.5C 539.286,416.567 541.286,413.567 544.5,411.5 Z"
    />
  </g>
  <g>
    <path
      d="M 191.5,412.5 C 199.091,411.29 203.424,414.456 204.5,422C 202.847,428.994 198.514,431.661 191.5,430C 184.859,424.039 184.859,418.206 191.5,412.5 Z"
    />
  </g>
  <g>
    <path
      d="M 496.5,414.5 C 502.978,415.141 505.145,418.474 503,424.5C 497.552,427.363 494.052,425.696 492.5,419.5C 493.127,417.213 494.46,415.546 496.5,414.5 Z"
    />
  </g>
  <g>
    <path
      d="M 144.5,418.5 C 150.258,422.572 149.592,425.239 142.5,426.5C 141.015,423.248 141.682,420.581 144.5,418.5 Z"
    />
  </g>
  <g>
    <path
      d="M 289.5,452.5 C 304.727,450.724 313.727,457.391 316.5,472.5C 313.71,487.571 304.71,493.738 289.5,491C 273.552,483.456 270.552,472.456 280.5,458C 283.263,455.626 286.263,453.792 289.5,452.5 Z"
    />
  </g>
  <g>
    <path
      d="M 343.5,451.5 C 361.373,454.266 367.873,464.266 363,481.5C 354.082,492.66 343.582,494.327 331.5,486.5C 323.204,477.875 322.371,468.541 329,458.5C 333.22,454.638 338.054,452.305 343.5,451.5 Z"
    />
  </g>
  <g>
    <path
      d="M 387.5,452.5 C 403.529,450.379 412.863,457.212 415.5,473C 412.646,487.831 403.646,494.165 388.5,492C 374.907,486.647 370.741,477.147 376,463.5C 379.07,458.927 382.904,455.261 387.5,452.5 Z"
    />
  </g>
  <g>
    <path
      d="M 440.5,451.5 C 458.03,450.862 466.197,459.195 465,476.5C 460.677,487.586 452.51,492.419 440.5,491C 429.582,488.003 424.416,480.837 425,469.5C 426.811,460.528 431.978,454.528 440.5,451.5 Z"
    />
  </g>
  <g>
    <path
      d="M 492.5,451.5 C 506.77,450.796 515.104,457.463 517.5,471.5C 516.725,481.107 511.725,487.273 502.5,490C 490.614,492.64 482.114,488.474 477,477.5C 474.799,464.211 479.966,455.544 492.5,451.5 Z"
    />
  </g>
  <g>
    <path
      d="M 742.5,451.5 C 759.431,452.23 765.598,460.563 761,476.5C 753.66,485.841 744.826,487.675 734.5,482C 727.923,475.432 726.756,467.932 731,459.5C 733.961,455.34 737.795,452.673 742.5,451.5 Z"
    />
  </g>
  <g>
    <path
      d="M 691.5,453.5 C 707.581,454.395 713.081,462.395 708,477.5C 701.709,483.904 694.542,485.07 686.5,481C 680.436,473.958 679.936,466.458 685,458.5C 686.965,456.45 689.132,454.783 691.5,453.5 Z"
    />
  </g>
  <g>
    <path
      d="M 240.5,457.5 C 258.092,458.215 263.592,466.549 257,482.5C 243.17,490.838 234.67,487.005 231.5,471C 231.664,464.48 234.664,459.98 240.5,457.5 Z"
    />
  </g>
  <g>
    <path
      d="M 641.5,457.5 C 656.462,457.617 661.296,464.617 656,478.5C 644.205,486.174 636.705,483.174 633.5,469.5C 634.192,464.099 636.859,460.099 641.5,457.5 Z"
    />
  </g>
  <g>
    <path
      d="M 591.5,459.5 C 604.048,458.259 608.215,463.592 604,475.5C 595.035,483.111 589.202,481.111 586.5,469.5C 586.709,465.291 588.376,461.958 591.5,459.5 Z"
    />
  </g>
  <g>
    <path
      d="M 545.5,460.5 C 553.536,462.239 556.036,466.906 553,474.5C 545.72,479.533 540.554,477.866 537.5,469.5C 538.817,465.189 541.484,462.189 545.5,460.5 Z"
    />
  </g>
  <g>
    <path
      d="M 193.5,461.5 C 202.645,463.479 205.478,468.812 202,477.5C 196.686,481.636 191.686,481.302 187,476.5C 185.426,469.665 187.593,464.665 193.5,461.5 Z"
    />
  </g>
  <g>
    <path
      d="M 142.5,468.5 C 150.029,468.6 151.195,471.266 146,476.5C 141.584,474.72 140.417,472.053 142.5,468.5 Z"
    />
  </g>
  <g>
    <path
      d="M 291.5,501.5 C 303.417,500.535 311.584,505.535 316,516.5C 316.899,534.929 308.065,543.096 289.5,541C 275.613,534.007 272.113,523.507 279,509.5C 282.671,505.923 286.838,503.256 291.5,501.5 Z"
    />
  </g>
  <g>
    <path
      d="M 339.5,501.5 C 351.123,500.972 359.289,505.972 364,516.5C 364.889,534.94 356.055,543.106 337.5,541C 324.154,533.126 321.321,522.292 329,508.5C 331.998,505.262 335.498,502.929 339.5,501.5 Z"
    />
  </g>
  <g>
    <path
      d="M 442.5,501.5 C 456.841,501.674 465.174,508.841 467.5,523C 466.493,532.339 461.493,538.339 452.5,541C 437.235,542.888 427.901,536.388 424.5,521.5C 425.968,510.701 431.968,504.035 442.5,501.5 Z"
    />
  </g>
  <g>
    <path
      d="M 492.5,501.5 C 510.542,501.705 518.375,510.705 516,528.5C 508.947,540.962 498.78,544.462 485.5,539C 472.593,528.098 472.259,516.764 484.5,505C 487.158,503.522 489.825,502.355 492.5,501.5 Z"
    />
  </g>
  <g>
    <path
      d="M 541.5,501.5 C 558.511,500.678 566.677,508.678 566,525.5C 560.936,538.949 551.436,543.782 537.5,540C 527.195,535.89 523.361,528.39 526,517.5C 527.979,509.021 533.146,503.688 541.5,501.5 Z"
    />
  </g>
  <g>
    <path
      d="M 690.5,504.5 C 708.145,504.297 713.645,512.297 707,528.5C 700.397,533.9 693.564,534.4 686.5,530C 680.028,524.096 678.861,517.262 683,509.5C 685.17,507.177 687.67,505.51 690.5,504.5 Z"
    />
  </g>
  <g>
    <path
      d="M 740.5,504.5 C 755.858,504.021 762.691,511.355 761,526.5C 758.23,534.401 752.563,538.401 744,538.5C 731.568,536.317 726.568,528.983 729,516.5C 731.045,510.624 734.878,506.624 740.5,504.5 Z"
    />
  </g>
  <g>
    <path
      d="M 241.5,506.5 C 252.196,505.009 259.196,509.342 262.5,519.5C 260.657,530.926 253.991,535.759 242.5,534C 230.193,528.756 227.86,520.756 235.5,510C 237.727,509.059 239.727,507.892 241.5,506.5 Z"
    />
  </g>
  <g>
    <path
      d="M 641.5,507.5 C 648.018,506.123 653.185,508.123 657,513.5C 659.768,526.734 654.268,532.234 640.5,530C 632.055,526.469 630.222,520.636 635,512.5C 636.965,510.45 639.132,508.783 641.5,507.5 Z"
    />
  </g>
  <g>
    <path
      d="M 393.5,509.5 C 406.386,511.004 410.219,517.67 405,529.5C 399.174,534.082 393.008,534.582 386.5,531C 382.114,525.847 381.614,520.347 385,514.5C 387.682,512.415 390.516,510.748 393.5,509.5 Z"
    />
  </g>
  <g>
    <path
      d="M 593.5,509.5 C 604.219,510.037 608.053,515.37 605,525.5C 600.752,530.596 595.585,531.763 589.5,529C 583.69,520.949 585.024,514.449 593.5,509.5 Z"
    />
  </g>
  <g>
    <path
      d="M 194.5,511.5 C 203.203,511.555 206.703,515.889 205,524.5C 201.746,529.223 197.246,530.723 191.5,529C 186.913,526.237 185.746,522.403 188,517.5C 189.914,515.144 192.081,513.144 194.5,511.5 Z"
    />
  </g>
  <g>
    <path
      d="M 142.5,516.5 C 144.144,516.286 145.644,516.62 147,517.5C 147.758,519.769 147.591,521.936 146.5,524C 143.317,524.342 141.317,522.842 140.5,519.5C 141.193,518.482 141.859,517.482 142.5,516.5 Z"
    />
  </g>
  <g>
    <path
      d="M 589.5,549.5 C 605.42,547.834 613.587,554.834 614,570.5C 608.369,588.492 597.203,592.992 580.5,584C 576.74,580.648 574.407,576.481 573.5,571.5C 575.469,561.503 580.802,554.17 589.5,549.5 Z"
    />
  </g>
  <g>
    <path
      d="M 291.5,550.5 C 305.841,549.854 314.174,556.521 316.5,570.5C 314.944,582.718 307.944,589.051 295.5,589.5C 287.145,588.995 280.978,584.995 277,577.5C 273.5,563.825 278.333,554.825 291.5,550.5 Z"
    />
  </g>
  <g>
    <path
      d="M 491.5,550.5 C 509.521,550.019 517.688,558.685 516,576.5C 512.065,585.248 505.232,589.915 495.5,590.5C 483.158,589.491 476.491,582.824 475.5,570.5C 476.11,559.904 481.444,553.237 491.5,550.5 Z"
    />
  </g>
  <g>
    <path
      d="M 539.5,550.5 C 558.83,550.322 566.663,559.656 563,578.5C 555.008,589.706 544.841,592.206 532.5,586C 524.724,578.269 523.39,569.436 528.5,559.5C 531.338,555.313 535.005,552.313 539.5,550.5 Z"
    />
  </g>
  <g>
    <path
      d="M 338.5,551.5 C 356.331,551.164 364.831,559.83 364,577.5C 358.279,589.109 349.112,593.275 336.5,590C 325.64,583.772 322.14,574.605 326,562.5C 328.505,556.822 332.671,553.155 338.5,551.5 Z"
    />
  </g>
  <g>
    <path
      d="M 441.5,555.5 C 454.313,555.478 460.479,561.811 460,574.5C 455.81,583.177 448.976,586.343 439.5,584C 429.862,578.159 428.029,570.326 434,560.5C 436.426,558.548 438.926,556.881 441.5,555.5 Z"
    />
  </g>
  <g>
    <path
      d="M 240.5,556.5 C 250.134,556.141 256.468,560.475 259.5,569.5C 256.362,582.903 248.362,587.069 235.5,582C 227.91,575.031 227.576,567.698 234.5,560C 236.432,558.541 238.432,557.375 240.5,556.5 Z"
    />
  </g>
  <g>
    <path
      d="M 741.5,556.5 C 758.743,556.325 765.576,564.658 762,581.5C 755.777,590.017 747.611,592.517 737.5,589C 726.727,580.81 725.727,571.476 734.5,561C 736.898,559.476 739.231,557.976 741.5,556.5 Z"
    />
  </g>
  <g>
    <path
      d="M 691.5,558.5 C 702.063,557.905 708.396,562.739 710.5,573C 706.919,584.625 699.252,588.625 687.5,585C 680.206,579.532 678.706,572.699 683,564.5C 685.637,562.109 688.47,560.109 691.5,558.5 Z"
    />
  </g>
  <g>
    <path
      d="M 644.5,559.5 C 656.22,560.952 660.387,567.285 657,578.5C 650.275,584.729 643.275,585.062 636,579.5C 631.955,569.922 634.789,563.255 644.5,559.5 Z"
    />
  </g>
  <g>
    <path
      d="M 392.5,560.5 C 400.817,561.641 404.317,566.308 403,574.5C 400.103,579.155 395.937,580.655 390.5,579C 386.057,575.938 384.557,571.771 386,566.5C 387.436,563.558 389.603,561.558 392.5,560.5 Z"
    />
  </g>
  <g>
    <path
      d="M 196.5,561.5 C 204.31,562.434 207.144,566.768 205,574.5C 200.951,579.977 196.118,580.81 190.5,577C 185.393,569.051 187.393,563.884 196.5,561.5 Z"
    />
  </g>
  <g>
    <path
      d="M 141.5,566.5 C 146.906,565.735 148.906,568.069 147.5,573.5C 142.444,573.462 140.444,571.128 141.5,566.5 Z"
    />
  </g>
  <g>
    <path
      d="M 692.5,597.5 C 705.617,595.965 713.284,601.632 715.5,614.5C 712.918,627.962 704.918,633.128 691.5,630C 679.726,624.977 676.559,616.81 682,605.5C 684.94,601.963 688.44,599.297 692.5,597.5 Z"
    />
  </g>
  <g>
    <path
      d="M 643.5,599.5 C 657.708,599.854 663.541,606.854 661,620.5C 657.655,626.436 652.488,629.769 645.5,630.5C 631.715,626.929 627.548,618.596 633,605.5C 636.101,602.648 639.601,600.648 643.5,599.5 Z"
    />
  </g>
  <g>
    <path
      d="M 592.5,600.5 C 606.878,600.03 612.711,606.697 610,620.5C 604.151,628.75 596.651,630.583 587.5,626C 579.91,619.031 579.576,611.698 586.5,604C 588.432,602.541 590.432,601.375 592.5,600.5 Z"
    />
  </g>
  <g>
    <path
      d="M 544.5,601.5 C 558.742,602.217 563.575,609.217 559,622.5C 548.857,630.405 540.524,628.738 534,617.5C 533.183,614.036 533.517,610.702 535,607.5C 537.843,604.837 541.009,602.837 544.5,601.5 Z"
    />
  </g>
  <g>
    <path
      d="M 495.5,602.5 C 507.195,604.891 510.695,611.558 506,622.5C 500.368,628.133 494.201,628.8 487.5,624.5C 481.231,613.975 483.898,606.642 495.5,602.5 Z"
    />
  </g>
  <g>
    <path
      d="M 442.5,605.5 C 452.837,606.004 457.003,611.338 455,621.5C 450.353,627.931 444.52,629.098 437.5,625C 433.56,619.266 433.56,613.599 437.5,608C 439.315,607.243 440.981,606.41 442.5,605.5 Z"
    />
  </g>
  <g>
    <path
      d="M 391.5,607.5 C 403.224,606.382 407.391,611.382 404,622.5C 396.205,629.46 390.205,628.126 386,618.5C 384.679,613.141 386.513,609.474 391.5,607.5 Z"
    />
  </g>
  <g>
    <path
      d="M 341.5,608.5 C 347.709,607.933 352.042,610.433 354.5,616C 351.115,625.015 345.615,626.515 338,620.5C 334.614,615.158 335.781,611.158 341.5,608.5 Z"
    />
  </g>
  <g>
    <path
      d="M 241.5,610.5 C 249.697,610.586 251.863,614.252 248,621.5C 242.818,624.494 239.652,622.994 238.5,617C 238.84,614.489 239.84,612.322 241.5,610.5 Z"
    />
  </g>
  <g>
    <path
      d="M 291.5,609.5 C 300.183,609.002 303.516,612.835 301.5,621C 296.886,623.402 292.719,622.902 289,619.5C 288.024,615.673 288.857,612.34 291.5,609.5 Z"
    />
  </g>
  <g>
    <path
      d="M 190.5,613.5 C 194.811,612.536 197.811,614.036 199.5,618C 196.167,624.496 193,624.662 190,618.5C 189.616,616.803 189.782,615.137 190.5,613.5 Z"
    />
  </g>
  <g>
    <path
      d="M 144.5,613.5 C 151.412,615.224 152.079,618.224 146.5,622.5C 140.796,620.418 140.13,617.418 144.5,613.5 Z"
    />
  </g>
  <g>
    <path
      d="M 643.5,647.5 C 655.198,647.698 662.198,653.531 664.5,665C 660.618,679.442 651.618,684.108 637.5,679C 626.77,670.27 626.103,660.936 635.5,651C 638.309,649.907 640.976,648.74 643.5,647.5 Z"
    />
  </g>
  <g>
    <path
      d="M 592.5,649.5 C 606.937,650.757 612.77,658.424 610,672.5C 605.216,681.208 598.049,684.042 588.5,681C 579.329,674.142 577.495,665.642 583,655.5C 585.843,652.837 589.009,650.837 592.5,649.5 Z"
    />
  </g>
  <g>
    <path
      d="M 545.5,650.5 C 559.873,652.005 564.373,659.338 559,672.5C 552.402,679.043 544.902,680.209 536.5,676C 532.219,670.377 531.385,664.21 534,657.5C 537.225,653.979 541.058,651.646 545.5,650.5 Z"
    />
  </g>
  <g>
    <path
      d="M 494.5,652.5 C 508.547,653.95 512.714,660.95 507,673.5C 500.836,678.11 494.336,678.61 487.5,675C 479.635,664.424 481.969,656.924 494.5,652.5 Z"
    />
  </g>
  <g>
    <path
      d="M 441.5,653.5 C 452.999,653.154 457.832,658.487 456,669.5C 446.569,679.652 438.736,678.652 432.5,666.5C 433.318,660.493 436.318,656.159 441.5,653.5 Z"
    />
  </g>
  <g>
    <path
      d="M 393.5,654.5 C 402.568,656.3 405.735,661.633 403,670.5C 397.856,676.141 392.189,676.808 386,672.5C 382.309,663.576 384.809,657.576 393.5,654.5 Z"
    />
  </g>
  <g>
    <path
      d="M 341.5,656.5 C 351.652,656.837 355.485,661.837 353,671.5C 345.983,677.609 340.317,676.609 336,668.5C 335.413,663.344 337.246,659.344 341.5,656.5 Z"
    />
  </g>
  <g>
    <path
      d="M 291.5,657.5 C 301.46,656.937 304.46,661.104 300.5,670C 287.603,672.937 284.603,668.77 291.5,657.5 Z"
    />
  </g>
  <g>
    <path
      d="M 191.5,661.5 C 198.969,660.941 201.303,663.941 198.5,670.5C 189.556,672.239 187.223,669.239 191.5,661.5 Z"
    />
  </g>
  <g>
    <path
      d="M 243.5,660.5 C 251.057,660.85 253.39,664.35 250.5,671C 244.389,673.778 240.389,671.944 238.5,665.5C 239.245,662.754 240.912,661.088 243.5,660.5 Z"
    />
  </g>
  <g>
    <path
      d="M 142.5,661.5 C 147.486,661.305 149.152,663.472 147.5,668C 145.564,668.813 143.564,669.313 141.5,669.5C 141.279,666.721 141.612,664.054 142.5,661.5 Z"
    />
  </g>
</svg>
}

export default ConfigIcon;
