import React from 'react';

const Icon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" fill="currentColor" style={{shapeRendering:"geometricPrecision", textRendering:"geometricPrecision", imageRendering:"optimizeQuality", fillRule:"evenodd", clipRule:"evenodd"}}>
    <g><path d="M499.6 236.3L441.5 210l-161.6 73.25C272.3 286.6 264.3 288.4 256 288.4S239.8 286.6 232.1 283.3L70.5 210L12.38 236.3c-16.5 7.5-16.5 32.5 0 40l233 105.6c6.75 3.125 14.5 3.125 21.25 0l233-105.6C516.1 268.8 516.1 243.8 499.6 236.3zM12.38 148l233 105.8c6.75 3 14.5 3 21.25 0L499.6 148c16.5-7.5 16.5-32.5 0-40l-233-105.8c-6.75-3-14.5-3-21.25 0L12.38 108C-4.125 115.5-4.125 140.5 12.38 148zM499.6 364.1l-57.88-26.25l-161.9 73.38C272.3 414.6 264.3 416.4 256 416.4s-16.25-1.75-23.88-5.125l-161.9-73.38l-57.88 26.25c-16.5 7.5-16.5 32.5 0 40l233 105.6c6.75 3 14.5 3 21.25 0l233-105.6C516.1 396.6 516.1 371.6 499.6 364.1z"/></g>
  </svg>
};


export default Icon;
