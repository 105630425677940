import axios from 'axios';
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment-jalaali';
import { MdDoneAll, MdDelete } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { HashLoader } from 'react-spinners';
import { FaRegSquare, FaRegCheckSquare, FaLink, FaSave, FaRegCopy, FaCheck } from 'react-icons/fa';
import { FiHelpCircle } from "react-icons/fi";
import copy from 'copy-to-clipboard';
import to from 'await-to-js';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useToken from '../hooks/useToken';
import { MultiSelectComponent, HDatePicker, SubmitComponent, CheckBox } from '../components';
import useOutsideClicked from '../hooks/useOutsideClick';

function getUniqueWithArrayFields(array, uniqueField, fieldsToArray) {
  const grouped = array.reduce((acc, item) => {
      const key = item[uniqueField];
      
      if (!acc[key]) {
          // برای اولین آیتم، یک آبجکت جدید میسازیم
          acc[key] = { ...item };
          // فیلدهایی که باید آرایه بشن رو آرایه میکنیم
          fieldsToArray.forEach(field => {
            if (item[field] === null) {
                acc[key][field] = [];
            } else {
                // اگر خودش آرایه بود flat میکنیم، اگر نبود در آرایه قرار میدیم
                acc[key][field] = Array.isArray(item[field]) ? 
                    item[field] : 
                    [item[field]];
            }
        });
      } else {
          // برای آیتم‌های بعدی، فیلدهای مورد نظر رو به آرایه اضافه میکنیم
          fieldsToArray.forEach(field => {
            if (item[field] !== null) {
                const newValues = Array.isArray(item[field]) ? 
                    item[field] : 
                    [item[field]];
                    
                // اضافه کردن مقادیر جدید که تکراری نباشند
                newValues.forEach(value => {
                    if (!acc[key][field].includes(value)) {
                        acc[key][field].push(value);
                    }
                });
            }
        });
      }
      return acc;
  }, {});

  return Object.values(grouped);
}

const Tagging = ({title, addToastHandler}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessToken] = useToken();
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterParameters, setFilterParameters] = useState({ titr: '', hozeh: [], hozehMozuei: [], roydad: '', resaneh: [], persons: [], notComplete: false });
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRoydadLoading, setIsRoydadLoading] = useState(false);
  const [isOneMessageLoading, setIsOneMessageLoading] = useState(null);
  const [showSearchPanel, setShowSearchPanel] = useState(true);
  const [showFilterPanel, setShowFilterPanel] = useState(true);
  const [showRoydadPanel, setShowRoydadPanel] = useState(false);
  const [showHelpPanel, setShowHelpPanel] = useState(false);
  const [helpContent, setHelpContent] = useState([]);
  const [filterRoydad, setFilterRoydad] = useState(null);
  const [currentRoydads, setCurrentRoydads] = useState([]);
  const [roydads, setRoydads] = useState([]);
  const [persons, setPersons] = useState([]);
  const [hozeh, setHozeh] = useState([]);
  const [hozehMozuei, setHozehMozuei] = useState([]);
  const [submitDelete, setSubmitDelete] = useState(null);
  const [newhelpContent, setNewhelpContent] = useState({index: null, content: null});

  const params = new URLSearchParams();

  useEffect(() => {
    getPersons();
    getHozeh();
    getHozehMozuei();
    getHelpContent();
    getRoydad()
  }, [])

  const location = useLocation();
  const [queryData, setQueryData] = useState({});

  useEffect(() => {
    // Parse the query string
    const searchParams = new URLSearchParams(location.search);
    
    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    setQueryData(queryParams);
    if(queryParams.from) setFromDate(moment(queryParams.from).format('YYYY-MM-DD'))
    if(queryParams.to) setToDate(moment(queryParams.to).format('YYYY-MM-DD'))

    
    navigate(`?${params.toString()}`, { replace: true });
  }, []);

  useEffect(() => {
    getMessageHansler();
  }, [hozeh, hozehMozuei])

  // useEffect(() => {
  //   handleSearch(filterRoydad)
  // }, [roydads, messages])
  
  useEffect(() => {
    handleSearch(filterRoydad, roydads, messages)
  }, [filterRoydad, roydads, messages])
  

  const convertJsonToExcel = (data1)=>{
    const workbook = XLSX.utils.book_new();
    if(!workbook.Workbook) workbook.Workbook = {};
    if(!workbook.Workbook.Views) workbook.Workbook.Views = [];
    if(!workbook.Workbook.Views[0]) workbook.Workbook.Views[0] = {};
    workbook.Workbook.Views[0].RTL = true;
    
    const worksheet1 = XLSX.utils.json_to_sheet(data1);
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1')

    //Generate buffer
    XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'});
    //Binary string
    XLSX.write(workbook, {bookType: 'xlsx', type: 'binary'});

    const jdate = moment().locale('fa').format('jDD jMMMM jYYYY hhmmss')
    XLSX.writeFile(workbook, `${jdate}.xlsx`)
  };

  const saveExcel = ()=>{
    const exportMessages = messages.map((m) => {return {...m, hozeh: m.hozeh.map((h) => hozeh.filter((hh) => hh.id === h)[0].hozeh + ' - ' + hozeh.filter((hh) => hh.id === h)[0].bakhsh).join('|'), hozehMozuei: m.hozehMozuei.map((h) => hozehMozuei.filter((hh) => hh.id === h)[0].hozehMozuei + ' - ' + hozehMozuei.filter((hh) => hh.id === h)[0].moalefe).join('|'), persons: m.persons.join('|') }});
    convertJsonToExcel(exportMessages.map((m) => {return {'تاریخ': moment(m.postDate).format('jYYYY-jMM-jDD'), 'عنوان': m.titr, 'رسانه': m.resaneh, 'لینک': m.link, 'رویداد': m.roydad, 'اشخاص': m.persons, 'حوزه': m.hozeh, 'حوزه موضوعی': m.hozehMozuei }}));
  };

  const getHelpContent = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/help-content`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    );

    if (err) {
      console.log(err);
      return;
    }
    setHelpContent(response.data.results);
  };
  
  const getPersons = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-persons`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    );

    if (err) {
      console.log(err);
      return;
    }
    setPersons(response.data.results);
  };

  const getHozeh = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setHozeh(response.data.results)
  };

  const getHozehMozuei = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh-mozuei`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setHozehMozuei(response.data.results)
  };

  const getMessageHansler = async () => {
    if(hozeh.length === 0 || hozehMozuei.length === 0) return;
    setIsLoading(true)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/get-web-messages-with-date`,
        {
          fromDate: fromDate,
          toDate: toDate,
          // limit: limit,
          // offset: offset
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }
    setIsLoading(false)
    setPersons([...new Set([...persons,...response.data.results.map(m => m.persons).flat()])])
    setMessages(response.data.results)
  }; 

  const addPersonsToSelectedMessages = () => {
    const tempMsgs1 = messages.filter((m) => !selectedRows.includes(m.id));
    const tempMsgs2 = messages.filter((m) => selectedRows.includes(m.id)).map((m) => { return {...m, persons: [...m.persons, ...filterParameters.persons] }});
    setMessages([...tempMsgs1, ...tempMsgs2]);
    setFilterParameters({ ...filterParameters, persons: [] });
  };

  const addRoydadToSelectedMessages = () => {
    const tempMsgs1 = messages.filter((m) => !selectedRows.includes(m.id));
    const tempMsgs2 = messages.filter((m) => selectedRows.includes(m.id)).map((m) => {return {...m, roydad: filterParameters.roydad}});
    setMessages([...tempMsgs1, ...tempMsgs2]);
    setFilterParameters({ ...filterParameters, roydad: '' });
  };

  const addHozehToSelectedMessages = () => {
    const tempMsgs1 = messages.filter((m) => !selectedRows.includes(m.id));
    const tempMsgs2 = messages.filter((m) => selectedRows.includes(m.id)).map((m) => {return {...m, hozeh: filterParameters.hozeh}});
    setMessages([...tempMsgs1, ...tempMsgs2]);
    setFilterParameters({ ...filterParameters, hozeh: [] });
  };

  const addHozehMozueiToSelectedMessages = () => {
    const tempMsgs1 = messages.filter((m) => !selectedRows.includes(m.id));
    const tempMsgs2 = messages.filter((m) => selectedRows.includes(m.id)).map((m) => {return {...m, hozehMozuei: filterParameters.hozehMozuei}});
    setMessages([...tempMsgs1, ...tempMsgs2]);
    setFilterParameters({ ...filterParameters, hozehMozuei: [] });
  };

  const helpPanel = useRef(null);

  useOutsideClicked(helpPanel, () => setShowHelpPanel(false));

  const submitHandler = async()=>{
    setIsLoading(true)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/update-web-messages`,
        {
          messages: messages.map(message=>{return {roydad: message.roydad,
            hozehIds: message.hozeh,
            hozehMozueiIds: message.hozehMozuei,
            persons: message.persons,
          messageId: message.id}
        })
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'خطایی در هنگام در ثبت رخ داده است.',
        type: 'error', 
        seconds: 3
      })
      return
    }
    getMessageHansler()
    setIsLoading(false)
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'اطلاعات با موفقیت ثبت شد.',
      type: 'success', 
      seconds: 3
    })
  };
  
  const submitOneMessageHandler = async(message)=>{
    setIsOneMessageLoading(message.id)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/update-web-message/${message.id}`,
        {roydad: message.roydad,
          hozehIds: message.hozeh,
          hozehMozueiIds: message.hozehMozuei,
          persons: message.persons
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      setIsOneMessageLoading(null)
      addToastHandler({
        id: Math.floor(Math.random()*3000),
        title: 'خطا',
        description: 'خطایی در هنگام ثبت رخ داده است.',
        type: 'error', 
        seconds: 3
      })
      return
    }
    setIsOneMessageLoading(null)
    addToastHandler({
      id: Math.floor(Math.random()*3000),
      title: 'موفق',
      description: 'اطلاعات با موفقیت ثبت شد.',
      type: 'success', 
      seconds: 3
    })
  };

  const deleteHandler = async(id)=>{
    let ids = []
    if(id === 'all'){
      setIsLoading(true)
      ids = selectedRows;
    }
    else{
      ids = [id]
    }

    for (let i = 0; i < ids.length; i++) {
      const [err, response] = await to(
        axios.delete(
          `${process.env.REACT_APP_BASE_URL}/message/delete-one-web-message/${ids[i]}`,{
            headers: { 'authorization': `Bearer ${accessToken}` }
          }
        )
      )
        
      if (err) {
        console.log(err);
        return;
      }
    }
    
    setIsLoading(false)
    setMessages(o=>o.filter(m=> !ids.includes(m.id)))
    setSubmitDelete(null)
    setSelectedRows([])
  };

  const addHelpContentHandler = async(id)=>{
    if(!newhelpContent.content || (newhelpContent.content && newhelpContent.content.trim() === '')) return
    console.log(newhelpContent)

    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/help-content`,
        {content: newhelpContent.content},
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if (err) {
      console.log(err);
      setIsLoading(false)
      return;
    }
    
    getHelpContent()
    setIsLoading(false)
    setNewhelpContent({index: null, content: null})
  };

  const deleteHelpContentHandler = async(id)=>{
    const [err, response] = await to(
      axios.delete(
        `${process.env.REACT_APP_BASE_URL}/help-content/${id}`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if (err) {
      console.log(err);
      return;
    }
    
    getHelpContent()
  };

  const updateHelpContentHandler = async(id)=>{
    const [err, response] = await to(
      axios.delete(
        `${process.env.REACT_APP_BASE_URL}/help-content/${id}`,
        {content: newhelpContent.content},{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if (err) {
      console.log(err);
      setIsLoading(false)
      return;
    }
    
    getHelpContent()
    setIsLoading(false)
  };

  const getRoydad = async()=>{
    setIsRoydadLoading(true)
    
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/roydad${filterRoydad?`?like=${filterRoydad}`:''}`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if (err) {
      console.log(err);
      setIsRoydadLoading(false)
      return;
    }
    setRoydads(response.data.results)
    setIsRoydadLoading(false)
  };

  const selectAllRowsHandler = () => {
    const allRows = messages.filter((m) => filterParameters.resaneh.length === 0?true:filterParameters.resaneh.includes(m.resaneh)).filter(m=>{if(filterParameters.notComplete){return (m.roydad === '' || m.hozeh.length === 0)}else{return true}}).filter(m=>(m.titr.indexOf(filterParameters.titr) > -1 || m.roydad.indexOf(filterParameters.titr) > -1)).map((m) => m.id);
    const newRows = [...selectedRows, ...allRows];
    setSelectedRows([...new Set(newRows)]);
  };

  const dateHandler = (d) => {
    setFromDate(d[0])
    setToDate(d[1])
    params.append("from", d[0])
    params.append("to", d[1])
    navigate(`?${params.toString()}`, { replace: true });
  }

  const [results, setResults] = useState([]);

  const highlightText = (text: string, searchQuery: string) => {
    if (!searchQuery || !searchQuery.trim()) return text;

    // تقسیم عبارت جستجو به کلمات و مرتب‌سازی بر اساس طول (نزولی)
    const searchTerms = searchQuery.toLowerCase()
      .split(/\s+/)
      .filter(Boolean)
      .sort((a, b) => b.length - a.length); // طولانی‌ترین عبارات اول

    let result = text;
    const elements: React.ReactNode[] = [];
    let processedText = text;
    let markers: { start: number; end: number; text: string }[] = [];

    // پیدا کردن همه موقعیت‌های منطبق
    searchTerms.forEach(term => {
      let match;
      const regex = new RegExp(term, 'gi');
      while ((match = regex.exec(processedText)) !== null) {
        const start = match.index;
        const end = start + match[0].length;
        
        // بررسی همپوشانی با نشانه‌های قبلی
        const hasOverlap = markers.some(
          marker => (start < marker.end && end > marker.start)
        );
        
        if (!hasOverlap) {
          markers.push({ start, end, text: match[0] });
        }
      }
    });

    // مرتب‌سازی نشانه‌ها بر اساس موقعیت
    markers.sort((a, b) => a.start - b.start);

    // ساخت خروجی نهایی
    let lastIndex = 0;
    markers.forEach(marker => {
      if (marker.start > lastIndex) {
        elements.push(processedText.substring(lastIndex, marker.start));
      }
      elements.push(
        <span key={marker.start} className="bg-yellow-200 px-0.5 rounded">
          {marker.text}
        </span>
      );
      lastIndex = marker.end;
    });

    if (lastIndex < processedText.length) {
      elements.push(processedText.substring(lastIndex));
    }

    return <>{elements}</>;
  };

  const handleSearch = useCallback((term: string, rydds, msgs) => {
    // const source = [...new Set([...rydds, ...msgs.filter(m => m.roydad !== null).map(m => {return {roydad: m.roydad, bakhsh: hozeh.filter(h => h.id === m.hozeh[0])[0].bakhsh}})])].filter(a => a !== "")
    let source = [...new Set([...rydds, ...msgs.filter(m => m.roydad !== null).map(m => {return {roydad: m.roydad, bakhsh: m.hozeh.map(id => hozeh.find(h => h.id === id)?.bakhsh).filter(Boolean)}})])]
    source = getUniqueWithArrayFields(source, 'roydad', ['bakhsh'])
    if (!term) {
      setResults(source);
      return;
    }

    const searchTerms = term.toLowerCase().split(' ');
    
    const matches = source.filter(item => {
      const itemText = item.roydad.toLowerCase();
      return searchTerms.every(filterRoydad => 
        itemText.includes(filterRoydad)
      );
    });

    setResults(matches);
  }, []);

  moment.loadPersian()
  return (
    <div className={`relative h-full box-border flex flex-col gap-1 justify-start items-stretch ${showRoydadPanel && 'overflow-y-hidden'} dark:bg-secondary-dark-bg`}>
      {/* Roydad Panel */}
      {(showHelpPanel) && (<div className="fixed z-[100] top-0 -end-10 w-full h-full bg-black opacity-60" />)}

      {showHelpPanel && (
          <div ref={helpPanel} className="absolute z-[1100] top-1/2 end-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[30rem] min-h-[30rem] max-h-97 p-3 flex flex-col gap-2 text-sm rounded-md max-w-3xl border bg-white dark:bg-gray-800 dark:border-gray-700">
          <div className="flex-grow border dark:border-gray-700 rounded p-2 overflow-y-auto flex flex-col gap-1">
            {helpContent.sort((a,b)=>a-b).map((m, idx) =>
              <div key={idx} className="flex justify-between items-center gap-2 border dark:border-gray-700 rounded px-2 py-1">
                <button onClick={()=>{setShowHelpPanel(false); copy(m.content)}} className="text-blue-600 hover:text-blue-800"><FaRegCopy /></button>
                <p className='flex-grow text-xs'>{m.content}</p>
                <button onClick={()=>{deleteHelpContentHandler(m.id)}} className="text-red-600 hover:text-red-800"><MdDelete /></button>
              </div>
            )}
            { newhelpContent.index !== null && <div className="flex justify-between items-center gap-2 border dark:border-gray-700 rounded px-2 py-1">
              <input onChange={(e)=>{setNewhelpContent({...newhelpContent, content: e.target.value})}} value={newhelpContent.content} className="flex-grow outline-none focus:outline-none bg-transparent"/>
              <button onClick={()=>{addHelpContentHandler()}} className="text-teal-600 hover:text-teal-800"><FaCheck /></button>
            </div>}
            <button className="rounded bg-teal-600 hover:bg-teal-500" onClick={() => setNewhelpContent({index: helpContent.length, content: null})}>+</button>
          </div>
          <div className="flex justify-end items-center gap-2">
            <button onClick={()=>{setShowHelpPanel(false)}} className="bg-blue-500 hover:bg-blue-400 text-white border rounded px-4 py-1">بستن</button>
          </div>
        </div>
      )}
      {/* search panel */}
      <div className={`relative flex flex-wrap md:grid md:grid-cols-12 gap-1 border rounded p-1 bg-slate-100 dark:bg-gray-700 dark:border-gray-500 pb-3 transition-all duration-500 ease-in-out ${showSearchPanel?'h-auto md:h-12':'h-12 md:h-4'}`}>
        {showSearchPanel && <div className="col-span-12 flex flex-col md:flex-row items-center justify-between gap-4">
          <div className=" grid grid-cols-1 md:flex items-center justify-start gap-2 md:gap-4">
            <HDatePicker className={`text-sm`} date={[fromDate, toDate]} setDate={d => dateHandler(d)} />
            <button onClick={()=>getMessageHansler()} className='px-2 py-1 border rounded text-sm bg-blue-500 hover:bg-blue-400 text-white dark:border-0' type="button">نمایش</button>
            <CheckBox className="text-xs" value={filterParameters.notComplete} onChange={(e) => setFilterParameters({...filterParameters, notComplete: e})} title="فقط نمایش رکوردهای ناقص" />
            <p className="py-1 text-xs font-bold">{`(${messages.filter((m) => filterParameters.resaneh.length === 0?true:filterParameters.resaneh.includes(m.resaneh)).filter(m=>{if(filterParameters.notComplete){return (m.roydad === '' || m.hozeh.length === 0)}else{return true}}).filter(m=>(m.titr.indexOf(filterParameters.titr) > -1 || m.roydad.indexOf(filterParameters.titr) > -1)).length} رکورد از ${messages.length} رکورد)`}</p>
            {/* {<button onClick={()=>{getRoydad();setShowRoydadPanel(true)}} className='px-2 py-1 border rounded text-xs text-blue-600 hover:bg-blue-400 hover:text-white dark:border-blue-500' type="button">مشاهده رویدادها</button>} */}
            {selectedRows.length > 0 && <button onClick={()=>{setSubmitDelete('all')}} className='px-2 py-1 border rounded text-xs text-red-600 hover:bg-red-400 hover:text-white dark:border-red-500' type="button">حذف رکوردهای انتخاب شده</button>}
            {submitDelete==='all' && <button onClick={()=>{setSubmitDelete(null)}} className={`rounded font-bold px-1 text-blue-500 hover:bg-blue-500 hover:text-white`}>انصراف</button>}
            {submitDelete==='all' && <button onClick={()=>{deleteHandler('all')}} className={`rounded font-bold px-1 text-red-500 hover:bg-red-500 hover:text-white`}>حذف</button>}
          </div>
          <div className="flex items-center justify-start gap-4">
            {messages.length > 0 && <button onClick={()=> {saveExcel()}} className='px-2 py-1 border rounded justify-self-start text-xs text-blue-600 hover:bg-blue-400 hover:text-white dark:border-blue-500' type="button">ذخیره اکسل</button>}
            <button className="text-gray-700 hover:text-gray-500 dark:text-teal-300 dark:hover:text-teal-500" onClick={() => setShowHelpPanel(true)}><FiHelpCircle/></button>
          </div>
        </div>}
        <button onClick={()=>setShowSearchPanel(o=>!o)} className='absolute bottom-0 start-1/2 text-blue-400 text-sm' type="button">{showSearchPanel?<IoIosArrowUp/>:<IoIosArrowDown />}</button>
      </div>

      <div className="w-full h-full max-h-full flex gap-1 overflow-auto">
          <div className="w-1/6 max-h-full hidden md:flex flex-col gap-2 bg-white dark:bg-gray-700 dark:border-gray-500 border-1 rounded p-2">
            <div className="flex justify-center items-center gap-2">
              <input type="text" onChange={(e) => {setFilterRoydad(e.target.value)}} value={filterRoydad} className='flex-grow rounded border px-2 py-1 outline-none text-xs dark:bg-secondary-dark-bg dark:border-gray-500' placeholder='جستجوی رویداد...'/>
              <button className="bg-blue-500 hover:bg-blue-400 text-white border dark:border-gray-500 rounded px-4 py-1 text-xs" onClick={() => getRoydad()}>دریافت</button>
            </div>
            <div className="flex-grow rounded overflow-y-auto scrollbar-hide flex flex-col gap-1">
              {(isRoydadLoading) && <div className="w-full flex justify-center items-center">
                <Loader size={60}/>
              </div>}
              {!isRoydadLoading && results.map((m, idx) =>
                <div key={idx} className="flex justify-between items-center gap-2 border rounded px-2 py-1 text-teal-700 dark:text-amber-600 dark:border-gray-500 shadow-lg dark:shadow-gray-700">
                  <div className="w-full">
                    <p className='text-xs'>{highlightText(m.roydad, filterRoydad)}</p>
                    {m.bakhsh.map(mb => <p className='w-full text-xs text-center bg-gray-600 rounded px-1'>{mb}</p>)}
                  </div>
                  <button onClick={()=>{setShowRoydadPanel(false); copy(m.roydad)}} className="text-blue-600 hover:text-blue-800"><FaRegCopy/></button>
                </div>
              )}
              {/* {!isRoydadLoading && results.map((m, idx) =>
                <div key={idx} className="flex justify-between items-center gap-2 border rounded px-2 py-1 dark:border-gray-500 shadow-lg dark:shadow-gray-700">
                  <p className='text-xs'>{highlightText(result.text, filterRoydad)}</p>
                  <button onClick={()=>{setShowRoydadPanel(false); copy(m)}} className="text-blue-600 hover:text-blue-800"><FaRegCopy/></button>
                </div>
              )} */}
            </div>
          </div>
          <div className="w-full md:w-5/6 bg-white dark:bg-gray-700 dark:border-gray-500 border-1 rounded flex flex-col gap-2 p-2">
            {/* filter panel */}
            <div className={`w-full relative flex flex-wrap md:grid md:grid-cols-12 gap-1 border rounded p-1 bg-slate-100 dark:bg-gray-700 dark:border-gray-500 pb-3 transition-all duration-200 ease-in-out ${showFilterPanel?'h-auto md:h-fit':'h-12 md:h-4'}`}>
              {showFilterPanel && <div className="col-span-3 w-full flex justify-start items-center gap-2">
                <button onClick={()=>{selectAllRowsHandler()}} className={`p-1 border rounded font-bold text-blue-800 dark:text-teal-500 hover:text-blue-300 dark:bg-secondary-dark-bg dark:border-gray-500`}><MdDoneAll /></button>
                <div className="flex gap-1 px-1 border rounded dark:bg-secondary-dark-bg dark:border-gray-500">
                  <button onClick={()=>{setSelectedRows([]); setSubmitDelete(null)}} className={` font-bold py-1 text-blue-800 dark:text-teal-500 hover:text-blue-300`}><FaRegSquare/></button>
                  {selectedRows.length > 0 && <p className="text-xs p-1 rounded font-bold text-blue-800">{selectedRows.length}</p>}
                </div>
                <input type="text" id="" placeholder="جستجوی متن" className="flex-grow px-2 py-1 text-xs rounded border focus:outline-none dark:bg-secondary-dark-bg dark:border-gray-500" value={filterParameters.titr} onChange={(e)=>{setFilterParameters({...filterParameters, titr: e.target.value})}}/>
              </div>}
              {showFilterPanel && <SubmitComponent className="col-span-4 w-full" onClick={()=>{addRoydadToSelectedMessages()}}>
                <input type="text" placeholder="اختصاص رویداد" value={filterParameters.roydad} onChange={(e)=>{setFilterParameters({...filterParameters, roydad: e.target.value})}} className="w-full rounded border px-2 py-1 text-xs focus:outline-none dark:bg-secondary-dark-bg dark:border-gray-500"/>
              </SubmitComponent>}
              {showFilterPanel && <SubmitComponent className="col-span-2 w-full" onClick={()=>{addPersonsToSelectedMessages()}}>
                <MultiSelectComponent placeholder="اشخاص..." className={`w-full rounded border px-2 dark:border-gray-500`} allowToAddItem={true} addItemHandler={(item)=>{setPersons([...persons, item])}} closeOnClick={false} data={persons.map(h=>{return {value: h, title: h}})} value={filterParameters.persons} onChange={(s)=>{setFilterParameters({...filterParameters, persons: s})}}/>
              </SubmitComponent>}
              {showFilterPanel && <SubmitComponent className="col-span-3 w-full" onClick={()=>{addHozehToSelectedMessages()}}>
                <MultiSelectComponent placeholder="اختصاص حوزه..." className={`w-full rounded border px-2 dark:border-gray-500`} topFilter={[...new Set(hozeh.map(h=>h.hozeh))]} closeOnClick={false} data={hozeh.map(h=>{return {id: h.id, title: h.hozeh + ' | ' + h.bakhsh}})} fields={{value: 'id', title: 'title'}} value={filterParameters.hozeh} onChange={(s)=>{setFilterParameters({...filterParameters, hozeh: s})}}/>
              </SubmitComponent>}
              {/* {showFilterPanel && <SubmitComponent className="col-span-2 w-full" onClick={()=>{addHozehMozueiToSelectedMessages()}}>
                <MultiSelectComponent placeholder="اختصاص حوزه موضوعی..." className={`w-full rounded border px-2 dark:border-gray-500`} topFilter={[...new Set(hozehMozuei.map(h=>h.hozehMozuei))]} closeOnClick={false} data={hozehMozuei.map(h=>{return {id: h.id, title: h.hozehMozuei + ' | ' + h.moalefe}})} fields={{value: 'id', title: 'title'}} value={filterParameters.hozehMozuei} onChange={(s)=>{setFilterParameters({...filterParameters, hozehMozuei: s})}}/>
              </SubmitComponent>} */}
              {showFilterPanel && <MultiSelectComponent placeholder="فیلتر رسانه..." className={`col-span-6 w-full rounded px-0 py-0 border-0 text-red-900 dark:text-amber-500`} start={true}  fontSize={'text-[10px]'} closeOnClick={false} data={[...new Set(messages.map((m) => m.resaneh))].map((r) => {return {id: r, title: r}})} fields={{value: 'id', title: 'title'}} value={filterParameters.resaneh} onChange={(s)=>{setFilterParameters({...filterParameters, resaneh: s})}}/>}
              <button onClick={()=>setShowFilterPanel(o=>!o)} className='absolute bottom-0 start-1/2 text-blue-400 dark:text-teal-500' type="button">{showFilterPanel?<IoIosArrowUp/>:<IoIosArrowDown />}</button>
            </div>
            {/* loading */}
            {(isLoading) && <div className="w-full flex justify-center items-center">
              <Loader size={60}/>
            </div>}
            <div className="flex-grow overflow-x-hidden overflow-y-auto flex flex-col gap-4 pb-20 bg-slate-50 dark:bg-secondary-dark-bg dark:border-gray-500">
              {(!isLoading) && messages.filter((m) => filterParameters.resaneh.length === 0?true:filterParameters.resaneh.includes(m.resaneh)).filter(m=>{if(filterParameters.notComplete){return (m.roydad === '' || m.hozeh.length === 0)}else{return true}}).filter(m=>(m.titr.indexOf(filterParameters.titr) > -1 || m.roydad.indexOf(filterParameters.titr) > -1)).sort((a,b)=>b.id - a.id).map((m,idx)=>
                <div key={idx} className={`grid grid-cols-12 gap-y-4 md:gap-y-0 gap-1 items-center shadow-lg dark:shadow-gray-700 p-3 py-5 justify-start border-b-2 dark:border-gray-700 rounded text-xs ${selectedRows.includes(m.id) && 'border-2 border-teal-800 dark:border-amber-600'}`}>
                  <div className="col-span-12 md:col-span-3 gap-2 md:gap-1 flex flex-col md:flex-row justify-start items-center rounded">
                  <CheckBox className="text-xs text-white" boxStyle='text-teal-700 border-2 border-teal-600 dark:text-teal-500' value={selectedRows.includes(m.id)} onChange={(e) => {setSelectedRows(o=>{if(o.includes(m.id)){return o.filter(f=>f!==m.id)}else{return [...o, m.id]}})}} title="" />

                  {/* <button onClick={()=>{setSelectedRows(o=>{if(o.includes(m.id)){return o.filter(f=>f!==m.id)}else{return [...o, m.id]}})}} className="text-2xl text-teal-800 dark:text-teal-500">{selectedRows.includes(m.id)?<FaRegCheckSquare/>:<FaRegSquare/>}</button> */}

                    {/* <button onClick={()=>{setSelectedRows(o=>{if(o.includes(m.id)){return o.filter(f=>f!==m.id)}else{return [...o, m.id]}})}} className={`px-2 py-1 border aspect-square rounded-full md:rounded font-bold text-blue-800 hover:bg-blue-200 ${selectedRows.includes(m.id) && 'bg-blue-400'}`}>{idx+1}</button> */}
                    <div className="flex justify-start items-center rounded md:px-2 w-full gap-2 md:gap-1">
                      <p className="text-xs font-bold text-blue-800 dark:text-teal-600">عنوان:</p>
                      <p className="flex-grow px-2 py-1 border rounded dark:bg-secondary-dark-bg dark:border-gray-500">{m.titr}</p>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-4 flex justify-start items-center rounded md:px-2 gap-2 md:gap-1">
                    <p className="text-xs font-bold text-blue-800 dark:text-teal-600">رویداد:</p>
                    <input type="text" className="flex-grow px-2 py-1 border rounded dark:bg-secondary-dark-bg dark:border-gray-500" value={m.roydad} onChange={(e)=>setMessages(o=>[...o.filter(me=>me.id != m.id), {...m, roydad: e.target.value}])}/>
                  </div>
                  <div className="col-span-12 md:col-span-2 flex justify-between items-center gap-2 md:gap-1">
                    <p className="text-xs font-bold text-blue-800 dark:text-teal-600">شخص:</p>
                    <MultiSelectComponent className={`rounded border px-2 py-1 dark:border-gray-500`} placeholder="اشخاص..." allowToAddItem={true} addItemHandler={(item)=>{setPersons([...persons, item])}} closeOnClick={false} data={persons.map(h=>{return {value: h, title: h}})} value={m.persons} onChange={(s)=>{setMessages(o=>[...o.filter(me=>me.id != m.id), {...m, persons: s}])}}/>
                  </div>
                  <div className="col-span-12 md:col-span-3 flex justify-between items-center gap-2 md:gap-1">
                    <p className="text-xs font-bold text-blue-800 dark:text-teal-600">حوزه:</p>
                    <MultiSelectComponent className={`rounded border px-2 py-1 dark:border-gray-500`} placeholder="حوزه..." topFilter={[...new Set(hozeh.map(h=>h.hozeh))]} closeOnClick={false} data={hozeh.map(h=>{return {value: h.id, title: h.hozeh + ' | ' + h.bakhsh}})} value={m.hozeh} onChange={(s)=>{setMessages(o=>[...o.filter(me=>me.id != m.id), {...m, hozeh: s}])}}/>
                  </div>
                  {/* <div className="col-span-12 md:col-span-2 flex justify-between items-center gap-2 md:gap-1">
                    <p className="text-xs font-bold text-blue-800 dark:text-teal-500 whitespace-pre">حوزه موضوعی:</p>
                    <MultiSelectComponent className={`rounded border px-2 py-1 dark:border-gray-500`} placeholder="حوزه موضوعی..." topFilter={[...new Set(hozehMozuei.map(h=>h.hozehMozuei))]} closeOnClick={false} data={hozehMozuei.map(h=>{return {value: h.id, title: h.hozehMozuei + ' | ' + h.moalefe}})} value={m.hozehMozuei} onChange={(s)=>{setMessages(o=>[...o.filter(me=>me.id != m.id), {...m, hozehMozuei: s}])}}/>
                  </div> */}
                  <hr className="col-span-12 md:col-span-12 border-1 gap-2 md:gap-1 dark:border-gray-500 my-1"/>
                  <div className="col-span-2 flex justify-start items-center gap-2">
                    <p className="text-[10px] text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{m.resaneh}</p>
                    <p className="text-[10px] text-start px-2 text-red-800 dark:text-teal-500 whitespace-pre font-bold">{`  ${moment(m.postDate).format('jYYYY-jMM-jDD')}  `}</p>
                    <button onClick={()=>{window.open(m.link,'_blank', 'rel=noopener noreferrer')}} className={`rounded font-bold text-blue-800 hover:text-blue-500 dark:text-amber-700 dark:hover:text-amber-500`}><FaLink /></button>
                    <div className={`flex gap-1 ${submitDelete?'w-5':'w-24'} transition-all duration-500 ease-linear`}>
                      <button onClick={()=>{setSubmitDelete(m.id)}} className={`rounded font-bold text-red-500 hover:text-red-300`}><MdDelete /></button>
                      {submitDelete===m.id && <button onClick={()=>{setSubmitDelete(null)}} className={`rounded font-bold px-1 text-blue-500 hover:bg-blue-500 hover:text-white`}>انصراف</button>}
                      {submitDelete===m.id && <button onClick={()=>{deleteHandler(m.id)}} className={`rounded font-bold px-1 text-red-500 hover:bg-red-500 hover:text-white`}>حذف</button>}
                      <button onClick={()=>{submitOneMessageHandler(m)}} className={`rounded font-bold text-green-800 hover:text-green-500 dark:text-teal-400 dark:hover:text-teal-500`}><FaSave /></button>
                    </div>
                    {(isOneMessageLoading === m.id) && <div className="flex justify-center items-center h-full">
                        <Loader size={16}/>
                    </div>}
                  </div>
                </div>
              )}
            </div>
          </div>
      </div>

      {/* Submit */}
      <div className="fixed bottom-2 end-0 px-2 py-1  flex flex-col gap-2 w-48 justify-center items-center bg-white dark:bg-gray-700 dark:border-gray-500 border-1 rounded-y-md rounded-s-md">
        {/* <p className={`text-sm font-bold`}>{`${selectedRows.length} پیام انتخاب شده است.`}</p> */}
        {!isLoading && <button className="border rounded bg-blue-500 hover:bg-blue-400 px-2 py-1 w-full" onClick={()=>submitHandler()}>ثبت</button>}
      </div>
    </div>
  );
};

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

export default Tagging;
