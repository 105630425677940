import { combineReducers } from 'redux';
import { authReducer } from './auth/authReducer';
import { themeReducer } from './theme/themeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  item: themeReducer,
});

export default rootReducer;
