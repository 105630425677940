import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Toast, RightPanel, Navbar } from '.';
import { BiMenu } from 'react-icons/bi';
import useOutsideClicked from '../hooks/useOutsideClick';

function Layout({ children, user, newToast, addToastHandler }) {
  const { t } = useTranslation();
  const location = useLocation();
  const [showRightPanel, setShowRightPanel] = useState(false);

  const pagesPanelRef = useRef(null);

  useOutsideClicked(pagesPanelRef, () => setShowRightPanel(false));

  document.title = location.pathname === '/' ? 'سامانه یوترند' : t(location.pathname.replace('/', ''));
  return (
    <>
      {location.pathname !== '/signin' && (
      <header className="bg-gray-100 dark:bg-gray-100 navbar w-full">
        <Navbar user={user} />
      </header>
      )}

      <main className={`md:absolute md:inset-0 md:flex md:justify-center md:items-start gap-1 max-w-full bg-gray-100 dark:bg-secondary-dark-bg dark:border-gray-600 md:transition-2 p-1 ${location.pathname !== '/signin' && 'mt-[2.25rem]'}`}>
        {location.pathname !== '/signin' && <div ref={pagesPanelRef} className={`md:h-full fixed z-[1000] bottom-1 md:bottom-0 start-1 md:start-0 md:relative flex flex-col justify-end md:justify-start items-center transition-width duration-300 ease-in-out ${showRightPanel ? 'h-auto border rounded-full bg-gray-400 ' : 'h-20'} md:h-full md:border-1 md:bg-white md:rounded-lg dark:border-gray-600 dark:bg-secondary-dark-bg ${!showRightPanel && 'dark:bg-transparent'}`}>
          <RightPanel onSelectItem={() => setShowRightPanel(false)} className={`${!showRightPanel && 'hidden md:inline-flex'}`} />
          <button onClick={()=>setShowRightPanel(!showRightPanel)} className={`flex justify-center items-center text-2xl text-white h-12 w-12 m-1 rounded-full bg-blue-500 md:hidden`}><BiMenu /></button>
        </div>}
        <div className="flex-grow md:h-full rounded-lg shadow-md border-1 bg-white dark:bg-secondary-dark-bg dark:border-gray-600 overflow-hidden p-1">
          {children}
        </div>
      </main>
      <Toast duration={2} positionX='end-0' positionY='bottom-5' newToast={newToast} addToastHandler={addToastHandler} />
    </>
  );
}

export default Layout;
