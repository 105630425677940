import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUser from "./hooks/useUser";
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { ThemeSettings, Layout } from "./components";
const LazyHoze = React.lazy(() => import('./pages/hozeh-management'))
import { ProtectedRoute, ChekerRoute } from "./customRoutes/PrivateRoute";
import {
  Home,
  Report,
  UploadMessages,
  UserManegement,
  SignIn,
  ChangePassword,
  Page404,
  Connection,
  TagManagement,
  HozehManagement,
  Setting
} from "./pages";
import "./App.css";
import useToken from "./hooks/useToken";
import axios from "axios";
import RotateLoader from 'react-spinners/RotateLoader'
import { jwtDecode } from "jwt-decode";
import HozehMozueiManagement from "./pages/hozeh-mozuei-management";
import to from 'await-to-js';

function SiteRoutes() {
  const [accessToken] = useToken();
  const [routes, setRoutes] = useState([
    {
        id: 10,
        name: "setting",
        title: "تنظیمات",
        icon: "config-icon",
        path: "/setting",
        access: "admin",
        showInPanel: true,
        order: "9",
        parentId: null
    },
    {
        id: 15,
        name: "labeling-lifeweb",
        title: "برچسب‌زنی لایف‌وب",
        icon: "lifeweb-icon",
        path: "/labeling-lifeweb",
        access: "costumer",
        showInPanel: true,
        order: "1",
        parentId: null
    },
    {
        id: 12,
        name: "labeling-dataak",
        title: "برچسب‌زنی دیتاک",
        icon: "dataak-icon",
        path: "/labeling-dataak",
        access: "costumer",
        showInPanel: true,
        order: "1",
        parentId: null
    },
    {
        id: 9,
        name: "user-management",
        title: "مدیریت کاربران",
        icon: null,
        path: "/user-management",
        access: "admin",
        showInPanel: false,
        order: "0",
        parentId: 10
    },
    {
        id: 1,
        name: "home",
        title: "صفحه اصلی",
        icon: "home-icon",
        path: "/",
        access: "costumer",
        showInPanel: true,
        order: "0",
        parentId: null
    },
    {
        id: 16,
        name: "question-management",
        title: "مدیریت سوالات",
        icon: "user-management-icon",
        path: "/question-management",
        access: "admin",
        showInPanel: true,
        order: "10",
        parentId: null
    },
    {
        id: 11,
        name: "change-password",
        title: "تغییر رمزعبور",
        icon: null,
        path: "/change-password",
        access: "costumer",
        showInPanel: false,
        order: "8",
        parentId: null
    },
    {
        id: 3,
        name: "upload-messages",
        title: "بارگذاری پیام",
        icon: "upload-icon",
        path: "/upload-messages",
        access: "costumer",
        showInPanel: true,
        order: "7",
        parentId: null
    },
    {
        id: 2,
        name: "report",
        title: "گزارش",
        icon: "report-icon",
        path: "/report",
        access: "costumer",
        showInPanel: true,
        order: "4",
        parentId: null
    },
    {
        id: 4,
        name: "hozeh-management",
        title: "مدیریت حوزه‌ها",
        icon: "hozeh-management-icon",
        path: "/hozeh-management",
        access: "admin",
        showInPanel: true,
        order: "5",
        parentId: null
    },
    {
        id: 5,
        name: "hozeh-mozuei-management",
        title: "مدیریت مولفه‌ها",
        icon: "hozeh-mozuei-management-icon",
        path: "/hozeh-mozuei-management",
        access: "admin",
        showInPanel: true,
        order: "6",
        parentId: null
    },
    {
        id: 14,
        name: "tagging",
        title: "ثبت حوزه",
        icon: "tag-icon",
        path: "/tagging",
        access: "costumer",
        showInPanel: true,
        order: "2",
        parentId: null
    },
    {
        id: 13,
        name: "subject-management",
        title: "مدیریت موضوعات",
        icon: "tag-icon",
        path: "/subject-management",
        access: "costumer",
        showInPanel: true,
        order: "11",
        parentId: null
    }
])
  const [isLoading, setIsLoading] = useState(true)
  const [newToast, setNewToast] = useState(null)

  // const getUserFromToken = async () => {
  //   if(accessToken){
  //     try {
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/checkToken`,{
  //           accessToken
  //         }
  //       );

  //       dispatch({
  //         type: "LOG_IN",
  //         user: response.data.user,
  //       })
  //     }
  //       catch (err) {
  //         console.log(err)
  //     }
  //   }
  //   setLoading(false)
  // }

  // useEffect(()=>{
  //   getUserFromToken();
  // },[])

  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  const dispatch = useDispatch();

  useEffect(()=>{
    // getRoutes()
  },[])

  const getRoutes = async () => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/config/get-all-routes`,
        {
          headers: { 
            authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': 'https://utrend.ir',
          }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setRoutes(response.data.results)
    setIsLoading(false)
  }

  useEffect(()=>{
    const token = localStorage.getItem('token')
    if (!token) {
      dispatch({
        type: "LOG_IN",
        user: null,
      })
      return
    }
    const user = jwtDecode(token)
    if (user) {
      dispatch({
        type: "LOG_IN",
        user: user,
      })
    }
  },[])

  const handleTheme = () => {
    dispatch({
      type: "SHOW_ITEM",
      payload:{value: "themeSetting", show: true}
    })
  }

return (
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          <div  className="bg-main-bg dark:bg-main-dark-bg w-full min-h-screen">
            <Routes>
              {routes.map((route, index) => 
                <Route
                  key={index}
                  path={route.path}
                  element={<ProtectedRoute access={route.access}>
                    <Layout newToast={newToast} addToastHandler={() => setNewToast(null)}>
                      <DynamicComponentLoader componentName={route.name} title={route.title} setNewToast={setNewToast} />
                    </Layout>
                  </ProtectedRoute>}
                />
              )}
              <Route path="/hozeh" element={
                <ProtectedRoute access={'admin'}>
                    <Layout newToast={newToast} addToastHandler={() => setNewToast(null)}>
                      <React.Suspense fallback='Loading...'>
                        <LazyHoze/>
                      </React.Suspense>
                    </Layout>
                  </ProtectedRoute>
                } />
              <Route path="/sign-in" element={<SignIn/>} />
              <Route path="*" element={<Layout><Page404/></Layout>} />
              {/* {((isLoading || routes.length === 0) && user) && <Route path="/" element={<Layout><Connection/></Layout>} />} */}
              {/* {!user && <Route path="/" element={<SignIn/>} />} */}
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    )
  }

  const DynamicComponentLoader = ({ componentName, title, setNewToast }) => {
    const [Component, setComponent] = useState(null);
  
    useEffect(() => {
      import(`./pages/${componentName}`)
        .then(module => setComponent(() => module.default))
        .catch(err => {
          console.error(`Error loading component ${componentName}:`, err);
          setComponent(null);
        });
      }, [componentName]);
  
    return Component ? <Component title={title?title:componentName} addToastHandler={(newToast) => {setNewToast(newToast)}} /> : <div>لطفاً صبر کنید...</div>;
  };

export default SiteRoutes;
