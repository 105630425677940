import React, { useRef, useState } from 'react';
import {useTranslation} from 'react-i18next'
import useOutsideClicked from '../hooks/useOutsideClick';

const HButton = ({onClick, icon, title=""})=>{
  // const [selectedValue, setSelectedValue] = useState()

  return (
    <button onClick={onClick} className="group px-3 py-3 cursor-pointer rounded relative min-w-[4rem] text-xl font-normal border-0 flex items-center justify-center bg-transparent text-blue-500 dark:text-teal-500 overflow-hidden transition-all duration-100">
      <span className="group-hover:w-full absolute left-0 h-full border-y w-3 border-l rounded-l border-blue-500 dark:border-teal-500 group-hover:bg-blue-500 dark:group-hover:bg-teal-500 transition-all duration-500"></span>
      <p className="group-hover:opacity-0 group-hover:translate-x-[-100%] text-lg absolute translate-x-0 transition-all duration-200">{icon}</p>
      <span className="group-hover:w-full absolute right-0 h-full border-y w-3 border-r rounded-r border-teal-500 transition-all duration-500"></span>
      <span className="group-hover:translate-x-0 group-hover:opacity-100 whitespace-pre text-xs absolute translate-x-full opacity-0 group-hover:text-white transition-all duration-200">{title}</span>
    </button>
  )
}

export default HButton;
