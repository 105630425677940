import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-jalaali';
import { MdDoneAll, MdDelete } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { HashLoader } from 'react-spinners';
import { FaRegSquare, FaRegCheckSquare, FaLink, FaSave, FaRegCopy } from 'react-icons/fa';
import { FiHelpCircle } from "react-icons/fi";
import copy from 'copy-to-clipboard';
import to from 'await-to-js';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useToken from '../hooks/useToken';
import { MultiSelectComponent, HDatePicker, SubmitComponent } from '../components';
import useOutsideClicked from '../hooks/useOutsideClick';

const Home = ({title}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const [pages, setPages] = useState([
    {path: '/labeling-dataak', title: 'ثبت برچسب دیتاک', desc: 'در این صفحه می‌توانید با کمک ابزارهای موجود در سامانه دیتاک برچسب را ثبت نمایید.'},
    {path: '/labeling-lifeweb', title: 'ثبت برچسب لایف وب', desc: 'در این صفحه می‌توانید با کمک ابزارهای موجود در سامانه لایف وب برچسب را ثبت نمایید.'},
    {path: '/tagging', title: 'ثبت حوزه', desc: 'در این صفحه می‌توانید حوزه، اشخاص و رویداد پیام‌های برچسب خورده را ثبت نمایید.'},
    {path: '/upload-messages', title: 'بارگذاری پیام', desc: 'در این صفحه می‌توانید پیام‌های برچسب خورده را بارگذاری نمایید.'},
  ])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  moment.loadPersian()
  return (
    <div className={`relative h-full box-border flex flex-wrap gap-6 justify-center items-center bg-slate-100 dark:bg-secondary-dark-bg`}>
      {pages.map((page, index) =>
      <div key={index} onClick={() => navigate(page.path)} className={`flex flex-col gap-1 justify-center items-center rounded-md shadow-xl bg-white cursor-pointer w-44 h-44 hover:bg-slate-300 dark:bg-gray-800 hover:dark:bg-gray-700`}>
        <p className="text-center text-lg font-bold">{page.title}</p>
        <p className="text-center text-xs">{page.desc}</p>
      </div>
    )}
    </div>
  );
};

export default Home;
