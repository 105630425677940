import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components';
import useKey from '../hooks/useKey';
import useToken from '../hooks/useToken';
import useUser from '../hooks/useUser';
import store from '../redux/store';
import {jwtDecode } from 'jwt-decode'
import {FaUser, FaKey, FaEye} from 'react-icons/fa'
import { PulseLoader } from 'react-spinners';
import to from 'await-to-js';

function SignIn({title}) {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user,
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const navigate = useNavigate();

  useEffect(()=>{
    if(user){
      return navigate('/');
    }
  },[])
  
  const [errorMessage, setErrorMessage] = useState("");
  const [usernameValue, setUsernameValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  
  const [, setToken] = useToken();
  const { t } = useTranslation();
  
  const onLogInClicked = async () => {
    setIsLoading(true)
    const [err, response] = await to(axios.post(
      `${process.env.REACT_APP_BASE_URL}/auth/global/login`,
      {
        email: usernameValue,
        password: passwordValue,
      }
    ))
    
    if(err){
      console.log(err)
      switch (err.response.status) {
        case 404:
          setErrorMessage(t('not_found_user'));
          break;        
        case 400:
          setErrorMessage(t('invalid_password'));
          break;
        case 500:
          setErrorMessage(t('can_not_connect_to_server'));
          break;
        default:
          setErrorMessage(t('can_not_connect_to_server'));
          break;
      }
      setIsLoading(false)
      return
    }

    const { token } = response.data.result;
    const decoded = jwtDecode(token);
    setToken(token);
    setParams(decoded)
    navigate('/')
    setIsLoading(false)
  }
  const setParams = (userD)=>
  {
    new Promise(function(resolve, reject) {
      dispatch({
        type: "LOG_IN",
        user: userD,
      });
      dispatch({
        type: "CHANGE_THEME",
        themeMode: userD?.config.theme || 'light',
      });
      dispatch({
        type: "CHANGE_DIRECTION",
        dir: userD?.config.direction || 'rtl',
      });
    }).then(()=>
    navigate(`/`)
  )
  };
  useKey(13,(e)=>{
    onLogInClicked();
  })
  return ( 
    <div className="absolute inset-0 z-20 flex justify-start items-center px-4 md:p-[15vh] bg-slate-500">
      <div className="w-[50vh] h-[50vh] md:h-[70vh] bg-slate-600 rounded-3xl flex flex-col justify-between items-center p-4 md:p-[5vh]">
        <div className="w-full flex flex-col gap-4">
          <div className="w-full flex justify-start items-center gap-2 rounded-lg px-4 bg-gray-400">
            <FaUser className='text-slate-400'/>
            <input onChange={(e) => setUsernameValue(e.target.value)} id="usernameInput" placeholder={t("username")} dir='ltr' type="text" className='flex-grow p-2 w-full text-center text-sm focus:border-none focus:outline-none bg-transparent'/>
          </div>
          <div className="w-full flex justify-start items-center gap-2 rounded-lg px-4 bg-gray-400">
            <FaKey className='text-slate-400'/>
            <input onChange={(e) => setPasswordValue(e.target.value)} id="passwordInput" placeholder={t("password")} dir='ltr' type="password" className='flex-grow p-2 w-full text-center text-sm focus:border-none focus:outline-none bg-transparent'/>
            <button><FaEye className='text-slate-400'/></button>
          </div>
          {errorMessage && <p className="w-full text-red-500 text-sm text-center">{errorMessage}</p>}
        </div>
        <div className="w-full flex flex-col gap-2">
          <button disabled={isLoading} onClick={onLogInClicked} className={`flex justify-center items-center gap-1 rounded-lg p-2 w-full text-white ${isLoading?'bg-slate-700':'bg-slate-800 hover:bg-slate-900'} `}>
            {t("signin")}
            {isLoading && <PulseLoader
              color={'#36d7b7'}
              loading={isLoading}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />}
            </button>
          <div className="flex justify-start items-center gap-1">
            <p className='text-sm whitespace-pre'>{t('forget_password')}</p>
            <button className='text-center text-sm hover:font-bold hover:text-red-900'>{t('recover_password')}</button>
          </div>
        </div>
      </div>
    </div>
   );
}

export default SignIn;
