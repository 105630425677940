import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import useToken from '../hooks/useToken';
import moment from 'moment-jalaali'
import { FaCheck, FaChevronCircleDown, FaChevronCircleLeft, FaEdit, FaMinus, FaPlus} from 'react-icons/fa';
import * as XLSX from 'xlsx'
import { BeatLoader, ClipLoader, HashLoader } from 'react-spinners';
import parser from 'logic-query-parser';
import to from 'await-to-js';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { CgClose } from 'react-icons/cg';

const HozehMozueiManagement = ({title}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [hozeha, setHozeha] = useState([]);
  const [newHozeh, setNewHozeh] = useState({hozehMozuei: '', moalefe: ''});
  const [editingHozeh, setEditingHozeh] = useState({id: null});
  const [deletingHozeh, setDeletingHozeh] = useState({id: null});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getHozeh()
  }, [])
  
  const getHozeh = async() => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh-mozuei`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    setHozeha(response.data.results)
    setIsLoading(false)
  }

  const handleUpdateHozeh = async(hozehMozuei) => {
    if(hozehMozuei.hozehMozuei.trim() === '') return
    setIsLoading(true)
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/message/update-web-hozeh-mozuei/${hozehMozuei.id}`,
        {
          hozehMozuei: hozehMozuei.hozehMozuei,
          moalefe: hozehMozuei.moalefe
        },
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    getHozeh()
  }

  const handleDeleteHozeh = async(hozehMozuei) => {
    setIsLoading(true)
    const [err, response] = await to(
      axios.delete(
        `${process.env.REACT_APP_BASE_URL}/message/delete-web-hozeh-mozuei/${hozehMozuei.id}`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )
    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }

    getHozeh()
  }
  
  const handleInsertHozeh = async(hozehMozuei)=>{
    if(hozehMozuei.hozehMozuei.trim() === '') return
    setIsLoading(true)
    console.log(hozehMozuei)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/insert-web-hozeh-mozuei`,
        {
          hozehMozuei: hozehMozuei.hozehMozuei,
          moalefe: hozehMozuei.moalefe
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      setIsLoading(false)
      return
    }
    getHozeh()
  }

  const inputRef = useRef(null)

  moment.loadPersian()
  return (
    <div className={`relative flex h-full rounded px-1 min-w-[10rem] text-sm`}>
      <div className={`w-full h-full flex flex-col gap-3 overflow-y-auto overflow-x-hidden scrollbar-thin rounded-b-md p-1 text-md border`}>
        {!isLoading && <div className="flex flex-col md:flex-row justify-start items-center gap-2 bg-blue-200 rounded p-1">
          <p className="whitespace-pre text-sm">حوزه موضوعی جدید</p>
          <input ref={inputRef} className='px-2 py-1 rounded border w-full md:flex-grow dark:bg-gray-700' placeholder="حوزه موضوعی" type="text" value={newHozeh.hozehMozuei} onChange={(e)=>{setNewHozeh({...newHozeh, hozehMozuei: e.target.value})}}/>
          <input className='px-2 py-1 rounded border w-full md:flex-grow dark:bg-gray-700' placeholder="مولفه" type="text" value={newHozeh.moalefe} onChange={(e)=>{setNewHozeh({...newHozeh, moalefe: e.target.value})}}/>
          <div className="flex justify-between items-center gap-2 w-full md:w-fit">
            <button onClick={()=>{setNewHozeh({hozehMozuei: '', moalefe: ''})}} className='border rounded  px-2 py-1 w-24 text-sm text-white font-bold bg-red-500 hover:bg-red-600'>بازنشانی</button>
            <button onClick={()=>{handleInsertHozeh(newHozeh); setNewHozeh({hozehMozuei: '', moalefe: ''})}} className='border rounded  px-2 py-1 w-24 text-sm text-white font-bold bg-green-500 hover:bg-green-600'>ثبت</button>
          </div>
        </div>}
        <hr/>
        {isLoading && <div className="w-full flex-grow flex justify-center items-center">
          <HashLoader
            color={'#36d7b7'}
            loading={isLoading}
            size={100}
          />
        </div>}
        {!isLoading && 
          hozeha.sort((a,b)=>a.id-b.id).map((hozehMozuei, idx)=>
          <div key={idx} className="grid grid-cols-12 md:flex justify-center items-center gap-2">
            <p className='col-span-1 w-5'>{hozehMozuei.id}</p>
            {<input className={`col-span-5 flex-grow px-2 py-1 rounded border dark:bg-gray-800 disabled:dark:bg-gray-500`} disabled={editingHozeh.id !== hozehMozuei.id} type="text" value={editingHozeh.id === hozehMozuei.id?editingHozeh.hozehMozuei:hozehMozuei.hozehMozuei} onChange={(e)=>{setEditingHozeh({...editingHozeh, hozehMozuei: e.target.value})}}/>}
            {<input className={`col-span-6 flex-grow px-2 py-1 rounded border dark:bg-gray-800 disabled:dark:bg-gray-500`} disabled={editingHozeh.id !== hozehMozuei.id} type="text" value={editingHozeh.id === hozehMozuei.id?editingHozeh.moalefe:hozehMozuei.moalefe} onChange={(e)=>{setEditingHozeh({...editingHozeh, moalefe: e.target.value})}}/>}
            {editingHozeh.id !== hozehMozuei.id && deletingHozeh.id !== hozehMozuei.id && <button onClick={()=>{setEditingHozeh(hozehMozuei)}} className='col-span-6 w-full border rounded px-2 py-1 md:w-24 text-sm text-white font-bold bg-yellow-500 hover:bg-yellow-600'>ویرایش</button>}
            {editingHozeh.id !== hozehMozuei.id && deletingHozeh.id !== hozehMozuei.id && <button onClick={()=>{setDeletingHozeh(hozehMozuei)}} className='col-span-6 w-full border rounded px-2 py-1 md:w-24 text-sm text-white font-bold bg-red-500 hover:bg-red-600'>حذف</button>}
            {(editingHozeh.id === hozehMozuei.id || deletingHozeh.id === hozehMozuei.id) && <button onClick={()=>{if(editingHozeh.id === hozehMozuei.id){handleUpdateHozeh(editingHozeh); setEditingHozeh({id:null})}else{handleDeleteHozeh(deletingHozeh); setDeletingHozeh({id:null})}}} className='col-span-6 w-full border rounded px-2 py-1 md:w-24 text-sm text-white font-bold bg-green-500 hover:bg-green-600'>تایید</button>}
            {(editingHozeh.id === hozehMozuei.id || deletingHozeh.id === hozehMozuei.id) && <button onClick={()=>{if(editingHozeh.id === hozehMozuei.id){setEditingHozeh({id:null})}else{setDeletingHozeh({id:null})}}} className='col-span-6 w-full border rounded px-2 py-1 md:w-24 text-sm text-white font-bold bg-red-500 hover:bg-red-600'>انصراف</button>}
          </div>
        )
        }
      </div>
    </div>
  );
};

export default HozehMozueiManagement;
