import React, { useEffect, useState } from 'react';
import { AiOutlineLogin } from 'react-icons/ai';
import { HiHome, HiOutlineHome } from 'react-icons/hi';
import { BsChatLeft } from 'react-icons/bs';
import { RiNotification3Line, RiTodoLine } from 'react-icons/ri';
import { MdDarkMode, MdFormatTextdirectionLToR, MdFormatTextdirectionRToL, MdKeyboardArrowDown, MdLightMode } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Notification, UserProfile } from '.';
import { useScroll } from '../hooks/useScroll';
// import io from 'socket.io-client';
import axios from 'axios';
import useToken from '../hooks/useToken';
import to from 'await-to-js';

// const socket = io.connect(`${process.env.REACT_APP_SOCKET_APP_BASE_URL}`);

const NavButton = ({ title, customFunc, icon, color, dotColor, className }) => (
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className={`relative text-2xl rounded-full p-1 hover:bg-light-gray ${className}`}
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-1 top-1"
      />
      {icon}
    </button>
);

const Navbar = () => {
  const { t } = useTranslation();
  const [accessToken, setToken] = useToken()

  const dispatch = useDispatch();
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  const navigate = useNavigate();

  const handleItemClick = async(clicked) => {
    let s = !theme[clicked];
    dispatch({
      type: "SHOW_ITEM",
      payload: {value: clicked, show: s},
    });
  };

  const [time, setTime] = useState()
  useEffect(() => {
    const d= new Date();
    setTime(`${d.getHours()}:${String(d.getMinutes()).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')}`)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      const d= new Date();
      setTime(`${d.getHours()}:${String(d.getMinutes()).padStart(2, '0')}:${String(d.getSeconds()).padStart(2, '0')}`)
    }, 1000);
  }, [time])

  const updateUserConfig = async(config) =>{
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/update-user-config/${user.id}`,{...config},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      setIsLoading(false)
      return
    }
  }

  const toggleTheme = async() => {
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/update-user-config/${user.id}`,{config: {theme: theme.themeMode === 'dark'?'light':'dark'}},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      return
    }

    let t;
    if (theme.themeMode == "dark") t = "light";
    else t = "dark";
    dispatch({
      type: "CHANGE_THEME",
      themeMode: t,
    });
  };
  
  const toggleDirection = async() => {
    const [err, response] = await to(
      axios.put(
        `${process.env.REACT_APP_BASE_URL}/user/update-user-config/${user.id}`,{config: {direction: theme.direction === 'ltr'?'rtl':'ltr'}},
        {
          headers: { Authorization: `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      return
    }

    let d;
    if (theme.direction == "ltr") d = "rtl";
    else d = "ltr";
    dispatch({
      type: "CHANGE_DIRECTION",
      dir: d,
    });
  };

  return (
    <div>
      <div
        className={`fixed top-0 max-w-full box-border z-50 p-1 start-0 w-full
        md:start-0 md:w-[calc(100%-0.5rem)] md:ms-1 duration-300 border-box flex justify-between items-center bg-white dark:bg-secondary-dark-bg`}
      >
        {/* <p className={`w-24 text-sm font-bold text-gray-400 tracking-widest ${theme.direction==='rtl'?'text-end':'text-start'}`}>{time}</p> */}
        <NavButton
          title={t("theme")}
          customFunc={() => toggleTheme()}
          color={theme.colorMode}
          icon={theme.themeMode == "dark" ?<MdLightMode/>:<MdDarkMode/>}
          className={`text-md`}
        />
        <NavButton
          title={t("direction")}
          customFunc={() => toggleDirection()}
          color={theme.colorMode}
          icon={theme.direction == "ltr" ?<MdFormatTextdirectionRToL/>:<MdFormatTextdirectionLToR/>}
          className={`text-md`}
        />
        {user ? (
            <div className="flex justify-end w-full">
              <div
                className="relative flex items-center gap-1 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                onClick={() => handleItemClick("userProfile")}
              >
                <img
                  className="rounded-full w-6 h-6"
                  src={user && `${user.avatar || 'images/avatars/avatar.jpg'}`}
                  alt="user-profile"
                />
                {/* <p>
                  <span className="text-gray-400 font-bold ml-1 text-xs">
                    {user && user.name}
                  </span>
                </p> */}
                <MdKeyboardArrowDown className="text-gray-400 text-xl" />
              </div>
              <NavButton
                title={t("user_home")}
                customFunc={() => navigate(`/`)}
                color={theme.colorMode}
                icon={<HiOutlineHome />}
                className={`text-md hidden md:block`}
              />
            </div>
        ) : (
          <div className="flex justify-between p-1 md:ml-6 md:mr-6 relative">
            <NavButton
              title={t("signin")}
              customFunc={() => navigate("/sign-in")}
              color={theme.colorMode}
              icon={<AiOutlineLogin />}
              className={`text-md`}
            />
            <NavButton
                title={t("home")}
                customFunc={() => navigate("/")}
                color={theme.colorMode}
                icon={<HiHome />}
                className={`text-md hidden md:block`}
              />
          </div>
        )}
      </div>
      {user && (
        <>
          {theme.userProfile && <UserProfile />}
        </>
      )}
    </div>
  );
};

export default Navbar;
