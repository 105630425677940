import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next'
import useKeyPress from '../../hooks/useKeyPress';
import useOutsideClicked from '../../hooks/useOutsideClick';
import ShowMoreText from "react-show-more-text";
import {GrView,  GrLike} from "react-icons/gr"
import {FaCommentAlt, FaEye, FaHeart, FaQuoteRight, FaReply, FaRetweet, FaTwitter, FaTelegramPlane, FaPlusSquare, FaPlus, FaMinus} from "react-icons/fa"
import MultiSelectComponent from '../multi-select-component';
import { CgClose } from 'react-icons/cg';
import { MdOutlineKeyboardReturn, MdOutlineSubdirectoryArrowLeft } from 'react-icons/md';

const TagPanel = ({tags, selectedTags=[], onSelect=()=>{}, onRemoveTag=()=>{}, prefix='برچسب', className=' ', textBetween, notChooseableTags=[]}) => {
  const [showTags, setShowTags] = useState(false)
  const [flatTags, setFlatTags] = useState([])
  const panelRef = useRef(null)

  const convertToFlat = (tagArray)=>{
    const tempFlatTags = []
    for (let i = 0; i < tagArray.length; i++) {
      const {childs, ...pt} = tagArray[i]
      tempFlatTags.push(pt)
      if (tagArray[i].childs.length > 0) {
        tempFlatTags.push(...convertToFlat(tagArray[i].childs))
      }
    }

    return tempFlatTags
  }

  useEffect(() => {
    setFlatTags(convertToFlat(tags))
  }, [])
  

  useOutsideClicked(panelRef, ()=>{
    setShowTags(false)
  })

  return (
    <div className={`relative flex ${className} rounded px-1 min-w-[10rem]`}>
      <div className={`w-full flex justify-start items-center gap-1 flex-wrap rounded`}>
        <p>{prefix}</p>
        {selectedTags.map((tag,idx)=>
        <div key={idx} className="flex justify-between items-center gap-1">
          <div className='flex justify-between items-center gap-2 text-xs rounded bg-blue-200 p-1'>
            <button onClick={()=>onRemoveTag(tag)} type="button"><CgClose/></button>
            <p className='whitespace-pre'>{flatTags.filter(t=>t.id===tag)[0]?.title}</p>
          </div>
          {selectedTags.length !== idx+1 && <p className='text-xs'>{textBetween}</p>}
        </div>
        )}
        <button className='self-end text-blue-400 hover:text-blue-500 text-2xl' onClick={()=>{setShowTags(true)}}><FaPlusSquare/></button>
      </div>
      {showTags && <div ref={panelRef} className={`w-full max-w-[30rem] absolute top-full start-0 z-[1000] shadow-lg rounded-b-md p-1 min-w-[15rem] text-md bg-white border`}>
        <TagElement  tags={tags} selectedTags={selectedTags} onRemoveTag={onRemoveTag} onSelect={onSelect} notChooseableTags={notChooseableTags}/>
      </div>}
    </div>
  );
};

const TagElement = ({tags, selectedTags, onRemoveTag, onSelect, notChooseableTags})=>{
  const [showChilds, setShowChilds] = useState([])

  return <div className={`flex flex-col gap-1 rounded-lg py-1 ps-1 text-sm w-full`}>
    {tags.map((tag, idx)=>
    <>
    <div key={idx} className={`flex justify-start items-center rounded-e-lg ${selectedTags.includes(tag.id)?'bg-blue-300 hover:bg-blue-400':'hover:bg-slate-100'} border rounded ${(notChooseableTags.includes(tag.id)) && 'bg-slate-100 cursor-not-allowed'}`}>
      {/* {tag.parentId !== null && <MdOutlineSubdirectoryArrowLeft className='h-full'/>} */}
      {tag.childs && tag.childs.length > 0 && !showChilds.includes(tag.id) && <button className='text-gray-500 hover:text-gray-600 my-2' onClick={()=>setShowChilds(o=>[...new Set([...o, tag.id])])} type="button"><FaPlus/></button>}
      {tag.childs && (tag.childs.length === 0 || showChilds.includes(tag.id)) && <button className='text-gray-500 hover:text-gray-600 my-2' onClick={()=>setShowChilds(o=>o.filter(t=>t!==tag.id))} type="button"><FaMinus/></button>}
      <button className={`w-full my-2 text-start ${(notChooseableTags.includes(tag.id)) && 'cursor-not-allowed'}`} onClick={()=>onSelect(tag.id)} type="button">{tag.title}</button>
    </div>
      {tag.childs.length > 0 && showChilds.includes(tag.id) && <div className="flex justify-start items-center gap-1 ms-4">
        <TagElement tags={tag.childs} selectedTags={selectedTags} onRemoveTag={onRemoveTag} onSelect={onSelect} notChooseableTags={notChooseableTags}/>
      </div>}
    </>
    )}
  </div>
}

export default TagPanel;
