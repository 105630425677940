import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollDirection } from 'react-use-scroll-direction'
import { MultiSelectComponent, DropDown } from '../components';
import useToken from '../hooks/useToken';
import { Line } from 'react-chartjs-2';
import { FaPlus, FaMinus } from "react-icons/fa";

import to from 'await-to-js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import RoutesBox from './config/routes-box';
import ConfigBox from './config/config-box';

const Setting = ({title, addToastHandler= () => {}}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();

  const [accessToken] = useToken();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className={`flex flex-col gap-2 max-w-full box-border bg-white dark:bg-secondary-dark-bg text-sm overflow-y-auto p-2`}>
      <RoutesBox addToastHandler={(t) => addToastHandler(t)}/>
      <ConfigBox addToastHandler={(t) => {console.log(t);addToastHandler(t)}} />
    </div>
  );
};

export default Setting;
