import { Navigate, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../hooks/useUser";
import {SignIn, Decative, Access} from '../pages'
import { jwtDecode } from "jwt-decode";
import { Layout } from "../components";

export const ProtectedRoute = ({ children, access='admin' }) => {
  const token = localStorage.getItem('token')
  if (!token) {
    return <Navigate to="/sign-in" />;
  }
  const user = jwtDecode(token)
  
  if (!user) {
    localStorage.removeItem('token')
    return <Navigate to="/sign-in" />;
    // return <SignIn />;
  }
  if(new Date().getTime() > user.exp * 1000){
    localStorage.removeItem('token')
    return <Navigate to="/sign-in" />;
  }
  if(!user.active) return <Decative/>

  let accessList = []
  switch (access) {
    case 'admin':
      accessList = ['admin']
      break;
    case 'costumer':
      accessList = ['admin', 'costumer']
      break;
    case 'all':
      accessList = [user.role.name]
      break;
    default:
      accessList = []
      break;
  }
  if(!accessList.includes(user.role.name)) {
    return (<Layout>
        <Access/>
      </Layout>)
  }

  return children;
};
