const fa = {
  username: 'نام کاربری',
  password: 'رمز عبور',
  confirmation_password: 'تکرار رمز عبور',
  signin: "ورود",
  sign_out: 'خروج',
  did_you_forget_your_password: "رمز عبور خود را فراموش کرده‌اید؟",
  don_t_have_an_account: "حساب کاربری ندارید ",
  please_register: 'لطفاً ثبت نام کنید.',
  condition_and_terms: 'من با قوانین و مقرارت سایت موافق هستم.',
  signup: 'ثبت نام',
  invalid_password: 'رمز عبور اشتباه است',
  not_found_user: 'نام کاربری وجود ندارد',
  forget_password: 'رمز عبور خود را فراموش کرده‌ام.',
  recover_password: 'بازیابی رمز عبور',
  did_you_have_an_account: 'حساب کاربری دارید؟ ',
  signin_from_this_link: 'از اینجا وارد شوید.',
  settings: 'تنظیمات',
  theme_option: 'تنظیمات پوسته',
  light: 'قالب روشن',
  dark: 'قالب تیره',
  theme_colors: 'رنگ‌ پوسته',
  user_profile: 'منوی کاربری',
  usernam_or_password_is_incorrect: 'نام کاربری و رمز عبور اشتباه است!',
  can_not_connect_to_server: 'برقراری ارتباط با سرور امکان‌پذیر نیست.',
  usernam_duplacate: 'این نام کاربری قبلاً ثبت شده است.!',
  token_not_valid: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  can_not_register_user_now: 'در حال حاضر امکان ثبت کاربر فراهم نیست.',
  enterd_code_is_not_valid: 'کد وارد شده معتبر نیست.',
  not_allowed_to_update_that_user: 'شما اجازه ویرایش مشخصات کاربر را ندارید.',
  unable_to_verify_token: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  no_authorization_header_send: 'نشست شما غیر معتبر است. لطفاً مجدد وارد شوید.',
  menu: 'منو',
  cart: 'سبد خرید',
  chat: 'تیکت',
  notification: 'اطلاع‌رسانی',
  hi: 'سلام، ',
  site_title: 'شاپی',
  dashboard: 'داشبورد',
  ecommerce: 'فروشگاه',
  pages: 'صفحات',
  orders: 'سفارشات',
  employees: 'پرسنل',
  users: 'کاربران',
  apps: 'ابزارها',
  calendar: 'تقویم',
  kanban: 'مدیریت پروژه',
  editor: 'ویرایشگر',
  color_picker: 'انتخاب رنگ',
  charts: 'نمودارها',
  line: 'نمودار خطی',
  area: 'نمودار سطحی',
  bar: 'نمودار میله‌ای',
  pie: 'نمودار دایراه‌ای',
  financial: 'نمودار تجاری',
  color_mapping: 'نمودار هیت مپ',
  pyramid: 'نمودار هرمی',
  stacked: 'نمودار درصدی',
  lang_option: 'زبان',
  ltr: 'چپ چین',
  rtl: 'راست چین',
  my_profile: 'پروفایل من',
  my_posts: 'پست‌ها',
  posts_created_by_you: 'پست‌های ایجاد شده توسط شما',
  profile_of: 'صفحه پروفایل',
  account_setting: 'تنظیمات حساب کاربری',
  my_inbox: 'صندوق پیام',
  messages_and_emails: 'پیام‌ها و ایمیل‌ها',
  my_tasks: 'تسک‌های من',
  todo_and_daily_tasks: 'تسک‌های روزانه',
  notifications: 'تیکت‌ها',
  see_all_notifications: 'مشاهده همه تیک‌ها',
  new: 'جدید',
  messages: 'پیام‌ها',
  see_all_messages: 'مشاهده همه پیام‌ها',
  shopping_cart: 'سبد خرید',
  count: 'تعداد',
  sub_total: 'مجموع این فاکتور',
  total: 'مجموع',
  place_order: 'ثبت خرید',
  currency_sign: 'تومان',
  click_to_change_language: 'برای تغییر زبان کلیک کنید',
  write_message: 'متن پیام...',
  p_avatar: 'تصویر پروفایل',
  p_name: 'نام',
  p_alias: 'نام نمایشی',
  p_mobile: 'شماره همراه',
  p_email: 'ایمیل',
  p_country: 'کشور',
  p_city: 'شهر',
  p_address: 'آدرس',
  p_bio: 'بیوگرافی',
  verified:'تائید شده',
  submit: 'ثبت',
  verification_code:'تائید شده',
  verify_email: 'تائید ایمیل',
  verify_mobile: 'تائید موبایل',
  save_changes: 'ذخیره تغییرات',
  reset: 'بازنشانی تغییرات',
  verification_code_sent_to_your_mobile:'کد تایید به شماره همراه شما ارسال شد. لطفاً کد ارسال شده را در باکس زیر وارد نمایید.',
  verification_code_sent_to_your_email:'کد تایید به ایمیل شما ارسال شد. لطفاً کد ارسال شده را در باکس زیر وارد نمایید.',
  cancel: 'انصراف',
  changes_saved_successfully: 'اطلاعات با موفقیت ثبت شد!',
  rights_reserved: '© 2022 کلیه حقوق برای سایت Shoppy.com محفوظ می‌باشد.',
  usernam_or_password_is_incorrect: 'نام کاربری یا رمزعبور اشتباه است.',
  is_loading: 'در حال دریافت اطلاعات ...',
  posts_per_page: 'تعداد پست در هر صفحه:',
  edit: 'ویرایش',
  delete: 'حذف',
  all_categories: 'همه پست‌ها',
  update: 'بروزسانی',
  category_title: 'عنوان دسته',
  add_category: 'اضافه‌کردن دسته جدید',
  delete_item: 'آیا از حذف این مورد مطمئن هستید؟',
  show_user_posts: 'نمایش پست‌های کاربر',
  title:'عنوان',
  description:'متن پست',
  tags:'برچسبها',
  permitted_users:'کاربران مجاز',
  explore:'نمایش در اکسپلور',
  supported_files: 'فایل‌های قابل بارگذاری',
  select_file: 'انتخاب فایل',
  new_post: 'پست جدید',
  categories_management: 'مدیریت دسته‌ها',
  posts_management: 'مدیریت پست‌ها',
  the_best: 'برترین‌ها',
  most_popular_posts: 'محبوب‌ترین پست‌ها',
  most_comment_posts: 'پست‌ها با بیشترین واکنش',
  saved_posts: 'پست‌های نشان شده',
  name:'نام',
  alias:'شهرت',
  email:'آدرس ایمیل',
  mobile:'شماره موبایل',
  bio:'بیوگرافی',
  comment:'کامنت',
  comments_count: 'تعداد کامنت',
  like: 'پسند',
  likes_count:'تعداد پسند',
  date: 'تاریخ',
  only_personal_page: 'عمومی',
  on_wall_page: 'دیوار',
}

export default fa;