import React, { useEffect, useRef, useState } from 'react';
import {useTranslation} from 'react-i18next'
import useOutsideClicked from '../hooks/useOutsideClick';

const MultiSelectComponent = ({data, value=[], topFilter=[], onChange, addItemHandler, className=' ', allowToAddItem=false, placeholder='فیلتر ...', fontSize='text-xs', closeOnClick=true, autoHideItemBox=true, fill='currentColor', start=false, fields={title: 'title', value: 'value'}, itemsCount=10})=>{
  const [filterValue, setFilterValue] = useState('')
  const [showItemBox, setShowItemBox] = useState(false)
  const filterInput = useRef();
  useOutsideClicked(filterInput,()=>setShowItemBox(false))
  const changeHandler = (d)=>{
    value.includes(d[fields.value])?onChange(value.filter(ele=>ele !== d[fields.value])):onChange([...value,d[fields.value]]);
    setShowItemBox(!closeOnClick);
  }

  return (
    <div ref={filterInput} className={`${autoHideItemBox && 'relative'} w-full ${className}`}>
      <div className="flex flex-wrap flex-grow justify-start w-full items-center gap-1">
        {value.map((v,idx)=><p key={idx} onClick={()=>{onChange(value.filter(ele=>ele !== v))}} className={`${fontSize} border rounded whitespace-pre-wrap py-1 px-2 bg-green-300 cursor-pointer dark:text-gray-700`}>X {data.filter(d=>d[fields.value]===v)[0][fields.title]}</p>)}
        <input style={{minWidth:'2rem'}} className={`rounded py-1 flex-grow bg-transparent focus:outline-none ${fontSize?fontSize:'text-xs'}`} type="text" onFocusCapture={()=>setShowItemBox(true)} placeholder={placeholder} value={filterValue} onChange={(e)=>setFilterValue(e.target.value)}/>
        {allowToAddItem && <button onClick={()=>addItemHandler(filterValue)}>+</button>}
      </div>
      <div className={`${autoHideItemBox && 'absolute z-50 top-full min-w-[200px] bg-white max-h-97 max-w-[50rem] dark:bg-secondary-dark-bg'} ${autoHideItemBox && !showItemBox && 'hidden'} flex flex-col gap-1 justify-start items-start border p-2 rounded-sm ${start?'start-0':'end-0'}`}>
        <div className="sticky top-0 flex justify-between items-center gap-1">
          {topFilter.map((m,idx)=>
          <button key={idx} onClick={()=>{setFilterValue(m)}} className="border px-1 rounded text-xs bg-blue-200 hover:bg-blue-300 dark:bg-teal-500 dark:hover:bg-teal-800">{m}</button>
          )}
        </div>
        <div className="w-full overflow-y-auto flex flex-col gap-1 justify-start items-start">
          {data.filter(d=>d[fields.title].indexOf(filterValue) > -1 && !value.includes(d[fields.value])).map((d, idx)=><div key={idx} onClick={()=>{changeHandler(d); setFilterValue('')}} className="cursor-pointer justify-start items-center gap-1 hover:text-gray-400">
            <p className={`${fontSize} border rounded py-1 px-2 ${value.includes(d[fields.value]) && 'bg-gray-300'}`}>{d[fields.title]}</p>
            </div>
          )}

        </div>
        {/* {data.filter(d=>d[fields.title].indexOf(filterValue) > -1 && !value.includes(d[fields.value])).length > itemsCount && <p className='text-xs my-2 text-red-400 dark:text-red-300'>...</p>} */}
        {data.filter(d=>d[fields.title].indexOf(filterValue) > -1 && !value.includes(d[fields.value])).length === 0 && <p className='text-xs my-2 text-red-400 dark:text-red-300'>آیتمی وجود ندارد...</p>}
      </div>
  </div>
  )
}

export default MultiSelectComponent;
