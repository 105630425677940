import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
// import {writeFile} from 'fs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-jalaali';
import { MdDoneAll, MdDelete } from 'react-icons/md';
import * as XLSX from 'xlsx';
import { HashLoader } from 'react-spinners';
import { FaRegSquare, FaLink, FaSave, FaRegCopy, FaFileExcel, FaCopy } from 'react-icons/fa';
import { FiHelpCircle } from "react-icons/fi";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import copy from 'copy-to-clipboard';
import to from 'await-to-js';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useToken from '../hooks/useToken';
import { MultiSelectComponent, HDatePicker, SubmitComponent, BarChart, DropDown } from '../components';
import useOutsideClicked from '../hooks/useOutsideClick';
import {Chart as ChartJS} from 'chart.js/auto'
import { Bar } from 'react-chartjs-2';
import ReactECharts from 'echarts-for-react';

const createChartOption = ({seriesData,xAxiesData, name="",color='#91cc75'}) =>{
  return {
    title: {
      text: `${name}`,
    },
    tooltip: {
      trigger: 'axis'
    },
    // legend: {},
    toolbox: {
      show: true,
      feature: {
        // dataZoom: {
        //   yAxisIndex: 'none'
        // },
        // dataView: { readOnly: false },
        // magicType: { type: ['bar'] },
        restore: {},
        saveAsImage: {}
      }
    },
    darkMide: 'auto',
    colorBy: 'series',
    dataGroupId: '',
    color: [color],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxiesData
    },
    textStyle: {
      fontFamily:'tahoma',
      fontSize: 10,
      fontWeight: 'normal',
    },
    yAxis: {
    },
    series: {
      type: 'bar',
      id: 'sales',
      data: seriesData,
      label:{
        show: true,
        position: 'top',
      },
      endLabel:{
        show:true,
        distance: 8
      },
      universalTransition: {
        enabled: true,
        divideShape: 'clone'
      }
    },
  };
}

const Report = ({title}) => {
  const { user, theme } = useSelector((state) => ({
    theme: state.item,
    user: state.auth.user
  }));

  useEffect(() => {
    document.title = title;
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accessToken] = useToken();
  const [fromDate, setFromDate] = useState(moment().startOf('jWeek').add(-2, 'day').add(-1,'week').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment().startOf('jWeek').add(-3, 'day').format('YYYY-MM-DD'));
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchPanel, setShowSearchPanel] = useState(true);
  const [showRoydadPanel, setShowRoydadPanel] = useState(false);
  const [filters, setFilters] = useState('all');
  const [hozeh, setHozeh] = useState([]);
  const [hozehMozuei, setHozehMozuei] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [computeData, setComputeData] = useState({roydad: [], hozeh: [], bakhsh: [], hozehAstan: [], ashkhas: [], resaneh: []});
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject , setSelectedSubject ] = useState(null);
  const [statistiscs , setStatistiscs ] = useState({
    instagram: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    twitter: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    telegram: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    rubika: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    bale: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    eitaa: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    news: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    },
    newspaper: {
      messageCount: 0,
      userCount: 0,
      viewSum: 0,
      top10User: []
    }});

  useEffect(() => {
    getHozeh();
    getHozehMozuei();
    // getMessageHansler();
    getSubjects();
  }, [])  
  
  // useEffect(() => {
  //   getMessageHansler();
  // }, [hozeh, hozehMozuei])

  const saveExcel = (data)=>{
    if(data === null){
      return
    }
    const workbook = XLSX.utils.book_new();
    if(!workbook.Workbook) workbook.Workbook = {};
    if(!workbook.Workbook.Views) workbook.Workbook.Views = [];
    if(!workbook.Workbook.Views[0]) workbook.Workbook.Views[0] = {};
    workbook.Workbook.Views[0].RTL = true;
    if(typeof data === 'object' && !Array.isArray(data)){
      const keys = Object.keys(data)
      for (let i = 0; i < Object.keys(data).length; i++) {
        const key = Object.keys(data)[i];
        console.log(key)
        const worksheet1 = XLSX.utils.json_to_sheet(data[key]);
        XLSX.utils.book_append_sheet(workbook, worksheet1, `${key}`)
      }
    }
    else{
      const worksheet1 = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet1, `data`)
    }

    //Generate buffer
    XLSX.write(workbook, {bookType: 'xlsx', type: 'buffer'});
    //Binary string
    XLSX.write(workbook, {bookType: 'xlsx', type: 'binary'});

    const jdate = moment().locale('fa').format('jDD jMMMM jYYYY hhmmss')
    XLSX.writeFile(workbook, `${jdate}.xlsx`)
  }

  const getHozeh = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setHozeh(response.data.results)
  }

  const getHozehMozuei = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/message/get-all-web-hozeh-mozuei`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setHozehMozuei(response.data.results)
  }

  const getSubjects = async() => {
    const [err, response] = await to(
      axios.get(
        `${process.env.REACT_APP_BASE_URL}/subject/subject/get-subjects`,
        {
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setSubjects(response.data.results)
  }

  const getMessageHansler = async() => {
    if(hozeh.length === 0 || hozehMozuei.length === 0) return;
    setIsLoading(true)
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/get-web-messages-with-date`,
        {
          fromDate: fromDate,
          toDate: toDate,
          // limit: limit,
          // offset: offset
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }
    setIsLoading(false);
    setMessages(response.data.results);
  }

  const getStatHandler = async() => {
    const [err, response] = await to(
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/message/get-statistics`,
        {
          subjectId: selectedSubject,
        },{
          headers: { 'authorization': `Bearer ${accessToken}` }
        }
      )
    )

    if(err){
      console.log(err)
      return
    }

    setStatistiscs(response.data.result)
  }

  useEffect(() => {
    const filterdMessages = messages.filter((m) => m.hozeh.some(r=> hozeh.filter((h) => {if(filters === 'all'){return true}else{return h.hozeh === filters}}).map((h) => h.id).indexOf(r) !== -1));
    const roydadList = filterdMessages.map((m) => m.roydad);
    const hozehList = messages.map((m) => m.hozeh.map((h) => hozeh.filter((hh) => hh.id === h)[0].hozeh)).flat();
    const bakhshList = filterdMessages.map((m) => m.hozeh.map((h) => hozeh.filter((hh) => hh.id === h)[0].bakhsh)).flat();
    const hozehAstannewsList = messages.filter((m) => m.resaneh === 'آستان نیوز').map((m) => m.hozeh.map((h) => hozeh.filter((hh) => hh.id === h)[0].hozeh)).flat();
    const astannewsTrendList = filterdMessages.filter((m) => m.resaneh === 'آستان نیوز').map((m) => moment(m.postDate, 'YYYY-MM-DD HH:mm:ss').add('3:30', 'hh').format('jYYYY/jMM/jDD'));
    const trendList = filterdMessages.map((m) => moment(m.postDate, 'YYYY-MM-DD HH:mm:ss').add('3:30', 'hh').format('jYYYY/jMM/jDD'));
    const personsList = filterdMessages.map((m) => m.persons).flat();
    const resanehList = filterdMessages.map((m) => m.resaneh);

    const computeData = {
      roydad: countOccurrences(roydadList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value), 
      hozeh: countOccurrences(hozehList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value), 
      bakhsh: countOccurrences(bakhshList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value), 
      hozehAstannews: countOccurrences(hozehAstannewsList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value), 
      ashkhas: countOccurrences(personsList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value), 
      resaneh: countOccurrences(resanehList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => b.value - a.value),
      astannewsTrend: countOccurrences(astannewsTrendList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => moment(a.name, 'jYYYY/jMM/jDD')-moment(b.name, 'jYYYY/jMM/jDD')),
      trend: countOccurrences(trendList).map((d) => {return { name: d[0], value: d[1] }}).sort((a, b) => moment(a.name, 'jYYYY/jMM/jDD')-moment(b.name, 'jYYYY/jMM/jDD'))
    }

    const hozehChart = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontFamily: 'ISFontFaNum',
          fontSize: 10
        },
      },
      legend: {
        top: 'bottom',
        show: false
      },
      textStyle: {
        fontFamily: 'ISFontFaNum'
      },
      series: [
        {
          // name: 'فراوانی',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          // roseType: 'area',
          padAngle: 3,
          itemStyle: {
            borderRadius: 8
          },

          label:{
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },

          data: computeData.hozeh
        }
      ]
    };

    const hozehBarChart = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontFamily: 'ISFontFaNum',
          fontSize: 10
        },
      },
      xAxis: {
        type: 'category',
        data: computeData.hozeh.filter((d, index) => index <=20).map((r) => r.name),
        axisLabel:{
          interval: 0,
          // rotate: -10,
          hideOverlap: true,
          overflow: 'break',
          fontSize: 10
        }
      },
      yAxis: {
        type: 'value'
      },
      textStyle: {
        fontFamily: 'ISFontFaNum',
        fontSize: 16
      },
      series: [
        {
          showBackground: true,
          radius: ['20%', '20%'],
          itemStyle: {
            borderRadius: [50,50,0,0]
          },
          label: {
            show: true,
            position: 'outside',
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },
          data: computeData.hozeh.filter((d, index) => index <=20),
          type: 'bar'
        }
      ]
    };

    /** @type EchartsOption **/
    const personsChart = {
      
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'filter',
          textStyle: {
            fontFamily: 'ISFontFaNum',
            fontSize: 10,
          },
          height: 10
        },
      ],
      title: {
        text: 'شخصیت‌ها',
        left: "center",
        top: "top",
        textStyle: {
          fontFamily: 'ISFontFaNum',
          fontSize: 14,
        },
        textAlign: 'center',
      },
      tooltip: {
        trigger: 'item'
      },
      xAxis: {
        type: 'category',
        data: computeData.ashkhas.map((a) => a.name),
        axisLabel:{
          interval: 0,
          // rotate: -10,
          hideOverlap: true,
          overflow: 'break',
          fontSize: 10
        }
      },
      yAxis: {
        type: 'value'
      },
      textStyle: {
        fontFamily: 'ISFontFaNum',
        fontSize: 16
      },
      series: [
        {
          showBackground: true,
          radius: ['20%', '20%'],
          itemStyle: {
            borderRadius: [50,50,0,0]
          },
          // labelLine: {
          //   show: true
          // },
          label: {
            show: true,
            position: 'outside',
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },
          data: computeData.ashkhas,
          type: 'bar'
        }
      ]
    };

    const roydadChart = {
      dataZoom: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter',
            textStyle: {
              fontFamily: 'ISFontFaNum',
              fontSize: 10,
            },
            height: 10
        },
      ],
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontFamily: 'ISFontFaNum',
          fontSize: 10
        },
      },
      xAxis: {
        type: 'category',
        data: computeData.roydad.filter((d, index) => index <=20).map((r) => r.name),
        axisLabel:{
          interval: 0,
          // rotate: -10,
          hideOverlap: true,
          overflow: 'break',
          fontSize: 10
        }
      },
      yAxis: {
        type: 'value'
      },
      textStyle: {
        fontFamily: 'ISFontFaNum',
        fontSize: 16
      },
      series: [
        {
          showBackground: true,
          radius: ['20%', '20%'],
          itemStyle: {
            borderRadius: [50,50,0,0]
          },
          label: {
            show: true,
            position: 'outside',
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },
          data: computeData.roydad.filter((d, index) => index <=20),
          type: 'bar'
        }
      ]
    };

    const astannewsTrendChart = {
      dataZoom: [
        {
            id: 'dataZoomX',
            type: 'slider',
            xAxisIndex: [0],
            filterMode: 'filter',
            textStyle: {
              fontFamily: 'ISFontFaNum',
              fontSize: 10,
            },
            height: 10
        },
      ],
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontFamily: 'ISFontFaNum',
          fontSize: 10
        },
      },
      xAxis: {
        type: 'category',
        data: computeData.trend.filter((d, index) => index <=20).map((d) => d.name),
        axisLabel:{
          interval: 0,
          // rotate: -10,
          hideOverlap: true,
          overflow: 'break',
          fontSize: 10
        }
      },
      yAxis: {
        type: 'value'
      },
      textStyle: {
        fontFamily: 'ISFontFaNum',
        fontSize: 16
      },
      series: [
        {
          showBackground: true,
          markPoint: {
            data: [{
              type: "max",
              name: "Max"
            }, {
              type: "min",
              name: "Min"
            }],
            itemStyle: {
              color: "#60a871",
            },
            label: {
              fontFamily: 'ISFontFaNum',
              fontSize: 11,
              fontWeight: 'bold',
              color: theme.themeMode === 'dark'?'#FFFFFF':'#c46067'
            },
          },
          markLine: {
            data: [{
              symbol: 'none',
              type: "average",
              name: "متوسط",
              label: {
                formatter: '{b} {c}',
                color: theme.themeMode === 'dark'?'#FFFFFF':'#7d7576'
              },
              lineStyle: {
                color: '#7d7576'
              },
            },
            [{
              type: 'max',
              name: "بیشینه",
              symbol: 'none',
              x: '10%',
              label: {
                formatter: '{b}',
                color: theme.themeMode === 'dark'?'#FFFFFF':'#60a871'
              },
              lineStyle: {
                color: '#60a871'
              },
            }, {
              yAxis: 'max',
              x: '90%'
            }]
            ],
            lineStyle: {
              opacity: 0.8,
              cap: 'round',
              join: 'miter',
              miterLimit: 50
            },
            label: {
              formatter: '{b} {c}',
              fontFamily: 'ISFontFaNum',
              fontSize: 11,
              fontWeight: 'bold',
              position: 'insideStartTop',
              color: theme.themeMode === 'dark'?'#FFFFFF':'#gfghgg'
            },
          },
          label: {
            show: true,
            position: 'outside',
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },
          data: computeData.trend.filter((d, index) => index <=20).map((d) => d.value),
          type: 'line',
          smooth: true
        },
        {
          showBackground: true,
          markLine: {
            data: [
            {
              symbol: "none",
              type: "min",
              name: "کمینه",
              label: {
                formatter: '{b}',
                color: theme.themeMode === 'dark'?'#FFFFFF':'#c46067'
              },
              lineStyle: {
                color: '#c46067'
              },
            }
            ],
            lineStyle: {
              opacity: 0.8,
              cap: 'round',
              join: 'miter',
              miterLimit: 50
            },
            label: {
              formatter: '{b} {c}',
              fontFamily: 'ISFontFaNum',
              fontSize: 11,
              color: '#gfghgg',
              fontWeight: 'bold',
              position: 'insideStartTop',
            },
          },
          showBackground: true,
          label: {
            show: true,
            position: 'outside',
            color: theme.themeMode === 'dark'?'#FFFFFF':'#666666'
          },
          data: computeData.trend.filter((d, index) => index <=20).map((t) => {
            if(computeData.astannewsTrend.filter((at) => at.name === t.name).length === 0){
              return {value: 0, name: t.name}
            }else{
              return computeData.astannewsTrend.filter((at) => at.name === t.name)[0]
            }} ).map((d) => d.value),
          type: 'line',
          smooth: true
        }
      ]
    };

    setComputeData(computeData);
    setChartData([{id: 1, option: roydadChart, className: 'col-span-12 md:col-span-6', fullScreen: false}, 
      {id: 2, option: personsChart, className: 'col-span-12 md:col-span-6', fullScreen: false}, 
      {id: 3, option: hozehChart, className: 'col-span-12 md:col-span-3', fullScreen: false}, 
      {id: 4, option: hozehBarChart, className: 'col-span-12 md:col-span-3', fullScreen: false}, 
      {id: 5, option: astannewsTrendChart, className: 'col-span-12 md:col-span-6', fullScreen: false}])
  }, [messages, filters])

  const onEvents = {
    click: (params) => {
      // console.log('Chart clicked', params);
      if(params.seriesType === "pie")
        setFilters(filters===params.name?'all':params.name)
    }
  }
  moment.loadPersian()
  return (
    <div className={`relative h-full box-border flex flex-col gap-1 justify-start items-stretch overflow-y-auto ${showRoydadPanel && 'overflow-y-hidden'}`}>
              <div className="col-span-12">
          <div className="flex flex-wrap justify-start items-center gap-2 py-1">
            <p className={`text-sm`}>سوژه</p>
            <DropDown className="flex-grow" value={selectedSubject} onChange={(s) => setSelectedSubject(s)} data={subjects} fields={{value: 'id', title: 'title'}} />
            <button type="button" onClick={()=>getStatHandler()} className="text-xs text-white font-bold rounded px-2 py-1 bg-green-400 hover:bg-green-500 dark:bg-teal-600 dark:hover:bg-teal-400">دریافت اطلاعات</button>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-8 gap-2 text-xs">
            {Object.entries(statistiscs).map((stat, index) =>
              <div key={index} className="col-span-1 p-1 border rounded dark:border-gray-500">
                <div className="flex justify-between items-center gap-2">
                  <p className="border-b dark:border-gray-500 my-1">{stat[0]}:</p>
                  <button className="text-blue-300 hover:text-blue-500 dark:text-teal-300 hover:dark:text-teal-500" onClick={() => copy( stat[1].userCount + '\n' + stat[1].messageCount + '\n' +stat[1].viewSum)}><FaCopy/></button>
                </div>
                <div className="flex justify-between items-center gap-2">
                  
                  <p>تعداد کاربر:</p>
                  <p>{stat[1].userCount}</p>
                </div>
                <div className="flex justify-between items-center gap-2">
                  <p>تعداد پیام:</p>
                  <p>{stat[1].messageCount}</p>
                </div>
                <div className="flex justify-between items-center gap-2">
                  <p>مجموع بازدید:</p>
                  <p>{stat[1].viewSum}</p>
                </div>
                <hr className="dark:border-gray-600 my-1" />
                  <div className="flex flex-col w-full">
                  <p className="py-1">فعالترین:</p>
                  {stat[1].top10User.map(mUser => 
                    <div className="flex justify-between items-center ps-2">
                      <p>{mUser[0]}</p>
                      <p>{mUser[1]}</p>
                  </div>)}
                </div>
              </div>
            )}
          </div>
        </div>
      {/* search panel */}
      <div className={`relative flex flex-wrap md:grid md:grid-cols-12 gap-1 border rounded p-1 bg-slate-100 dark:bg-slate-500 pb-3 transition-all duration-200 ease-in-out ${showSearchPanel?'h-auto md:h-12':'h-12 md:h-4'}`}>
        {showSearchPanel && <div className="col-span-12 flex items-center justify-between gap-4">
          <div className="grid grid-cols-1 md:flex items-center justify-start gap-2 md:gap-4">
            <HDatePicker className={`text-sm`} date={[fromDate, toDate]} setDate = {(d) => {setFromDate(d[0]);setToDate(d[1])}}/>
            <button onClick={()=>getMessageHansler()} className='px-2 py-1 border rounded text-sm bg-blue-500 hover:bg-blue-400 text-white' type="button">نمایش</button>
            <button onClick={()=>saveExcel(computeData)} className='px-2 py-1 rounded text-2xl text-teal-500 hover:text-teal-400' type="button"><FaFileExcel /></button>
          </div>
        </div>}
        <button onClick={()=>setShowSearchPanel(o=>!o)} className='absolute bottom-0 start-1/2 text-blue-400 text-sm' type="button">{showSearchPanel?<IoIosArrowUp/>:<IoIosArrowDown />}</button>
      </div>
      {/* loading */}
      {(isLoading) && <div className="flex justify-center items-center h-full">
          <Loader size={60} />
      </div>}
      {!isLoading && messages.length > 0 && <div className="w-full grid grid-cols-12 gap-4">
        {chartData.filter((cd) => chartData.filter((cd) => cd.fullScreen).length > 0?cd.fullScreen:true).map((cd, idx) => 
        <div key={idx} className={`relative border rounded-md shadow-md max-h-[700px] bg-white dark:bg-secondary-dark-bg ${cd.fullScreen === true?'col-span-12 h-[700px]':`${cd.className} h-[30vh]`}`}>
          <button type="button" onClick={() => {setChartData(chartData.map((cdd, idxx) => {if(cdd.id === cd.id){return {...cdd, fullScreen: !cdd.fullScreen}}else{return cdd}}))}} className="absolute top-2 start-2 z-[2] hover:text-gray-400 text-lg">{cd.fullScreen?<AiOutlineFullscreenExit />:<AiOutlineFullscreen />}</button>
          <ReactECharts style={{height: '100%'}} option={cd.option} onEvents={onEvents} />
        </div>
        )}        
        <Card className="col-span-12 md:col-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="فراوانی هر حوزه" selectedValue={filters} onSelect={(s) => setFilters((o) => {if(o===s){return 'all'}else{return s}})} array={computeData.hozeh} />
        <Card className="col-span-12 md:col-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="فراوانی هر حوزه در آستان نیوز" array={computeData.hozehAstannews} />
        {/* <Card className="col-span-12 md:col-span-4 row-span-2 flex flex-col justify-start items-start rounded-md border shadow-md" title="فراوانی هر سوژه" count={20} array={computeData.trend} /> */}
        {/* <Card className="col-span-12 md:col-span-4 row-span-2 flex flex-col justify-start items-start rounded-md border shadow-md" title="فراوانی هر سوژه" count={20} array={computeData.astannewsTrend} /> */}
        <Card className="col-span-12 md:col-span-4 row-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="فراوانی هر سوژه" count={20} array={computeData.roydad} />
        <Card className="col-span-12 md:col-span-4 row-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="فراوانی هر بخش" count={20} array={computeData.bakhsh} />
        <Card className="col-span-12 md:col-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="فراوانی هر رسانه" array={computeData.resaneh} />
        <Card className="col-span-12 md:col-span-2 flex flex-col justify-start items-start rounded-md border dark:border-gray-500 overflow-hidden shadow-md" onClick={(d) => {saveExcel(d)}} title="اشخاص" array={computeData.ashkhas} />
      </div>}
    </div>
  );
};

const countOccurrences = (arr) => {
  const cc = arr.reduce((obj, item) => {
    if (Object.keys(obj).includes(item)) {
      obj[item] = obj[item] + 1;
    } else {
        obj[item] = 1;
    }
    return obj;
  }, {});
  return Object.entries(cc);
};

const Card = ({title = ' ', array, className=' ', count=10, onClick=()=>{}, onSelect=()=>{}, selectedValue}) => {

  return (<div className={`relative ${className}`}>
    <p className='font-bold text-xs w-full text-center bg-gray-200 dark:bg-gray-800 py-1'>{title}</p>
    <button onClick={() => onClick(array)} className="absolute top-1 end-1 text-lg text-green-600 hover:text-green-500"><FaFileExcel /></button>
    <hr className='border-1 border-gray-300 dark:border-gray-500 w-full'/>
    {(array).filter((m, idx) => idx <= count-1).map((m, idx) => 
      <div onClick={() => onSelect(m.name)} key={idx} className={`w-full flex justify-between items-center text-xs px-2 py-1 ${(idx % 2 === 1) && (selectedValue !== m.name) && `bg-gray-100 dark:bg-gray-700`} ${selectedValue !== undefined && 'cursor-pointer'} ${selectedValue === m.name && 'bg-blue-300 dark:bg-gray-800'}`}>
        <p className='text-sm'>{m.name}</p>
        <p className='text-sm'>{m.value}</p>
      </div>
    )}
  </div>)
}

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

export default Report;
