import React from 'react'
import HashLoader from 'react-spinners/RotateLoader'

function Connection() {
  return (
    <div
    className={`mx-1 box-border bg-white dark:bg-secondary-dark-bg p-2 md:transition-2 pt-4 mt-[7.5rem] w-full h-96 flex justify-center items-center`}
  >
      <div className="w-96 rounded-md">
        <p className='text-sm text-red-500 dark:text-amber-500'>خوش آمدید:</p>
        <p className="mb-10">لطفاً صبر کنید...</p>
        <Loader />
      </div>
    </div>
  )
}

const Loader = ({size=100})=>{
  return (<HashLoader
            color={'#36d7b7'}
            loading={true}
            size={size}
            aria-label="Loading Spinner"
            data-testid="loader"
          />)
}

export default Connection