import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClicked from '../hooks/useOutsideClick';
import { CgClose } from 'react-icons/cg';
import ReactDOMServer from 'react-dom/server';

const DropDown = ({
  data,
  placeHolder,
  onClear = () => {},
  hideFilter = false,
  onChange,
  value = null,
  className = '',
  paddingY = '',
  fields = { title: 'title', value: 'value' },
  showClearButton = true,
  itemsCount = 10
}) => {
  const [showRoleDropDown, setShowRoleDropDown] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [showAbove, setShowAbove] = useState(false);
  
  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);
  const dropdownContentRef = useRef(null);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const checkPosition = () => {
    if (!buttonRef.current || !dropdownContentRef.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const dropdownHeight = dropdownContentRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    const spaceBelow = windowHeight - buttonRect.bottom;
    const spaceAbove = buttonRect.top;

    setShowAbove(spaceBelow < dropdownHeight && spaceAbove > dropdownHeight);
  };

  useEffect(() => {
    if (showRoleDropDown && !isMobile) {
      checkPosition();
      window.addEventListener('scroll', checkPosition);
      window.addEventListener('resize', checkPosition);

      return () => {
        window.removeEventListener('scroll', checkPosition);
        window.removeEventListener('resize', checkPosition);
      };
    }
  }, [showRoleDropDown, isMobile]);

  const ff = () => {
    if (!isMobile) {
      setShowRoleDropDown(false);
    }
  };

  useOutsideClicked(dropDownRef, ff);

  const closePopup = () => {
    setShowRoleDropDown(false);
    setFilterText('');
  };

  const handleFilterChange = (e) => {
    setFilterText(e.target.value);
    e.target.focus();
  };

  const MobilePopup = () => (
    <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-white dark:bg-gray-800 flex flex-col max-w-full max-h-full">
        {/* Header */}
        <div className="flex items-center justify-between mt-8 px-4 py-3 border-b dark:border-gray-700">
          <h2 className="text-lg font-semibold">{placeHolder}</h2>
          <button
            onClick={closePopup}
            className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
          >
            <CgClose className="w-6 h-6" />
          </button>
        </div>
        
        {/* Search */}
        {!hideFilter && (
          <div className="p-4 border-b dark:border-gray-700">
            <div className="relative">
              <input
                autoFocus
                className="w-full border rounded-lg bg-white dark:bg-gray-700 px-4 py-2 outline-none dark:border-gray-600"
                placeholder="جستجو"
                type="text"
                value={filterText}
                onChange={handleFilterChange}
              />
              {showClearButton && filterText && (
                <button
                  onClick={() => setFilterText('')}
                  className="absolute left-2 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <CgClose className="w-4 h-4" />
                </button>
              )}
            </div>
          </div>
        )}
        
        {/* Items List */}
        <div className="flex-1 overflow-y-auto">
          {data
            .filter((d) => ReactDOMServer.renderToString(d[fields.title]).toLowerCase().includes(filterText.toLowerCase()))
            .map((d, idx) => (
              <button
                key={idx}
                onClick={() => {
                  onChange(d[fields.value]);
                  closePopup();
                }}
                className={`w-full text-right px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 border-b dark:border-gray-700
                  ${value === d[fields.value] ? 'bg-blue-50 dark:bg-amber-500/10' : ''}`}
              >
                {d[fields.title]}
              </button>
            ))}
        </div>
      </div>
    </div>
  );

  const DesktopDropdown = () => (
    <div
      ref={dropdownContentRef}
      className={`absolute start-0 z-50 w-64 mt-1 rounded-md bg-white dark:bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:border dark:border-gray-600
        ${showAbove ? 'bottom-full mb-1' : 'top-full'}`}
    >
      {!hideFilter && (
        <div className="p-2 border-b dark:border-gray-600">
          <div className="relative">
            <input
              autoFocus
              className="w-full border rounded-md bg-white dark:bg-gray-600 px-3 py-1.5 outline-none dark:border-gray-500"
              placeholder="جستجو"
              type="text"
              value={filterText}
              onChange={handleFilterChange}
            />
            {showClearButton && filterText && (
              <button
                onClick={() => setFilterText('')}
                className="absolute left-2 top-1/2 -translate-y-1/2 px-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              >
                <CgClose className="w-4 h-4" />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="max-h-60 overflow-y-auto flex flex-col">
        {data
          .filter((d) => ReactDOMServer.renderToString(d[fields.title]).toLowerCase().includes(filterText.toLowerCase()))
          .map((d, idx) => (
            <button
              key={idx}
              onClick={() => {
                onChange(d[fields.value]);
                setShowRoleDropDown(false);
              }}
              className={`w-full text-right px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600
                ${value === d[fields.value] ? 'bg-blue-50 dark:bg-amber-500/10' : ''}`}
            >
              {d[fields.title]}
            </button>
          ))}
      </div>
    </div>
  );

  return (
    <div ref={dropDownRef} className={`relative inline-block text-left text-xs ${className}`}>
      <div className="flex flex-col h-full">
        <button
          ref={buttonRef}
          onClick={() => setShowRoleDropDown(!showRoleDropDown)}
          type="button"
          className={`flex flex-grow w-full justify-between items-center rounded-t border-x border-t pb-1 ${(value === null || value.length === 0 || !data.find(d => d[fields.value] === value)) && "border-b rounded"} px-1 gap-1 shadow-sm bg-white dark:bg-gray-700 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 text-start`}
        >
          <span className={`truncate text-[10px] text-gray-400 ${(value === null || value.length === 0 || !data.find(d => d[fields.value] === value))?"text-gray-500":""}`}>
            {/* {(value === null || value.length === 0 || !data.find(d => d[fields.value] === value))
              ? placeHolder
              : data.find(d => d[fields.value] === value)[fields.title]} */}
              {placeHolder}
          </span>
          <div className="flex items-center">
            {showClearButton && value !== null && value.length !== 0 && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 rounded-full hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <CgClose className="w-3 h-3" />
              </button>
            )}
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </button>
        {!(value === null || value.length === 0 || !data.find(d => d[fields.value] === value)) && <p className={`w-full px-1 text-center truncate text-[11px] -translate-y-1 border-x border-b rounded-b dark:border-gray-600`}>
            {data.find(d => d[fields.value] === value)[fields.title]}
          </p>}
      </div>

      {showRoleDropDown && (
        isMobile ? <MobilePopup /> : <DesktopDropdown />
      )}
    </div>
  );
};

export default DropDown;